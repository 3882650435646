import React, {ChangeEvent, useContext, useState} from "react";
import axios from "axios";
import {LanguageContext} from "../App";
import {LanguageType} from "./utils";
import timeIcon from '../assets/time.png';
import locationIcon from '../assets/location.png';
import mailIcon from '../assets/mail.png';
import phoneIcon from '../assets/hotline.png'
import MetaButton from "../components/MetaButton";
import banner from '../components/pages/Drone/assests/PHONE4.png';
import horizontalBanner from '../assets/TUYỂN DỤNG.png';

interface FormInfo {
    name: string,
    phone: string,
    email: string,
    position: string,
    content: string
}

const ContactPage = () => {
    const {language, pageContent, windowSize} = useContext(LanguageContext) as LanguageType;
    const [formInfo, setFormInfo] = useState<FormInfo>({
        name: "",
        phone: "",
        email: "",
        position: "",
        content: ""
    })

    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormInfo((formInfo) => ({
            ...formInfo,
            [name]: value,
        }));
    };

    const handleSend = () => {
        if ((formInfo.name.length == 0) || (formInfo.email.length == 0) || (formInfo.phone.length == 0)) {
        }else {
            console.log(formInfo);
            axios.post(`${process.env.REACT_APP_API_URL}api/notification/question`, formInfo).then(res => {
                console.log(res);
                window.location.reload();
            }).catch(err => {
                console.log(err);
            })
        }
    }


    return <div className="w-screen mb-20 font-custom1 tracking-wide">
        {/*Introduce banner*/}
        <div className="w-full rounded-2xl bg-white flex flex-col gap-10">
            <div className="w-full h-auto brightness-75">
                <img src={(windowSize.width < 700) ? banner : horizontalBanner} alt={"BAnner"} className="w-full h-auto"/>
            </div>
            <div>
                <div className="text-4xl font-bold mb-6 mt-10">
                    <label>{pageContent.contactPage.contact.title}</label>
                </div>
                <div className="flex flex-col md:flex-row md:gap-0 gap-4 md:justify-start mb-12">
                    <div className="flex gap-8 w-1/2">
                        <div className="w-16">
                            <img src={locationIcon}/>
                        </div>
                        <div>
                            <div className="text-black text-lg w-fit">
                                <label>{pageContent.contactPage.contact.addressTitle}</label>
                            </div>
                            <div className="text-gray-400 text-lg w-full">
                                <label className="break-words">{pageContent.contactPage.contact.address}</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-8 ">
                        <div className="w-8">
                            <img src={timeIcon}/>
                        </div>
                        <div className="mr-20">
                            <div className=" text-black text-lg">
                                <label>{pageContent.contactPage.contact.workingTimeTitle}</label>
                            </div>
                            <div className="text-gray-400 text-lg w-full">
                                <label>{pageContent.contactPage.contact.workingTime}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row md:justify-start md:gap-0 gap-4 ">
                    <div className="flex gap-8 w-1/2">
                        <div className="w-8">
                            <img src={mailIcon}/>
                        </div>
                        <div>
                            <div className=" text-black text-lg">
                                <label>Email</label>
                            </div>
                            <div className="text-gray-400 text-lg w-1/2">
                                <label>khangpn@scienceboxvietnam.vn</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-8">
                        <div className="w-8">
                            <img src={phoneIcon}/>
                        </div>
                        <div className="mr-20">
                            <div className=" text-black text-lg">
                                <label>{pageContent.contactPage.contact.phoneTitle}</label>
                            </div>
                            <div className="text-gray-400 text-lg w-full">
                                <label>0776140912</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="w-full md:ml-20 md:w-3/6 rounded-2xl bg-white px-6 py-10 flex flex-col gap-10 mt-10">
            <div className="text-4xl font-bold">
                <label>{pageContent.contactPage.infoForm.title}</label>
            </div>
            <div className="w-full text-gray-500">
                <label className="w-full break-words">{pageContent.contactPage.infoForm.question}</label>
            </div>
            <div className="w-full ">
                <div className="w-full px-2 py-2 border-2 border-gray-100 rounded-xl">
                    <input onChange={handleInputChange} name={'name'} className="w-full" type={"text"} placeholder={`${pageContent.contactPage.infoForm.nameField}`} style={{outline: 'none', border: 'none'}}/>
                </div>
                <div className="flex items-center justify-between mt-8">
                    <div className="w-1/2 px-2 py-2 border-2 border-gray-100 rounded-xl mr-4">
                        <input onChange={handleInputChange} name={'email'} className="w-full" type={"email"} placeholder={`${pageContent.contactPage.infoForm.emailField}`} style={{outline: 'none', border: 'none'}}/>
                    </div>
                    <div className="w-1/2 px-2 py-2 border-2 border-gray-100 rounded-xl">
                        <input className="w-full" onChange={handleInputChange} name={'phone'} type={"text"} placeholder={`${pageContent.contactPage.infoForm.phoneField}`} style={{outline: 'none', border: 'none'}}/>
                    </div>
                </div>
                <textarea
                    onChange={handleInputChange}
                    name={"content"}
                    className="rounded-2xl w-full h-[200px] mt-4"
                    placeholder={`${pageContent.contactPage.infoForm.contentField}`}
                    style={{outline: 'none'}}
                ></textarea>
                <div className="mt-4">
                    <MetaButton className={""} onClick={handleSend} content={pageContent.contactPage.button}/>
                </div>
            </div>
        </div>
    </div>

}

export default ContactPage;
