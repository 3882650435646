import React from "react";
import megatonColorLogo from "../../Megaton/assests/LOGO.png";
import megatonBoard from "../../Megaton/assests/megaton_box.png";

const ToolIntroduction = () => {
    return <section className={`w-full h-fit mt-0 sec-2 relative pt-5 bg-[#FF0000]`}>
        <div>
            <div className="w-[80px] h-auto mx-auto brightness-200">
                <img className="w-full h-full" src={megatonColorLogo} alt={"Color Logo"}/>
            </div>
        </div>
        <div className="w-full">
            <div className="w-[500px] font-meta_header text-white mx-auto text-center mt-9 text-2xl">
                <label>TÍCH HỢP AI THÔNG QUA<br/>
                    BỘ CÔNG CỤ MEGATON

                </label>
            </div>
            <div className="w-fit h-fit mx-auto underline text-white mt-9">
                <label>Tìm Hiểu Thêm</label>
            </div>
            <div className="w-full h-[240px] mx-auto mt-6 ">
                <img className=" absolute -bottom-32 left-1/2 transform -translate-x-1/2 w-[550px] h-auto z-40" src={megatonBoard} alt={"Megaton Board"} />
            </div>
        </div>
    </section>
}

export default ToolIntroduction;
