import React from "react";
import megatonColorLogo from "../assests/color_logo.png";

const CompetitionIntroduction = () => {
    return <section className={`w-full h-[500px] mt-0 sec-2 relative pt-5 bg-white pb-20`}>
        <div className="">
            <div className="w-[130px] h-auto mx-auto brightness-200">
                <img className="w-full h-full" src={megatonColorLogo} alt={"Color Logo"}/>
            </div>
        </div>
        <div className="flex items-center justify-center h-full w-full mt-1">
            {/*Content Container*/}
            <div className="w-1/3 h-auto">
                <div className="font-meta_header text-2xl text-center">
                    <label>ỨNG DỤNG</label>
                </div>
                <div className="w-full text-justify font-meta_content mx-auto mt-4 text-md pr-20">
                    <label>Đặc biệt, các học sinh sẽ được tham gia các giải thi đấu khoa học sáng tạo thường niên do Sciencebox tổ chức trong nước và quốc tế.</label>
                </div>
                <div className="w-full flex items-center justify-start  mt-10">
                    <div className="w-fit flex items-center font-meta_content justify-center">
                        <button className="bg-red-600 px-10 py-2 text-lg rounded-3xl mx-auto text-white">Mua</button>
                    </div>
                    <div className="text-lg ml-20  font-meta_content text-[#0070C0] font-bold underline ">
                        <label>Tìm Hiểu Thêm</label>
                    </div>
                </div>
            </div>

            {/*Video Container*/}
            <div className="w-1/3 h-full flex items-center justify-center  ">
                <iframe
                    width={480}
                    height={270}
                    src={`https://www.youtube.com/embed/${'ZgTeVeysKTA'}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    </section>
}

export default CompetitionIntroduction;

/*

*/
