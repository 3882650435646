import React from "react";
import skills1 from '../../Drone/assests/teamwork.png';
import skills2 from '../../Drone/assests/creative.png';
import skills3 from '../../Drone/assests/coding.png';
import skills4 from '../../Drone/assests/logical.png';

const Skills = () => {
    return <section className="w-full mt-10 h-fit ">
        <div className="text-xl font-meta_header text-[#FFC000] ml-9">
            <label>PHÁT TRIỂN KỸ NĂNG</label>
        </div>

        <div className="w-4/5 h-fit flex items-center justify-center gap-20 mx-auto flex-wrap mt-10">
            <div className="w-[100px] h-auto brightness-200 bg-[#5C9BD5] rounded-full">
                <img src={skills1} alt="Skill" className="w-full h-full"/>
            </div>
            <div className="w-[100px] h-auto brightness-200 bg-[#5C9BD5] rounded-full">
                <img src={skills2} alt="Skill" className="w-full h-full"/>
            </div>
            <div className="w-[100px] h-auto brightness-200 bg-[#5C9BD5] rounded-full">
                <img src={skills3} alt="Skill" className="w-full h-full"/>
            </div>
            <div className="w-[100px] h-auto brightness-200 bg-[#5C9BD5] rounded-full">
                <img src={skills4} alt="Skill" className="w-full h-full"/>
            </div>
        </div>
    </section>
}

export default Skills;
