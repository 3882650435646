import React from "react";
import megatonColorLogo from "../assests/sb_logo.png";
import {MetaSlider} from "../../../Slider";
import activity1 from '../assests/activity/hd1.jpg'
import activity2 from '../assests/activity/hd2.jpg'
import activity3 from '../assests/activity/hd3.jpg'
import activity4 from '../assests/activity/hd4.jpg'
import activity5 from '../assests/activity/hd5.jpg'
import activity6 from '../assests/activity/hd6.jpg'
import activity7 from '../assests/activity/hd7.jpg'
import activity8 from '../assests/activity/hd8.jpg'
import activity9 from '../assests/activity/hd9.jpg'
import activity10 from '../assests/activity/hd10.jpg'

const activities = [
    activity1,
    activity2,
    activity3,
    activity4,
    activity5,
    activity6,
    activity7,
    activity8,
    activity9,
    activity10
]


const ActivitySection = () => {
    return <section className="w-full h-fit mt-0 sec-2 relative pt-5 bg-[#F5F5F7] pb-5">
        <div className="w-full">
            <div className="w-fit text-3xl mx-auto font-meta_header font-bold mt-10">
                <label>HOẠT ĐỘNG</label>
            </div>
            <MetaSlider className="mt-12" gap={10} left={10}>
                {activities.map((activity, index) => <img key={index} className="w-[400px] h-auto rounded-3xl" src={activity} alt={"ACT"}/>)}
            </MetaSlider>
            <div className="w-full mt-5">
                <div className="w-2/5 mx-auto text-center">
                    <label className="w-full text-center">
                        Tiếp cận công nghệ cao ứng dụng trí tuệ nhân tạo vào lập trình và phát triển Robot, 
                    tạo cơ hội hội nhập thông qua các cuộc thi Đa quốc gia.
                    </label>
                </div>
            </div>
        </div>
    </section>
}

export default ActivitySection;
