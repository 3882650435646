import React from "react";
import {MetaSlider} from "../../../Slider";
import act1 from '../assests/activity/3.jpg';
import act2 from '../assests/activity/145086-nam00892jpg.jpg';
import act3 from '../assests/activity/2.jpg';
import act4 from '../assests/activity/z5116168957362_41139bc03d90586874d8292fe58d77d7.jpg';
import act5 from '../assests/activity/A7105624.jpg';

const activities = [
    act1,
    act2,
    act3,
    act4,
    act5
]

const Activity = () => {
    return <section className="w-full mx-auto rounded-3xl h-fit bg-gray-100 py-10 mb-14">
        <div className="w-fit mx-auto font-meta_header text-3xl text-black">
            <label>HOẠT ĐỘNG</label>
        </div>
        <MetaSlider className="mt-5" gap={10}>
            {activities.map((activity, index) => <img key={index} className="w-[400px] h-auto rounded-3xl" src={activity} alt={"ACT"}/>)}
        </MetaSlider>
    </section>
}

export default Activity;
