import React, {useContext} from "react";
import {LanguageContext} from "../App";
import {LanguageType} from "./utils";
import {RoboticsPage, RoboticsPagePhone} from "../components/pages/Robotics";

const Robotics = () => {
    const {windowSize} = useContext(LanguageContext) as LanguageType;

    return <div>
        {windowSize.width < 700 ? <RoboticsPagePhone/> : <RoboticsPage/>}
    </div>
}

export default Robotics;
