import React, {useEffect} from "react";
import banner from '../assests/drone_banner.png'
import AboutSection from "./AboutSection";
import SkillsSection from "./SkillsSection";
import IntroductionSection from "./IntroductionSection";
import InternationalCompetitionIntroduction from "./InternationalCompetitionIntroduction";
import Certificates from "./Certificates";
import Activity from "./Activity";

const DronePage = () => {
    useEffect(() => {
        let sections = document.querySelectorAll('section');
        const handleScroll = () => {
            sections.forEach(sec => {
                let top = window.scrollY;
                let offset = sec.offsetTop - 600;
                let height = sec.offsetHeight + 400;
                // console.log(`TOP: ${top} - OFFSET: ${offset} - HEIGHT: ${height}`);
                if (top >= offset && top < offset + height) {
                    sec.classList.add('show-animate');
                }else {
                    sec.classList.remove('show-animate')
                }

            })


        };

        // Attach the event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Detach the event listener when the component unmounts to avoid memory leaks
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return <div>
        {/*Banner*/}
        <section className="w-screen h-auto brightness-90">
            <img className="w-full h-full " src={banner} alt={"Banner"}/>
        </section>
        <AboutSection/>
        <SkillsSection/>
        <IntroductionSection/>
        <InternationalCompetitionIntroduction/>
        <Certificates/>
        <Activity/>
    </div>
}

export default DronePage;
