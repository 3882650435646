import React from "react";
import Banner from "./Banner";
import ScienceBoxIntroduction from "./ScienceBoxIntroduction";
import Activity from "./Activity";

const ScienceBoxPhone = () => {
    return <div className="overflow-x-hidden">
        <Banner/>
        <ScienceBoxIntroduction/>
        <Activity/>
    </div>
}

export default ScienceBoxPhone;
