import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import NewsContainer from "../components/NewsContainer";
import {LanguageContext} from "../App";
import {LanguageType, removePunctuation} from "./utils";
import './css/news_page.css'
import './css/news_container.css'
import {News} from "../interface/NewsInterface";


interface NewsContainerProps {
    news: News;
}

export const LatestNewsContainer: React.FC<NewsContainerProps> = ({news}) => {
    const [titleImg, setTitleImg] = useState('');
    const navigate = useNavigate();
    const {language} = useContext(LanguageContext) as LanguageType;

    useEffect( () => {
        try {
            axios.get(`https://test-client-stem.s3.amazonaws.com/blog-${news.blod_id}.txt`).then(res => {
                setTitleImg(res.data);
            }).catch(err => {
                console.log(err);
            })

        } catch (error) {
            console.error('Error fetching file content:', error);
        }
    }, []);

    return <div onClick={() => {
        navigate(`/news/${removePunctuation(news.title)}`);
        window.location.reload();
    }} className="cursor-pointer w-full border-b border-gray-300 pb-5">
        <div className="cursor-pointer flex gap-3 w-full items-center gap-4">
            <div className="w-2/3">
                <img src={titleImg}/>
            </div>
            <div className={`break-words w-full font-meta_header`}>
                <div className="cursor-pointer briefTitle">
                    <label>{(language == 'vn') ? news.title : (language == 'en') ? news.enTitle : news.krTitle}</label>
                </div>
                <div className="text-sm font-meta_content mt-4">
                    <label>{news.date}</label>
                </div>
            </div>
        </div>
    </div>
}


const NewsPage = () => {
    const [news, setNews] = useState<News[]>([]);
    const {language, pageContent, windowSize} = useContext(LanguageContext) as LanguageType;
    const [element, setElement] = useState(3);
    const [onLatestNewsClick, setOnLatestNewsClick] = useState(true);



    useEffect(() => {
        if (windowSize.width < 700) {
            setElement(1);
        }else if (windowSize.width < 900) {
            setElement(2);
        }else {
            setElement(3);
        }
    }, [windowSize])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/blog`).then(res => {
            setNews(res.data);
        }).catch(err => {
            console.log(err);
        })
    }, [])

    return <div className=" w-screen mt-20 font-custom1 tracking-wide px-4">
        <div className=" w-full flex flex-col lg:flex-row lg:gap-5 justify-between items-start">
            <div className="w-5/6 mx-auto lg:pl-4 lg:mx-0 justify-end lg:w-2/3 flex flex-col lg:flex-row lg:flex-wrap gap-5">
                {news.map((content, index) => <div className="w-full lg:w-2/5">
                    <NewsContainer key={content.blod_id} elements={2} news={content} language={language}/>
                </div>)}
            </div>
            <div className="w-full">
                <div onClick={() => {
                    setOnLatestNewsClick(!onLatestNewsClick);
                }} className="mx-auto border-b-4 border-gray-400 font-meta_header text-xl lg:mx-0 hover:bg-white py-3 px-6 w-4/5 flex gap-8">
                    <div>
                        <label>{pageContent.newsPage.latestNews}</label>
                    </div>
                    <div className={`transition-all duration-500 ${onLatestNewsClick ? '-rotate-90' : 'rotate-0'}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                        </svg>
                    </div>
                </div>
                <div className={`wrapper mx-auto w-5/6 lg:mx-0 ${onLatestNewsClick ? 'open py-4 px-8 bg-white' : ''} mt-3`}>
                    <div className={`${onLatestNewsClick ? '' : 'expandable'} flex flex-col gap-10`}>
                        {news.map((news, index) => (index < 5) ? <div>
                            <LatestNewsContainer key={news.blod_id} news={news}/>
                        </div> : null)}
                    </div>
                </div>

            </div>
        </div>

    </div>
}

export default NewsPage;
