import React from "react";
import sb3 from '../assests/SB3.png';
import sb4 from '../assests/SB4.png';
import {MetaSlider} from "../../../Slider";
import megatonLogo from '../../Megaton/assests/LOGO.png';
import megatonBoard from '../../Megaton/assests/megaton_box.png';
import {useNavigate} from "react-router-dom";

const ScienceBoxIntroduction = () => {

    const navigate = useNavigate();

    return <section  className="w-full h-fit mb-5 mt-10">
        <div className="w-fit mx-auto text-3xl font-meta_header text-[#FF0000]">
            <label>VỀ SCIENCEBOX</label>
        </div>
        <div className="w-3/5 mx-auto text-center mt-5 font-meta_content">
            <label>Bộ công cụ Sciencebox giúp trẻ em phát triển vượt trội về khả năng tư duy logic, kết hợp với kiến thức khoa học ứng dụng thông qua bộ môn lắp ráp kĩ thuật cơ khí và lập trình
            </label>
        </div>
        <div className="w-fit mx-auto mt-10">
            <button onClick={() => {
                navigate('/contact');
                window.location.reload();
            }} className="font-meta_content text-white text-xl bg-[#FF0000] px-5 py-1.5 rounded-2xl">Mua
            </button>
        </div>
        <MetaSlider className="mt-2" gap={10} bottom={2}>
            <div className="min-w-[450px] w-[450px] h-fit relative">
                <div className="w-full h-fit relative ">
                    <img src={sb3} alt="Megaton box" className="w-2/3 h-auto mx-auto"/>
                </div>
                <div className="w-fit mx-auto font-meta_header text-[#FFC000] text-xl">
                    <label>SCIENCE BOX NO6</label>
                </div>
                <div className="w-2/3 mx-auto font-meta_content text-center mt-1">
                    <label>Kết hợp với kiến thức khoa học ứng dụng thông qua bộ môn lắp ráp kĩ thuật cơ khí và lập trình.
                        </label>
                </div>
            </div>
            <div className="min-w-[450px] w-[450px] h-fit relative">
                <div className="w-full h-fit relative ">
                    <img src={sb4} alt="Megaton box" className="w-2/3 h-auto mx-auto"/>
                </div>
                <div className="w-fit mx-auto font-meta_header text-[#FFC000] text-xl">
                    <label>SCIENCE BOX NO3</label>
                </div>
                <div className="w-2/3 mx-auto font-meta_content text-center mt-1">
                    <label>Bộ công cụ Sciencebox giúp trẻ em phát triển vượt trội về khả năng tư duy logic<br/> và lập trình</label>
                </div>
            </div>
        </MetaSlider>
        <div className="w-fit mx-5 h-fit mt-10 bg-[#F5F5F7] rounded-3xl pt-5 ">
            <div className="w-fit text-3xl text-[#FF0000] mx-auto font-meta_header">
                <label>ỨNG DỤNG</label>
            </div>
            <div className="w-2/3 text-center font-meta_content text-lg mt-5 mx-auto">
                <label>Đặc biệt, các học sinh sẽ được tham gia các giải thi đấu khoa học sáng tạo thường niên do Sciencebox tổ chức
                    trong nước và quốc tế.
                </label>
            </div>
            <div className="w-fit mx-auto mt-5">
                <button onClick={() => {
                    navigate('/contact');
                    window.location.reload();
                }} className="font-meta_content  text-md text-blue-700 px-5 underline py-1.5 rounded-2xl">Tìm hiểu thêm
                </button>
            </div>
            <div className="w-full h-[300px] mx-auto mb-14 mt-10 ">
                <iframe
                    width={480}
                    height={270}
                    src={`https://www.youtube.com/embed/${'DIFDnhLjwks'}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        </div>

        <div className="mt-10 w-11/12 bg-[#FF0000] h-[400px] pt-5 pb-5 rounded-3xl mx-auto">
            <div className="w-full h-fit ">
                <img src={megatonLogo} alt={"MEGATON_LOGO"} className="w-[50px] h-auto mx-auto"/>
            </div>
            <div className="w-fit mx-auto text-center font-meta_header mt-4 text-2xl text-white">
                <label>TÍCH HỢP AI THÔNG QUA<br/>
                    BỘ CÔNG CỤ MEGATON
                </label>
            </div>
            <div className="w-fit mx-auto text-white font-meta_header mt-5">
                <button onClick={() => {
                    navigate('/contact');
                    window.location.reload();
                }} className=" underline font-meta_content">Tìm hiểu thêm
                </button>
            </div>
            <div className="w-full h-[300px] mx-auto" >
                <img src={megatonBoard} alt={"MEGATON"} className="h-full w-auto mx-auto"/>
            </div>
        </div>
    </section>
}

export default ScienceBoxIntroduction;
