import React, {useEffect, useRef, useState} from "react";
import bn1 from '../assests/Slide1.png';
import bn2 from '../assests/Slide2.png';
import bn3 from '../assests/Slide3.png';
import {useNavigate} from "react-router-dom";

const Banner = () => {
    const [imgShown, setImgShown] = useState(0);
    const navigate = useNavigate();
    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const container = event.currentTarget;
        const containerRect = container.getBoundingClientRect();
        const containerCenter = containerRect.left + containerRect.width / 2;
        const images = container.querySelectorAll('img');

        images.forEach(img => {
            const imgRect = img.getBoundingClientRect();
            const imgCenter = imgRect.left + imgRect.width / 2;

            // Check if the image center is within the container
            if (imgCenter >= containerRect.left && imgCenter <= containerRect.right) {
                if (img.id === 'image1') {
                    setImgShown(0);
                }else if (img.id === 'image2') {
                    setImgShown(1);
                }else {
                    setImgShown(2);
                }
            }
        });
    };

    return <section className="w-full h-screen relative">
        <div className="snap-x w-screen h-full flex overflow-x-scroll overflow-y-hidden items-center justify-start" onScroll={handleScroll}>
            <div className="snap-center min-w-full w-full h-full relative">
                <img src={bn1} alt={"Banner"} id="image1" className="w-full h-full absolute top-0"/>
                <div className="w-full h-fit absolute top-5">
                    <div className="w-fit mx-auto text-2xl bg-[#00B0F0] font-meta_content text-white px-8 py-1 rounded-3xl">
                        <label>KHOÁ HỌC</label>
                    </div>
                </div>
                <div className="absolute -bottom-24 pt-10 z-30 w-full h-3/5 flex flex-col items-center justify-end pb-28">
                    <div className="text-4xl text-white w-fit mx-auto font-meta_header">
                        <label>CHÀO MỪNG ĐẾN VỚI </label>
                    </div>
                    <div className="text-[#00B0F0] font-bold text-xl font-meta_content w-fit mx-auto mt-8">
                        <label>META SQUARE</label>
                    </div>
                    <div className="w-2/3 text-center text-white mx-auto mt-5 font-meta_content">
                        <label>Xây dựng nền tảng vững chắc về công nghệ
                            và kỹ thuật cho tất cả học sinh
                        </label>
                    </div>
                    <div  className="w-fit mx-auto mt-10">
                        <button onClick={() => {
                            navigate('/contact')
                        }} className="font-meta_content text-[#FFC000] text-xl">Liên hệ
                        </button>
                    </div>
                </div>
            </div>
            <div className="snap-center min-w-full w-full h-full relative">
                <img src={bn2} alt={"Banner"} id="image1" className="w-full h-full absolute top-0"/>
                <div className="w-full h-fit absolute top-5">
                    <div className="w-fit mx-auto text-2xl bg-[#00B0F0] font-meta_content text-white px-8 py-1 rounded-3xl">
                        <label>DRONE</label>
                    </div>
                </div>
                <div className="absolute -bottom-24 pt-10 z-30 w-full h-3/5 flex flex-col items-center justify-end pb-28">
                    <div className="text-4xl text-white w-fit mx-auto font-meta_content ">
                        <label>KHOÁ HỌC </label>
                    </div>
                    <div className="text-[#00B0F0] text-xl font-bold font-meta_content w-fit mx-auto mt-8">
                        <label>DRONE</label>
                    </div>
                    <div className="w-4/5 text-center text-white mx-auto mt-5 font-meta_content">
                        <label>Cung cấp kiến thức, kỹ năng căn bản về khả năng tư duy logic, kết hợp với lập trình điều khiển.
                        </label>
                    </div>
                    <div  className="w-fit mx-auto mt-10">
                        <button onClick={() => {
                            navigate('/contact')
                        }} className="font-meta_content text-[#FFC000] text-xl">Liên hệ
                        </button>
                    </div>
                </div>
            </div>
            <div className="snap-center min-w-full w-full h-full relative">
                <img src={bn3} alt={"Banner"} id="image1" className="w-full h-full absolute top-0"/>
                <div className="w-full h-fit absolute top-5">
                    <div className="w-fit mx-auto text-2xl bg-[#00B0F0] font-meta_content text-white px-8 py-1 rounded-3xl">
                        <label>DRONE</label>
                    </div>
                </div>
                <div className="absolute -bottom-24 pt-10 z-30 w-full h-3/5 flex flex-col items-center justify-end pb-28">
                    <div className="text-4xl text-white w-fit mx-auto font-meta_header">
                        <label>KHOÁ HỌC </label>
                    </div>
                    <div className="text-[#00B0F0] font-bold text-xl font-meta_content w-fit mx-auto mt-8">
                        <label>ROBOTICS</label>
                    </div>
                    <div className="w-4/5 text-center text-white mx-auto mt-5 font-meta_content">
                        <label>Ứng dụng Trí Tuệ Nhân Tạo vào
                            lập trình<br/> Phát triển Robot</label>
                    </div>
                    <div  className="w-fit mx-auto mt-10">
                        <button onClick={() => {
                            navigate('/contact')
                        }} className="font-meta_content text-[#FFC000] text-xl">Liên hệ
                        </button>
                    </div>
                </div>
            </div>
            {/* 
            <div className="snap-center min-w-full w-full h-full relative"> */}
                {/* <img src={bn3} alt={"Banner"} id="image3" className="w-full h-full brightness-75"/>
                <div className="w-full h-fit absolute top-5">
                    <div className="w-fit mx-auto text-2xl bg-[#00B0F0] font-meta_header px-8 py-1 rounded-3xl">
                        <label>DRONE</label>
                    </div>
                </div>
                <div className="absolute -bottom-24 pt-10 z-30 w-full h-3/5 flex flex-col items-center justify-end pb-28">
                    <div className="text-4xl text-white w-fit mx-auto font-meta_header">
                        <label>KHOÁ HỌC </label>
                    </div>
                    <div className="text-[#00B0F0] font-bold font-meta_content w-fit mx-auto mt-8">
                        <label>ROBOTICS</label>
                    </div>
                    <div className="w-2/3 text-center text-white mx-auto mt-5 font-meta_content">
                        <label>Ứng dụng Trí Tuệ Nhân Tạo vào
                            lập trình Phát triển Robot</label>
                    </div>
                    <div  className="w-fit mx-auto mt-10">
                        <button onClick={() => {
                            navigate('/contact')
                        }} className="font-meta_content text-[#FFC000] text-xl">Liên hệ
                        </button>
                    </div>
                </div>
            </div>*/}
        </div> 

    </section>
}

export default Banner;
