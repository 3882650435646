import React from "react";
import TitleSection from "./TitleSection";
import IntroductionSection from "./IntroductionSection";
import AIIntroduction from "./AIIntroduction";
import CompetitionIntroduction from "./CompetitionIntroduction";
import CodingToolIntroduction from "./CodingToolIntroduction";

const MegatonPage = () => {
    return <div>
        <TitleSection/>
        <IntroductionSection/>
        <AIIntroduction/>
        <CompetitionIntroduction/>
        <CodingToolIntroduction/>
    </div>
}

export default MegatonPage;
