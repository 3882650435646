import React from "react";
import {useNavigate} from "react-router-dom";

const IntroductionSection = () => {
    const navigate = useNavigate();

    return <section className={`w-full h-fit mt-0 sec-2 relative pt-5 bg-white pb-20`}>
        <div className="flex items-center justify-center h-full w-full mt-10 gap-16">
            {/*Video Container*/}
            <div className="w-1/3 h-full flex items-center justify-center  ">
                <iframe
                    width={480}
                    height={270}
                    src={`https://www.youtube.com/embed/${'ZgTeVeysKTA'}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>

            {/*Content Container*/}
            <div className="w-1/3 h-auto ">
                <div className="font-meta_header text-2xl text-right">
                    <label>ỨNG DỤNG HỌC TẬP</label>
                </div>
                <div className="w-full text-right font-meta_content mx-auto mt-5 text-md">
                    <label>Các bạn sẽ học cách lập trình và điều khiển Drone thực hiện những nhiệm vụ thú vị như vượt chướng ngại vật, hay giao hàng mô phỏng,…Tiếp cận với những thử thách trong việc điều khiển và lập trình Drone</label>
                </div>
                <div className="mt-5 flex items-center justify-end">
                    <button onClick={() => {
                        navigate('/contact');
                        window.location.reload();
                    }} className="px-7 py-1 text-lg border-2 border-[#41719C] text-[#0070C0]">Liên hệ</button>
                </div>
            </div>
        </div>
    </section>
}

export default IntroductionSection;

// 