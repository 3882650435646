import React, {useContext, useEffect, useState} from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'
import axios from "axios";
import {useLocation} from "react-router-dom";
import {Simulate} from "react-dom/test-utils";
import {LanguageContext} from "../App";
import {LanguageType} from "./utils";

interface ChangeProps {
    value: string
}

const modules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
    ],
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
]

const NewsUploadPage = () => {
    const {language, pageContent, windowSize} = useContext(LanguageContext) as LanguageType;
    const [title, setTitle] = useState('');
    const [summary, setSummary] = useState('');
    // const [image, setImage] = useState(null);
    const [content, setContent] = useState('');
    const [enTitle, setEnTitle] = useState('');
    const [enSummary, setEnSummary] = useState('');
    const [enContent, setEnContent] = useState('');
    const [krTitle, setKrTitle] = useState('');
    const [krSummary, setKrSummary] = useState('');
    const [krContent, setKrContent] = useState('');

    const [editedContent, setEditedContent] = useState('');

    const [base64Img, setBase64Image] = useState('');
    const location = useLocation();
    const [missingInfo, setMissingInfo] = useState(false);
    const [startUpload, setStartUpload] = useState(false);
    const [vnSelected, setVnSelected] = useState(true);
    const [enSelected, setEnSelected] = useState(false);
    const [krSelected, setKrSelected] = useState(false);
    const [editedTitle, setEditedTitle] = useState('');

    useEffect(() => {
        if (location.state.update === "1") {
            console.log("Updating");
            axios.get(`${process.env.REACT_APP_CONTENT_URL}blog-${location.state.news.blod_id}.txt`).then(res => {
                setBase64Image(res.data);
            }).catch(err => {
                console.log(err);
            })

            axios.get(`${process.env.REACT_APP_API_URL}api/blog/single?blog_id=${location.state.news.blod_id}`).then(res => {
                console.log(res.data);
                // setContent(res.data);
                setContent(res.data.content);
                setEnContent(res.data.enContent);
                setKrContent(res.data.krContent);
                setEditedContent(res.data.content);
                // setIsLoading(false);
            }).catch(err => {
                console.log(err);
            })

            // console.log("Calling " + `http://test-client-stem.s3-website-us-east-1.amazonaws.com/blog-vn-content-${location.state.news.blod_id}.txt`);
            // axios.get(`${process.env.REACT_APP_CONTENT_URL}blog-vn-content-${location.state.news.blod_id}.txt`).then(res => {
            //     console.log(res);
            //     console.log(res.data);
            //     setContent(res.data);
            //
            // }).catch(err => {
            //     console.log(err);
            // })
            setTitle(location.state.news.title);
            setSummary(location.state.news.summary);

            // axios.get(`${process.env.REACT_APP_CONTENT_URL}blog-kr-content-${location.state.news.blod_id}.txt`).then(res => {
            //     console.log(res.data);
            //     setKrContent(res.data);
            // }).catch(err => {
            //     console.log(err);
            // })
            setKrTitle(location.state.news.krTitle);
            setKrSummary(location.state.news.krSummary);

            // axios.get(`${process.env.REACT_APP_CONTENT_URL}blog-en-content-${location.state.news.blod_id}.txt`).then(res => {
            //     console.log(res.data);
            //     setEnContent(res.data);
            // }).catch(err => {
            //     console.log(err);
            // })
            setEnTitle(location.state.news.enTitle);
            setEnSummary(location.state.news.enSummary);
        }else {
            console.log("Update new blog")
        }
    }, []);

    useEffect(() => {
        console.log("En conent Changed");
    }, [content])

    const getCurrentDateFormatted = () => {
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1; // Months are 0-based, so add 1 to get the correct month
        const year = currentDate.getFullYear();

        // Add leading zeroes to day and month if they are less than 10
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        // Format: DD/MM/YYYY
        const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
        return formattedDate;
    };

    const handlePostNews = () => {
        console.log(base64Img);
        console.log(content);
        console.log("Date post: " + getCurrentDateFormatted());
        if (location.state.update === "0") {
            if ((title.length === 0) || (summary.length === 0) || (base64Img.length === 0)) {
                setMissingInfo(true);
            }else {
                setStartUpload(true);
                axios.post(`${process.env.REACT_APP_API_URL}api/blog`, {
                    title: title,
                    summary: summary,
                    content: content,
                    enTitle: enTitle,
                    enSummary: enSummary,
                    krTitle: krTitle,
                    krSummary: krSummary,
                    img: base64Img,
                    enContent: enContent,
                    krContent: krContent,
                    date: getCurrentDateFormatted()
                }).then(res => {
                    console.log(res);
                    setStartUpload(false);
                    window.location.reload();
                }).catch(err => {
                    console.log(err);
                })
            }
        }else {
            console.log("Update");
            console.log({
                blod_id: location.state.news.blod_id,
                title: title,
                summary: summary,
                content: content,
                enTitle: enTitle,
                enSummary: enSummary,
                krTitle: krTitle,
                krSummary: krSummary,
                img: base64Img,
                enContent: enContent,
                krContent: krContent,
                date: getCurrentDateFormatted()
            })
            setStartUpload(true);
            axios.put(`${process.env.REACT_APP_API_URL}api/blog`, {
                blod_id: location.state.news.blod_id,
                title: title,
                summary: summary,
                content: content,
                enTitle: enTitle,
                enSummary: enSummary,
                krTitle: krTitle,
                krSummary: krSummary,
                img: base64Img,
                enContent: enContent,
                krContent: krContent,
                date: getCurrentDateFormatted()
            }).then(res => {
                console.log(res);
                setStartUpload(false);
                alert("Xong")
            }).catch(err => {
                console.log(err);
            })
        }
    }

    useEffect(() => {
        setEditedContent('')
        if (vnSelected) {
            setEditedContent(content);
            setEditedTitle(title);
        }else if (enSelected) {
            setEditedContent(enContent);
            setEditedTitle(enTitle);
        }else {
            setEditedContent(krContent);
            setEditedTitle(krTitle);
        }
    }, [enSelected, vnSelected, krSelected])

    useEffect(() => {
        if (vnSelected) {
            setContent(editedContent);
        }else if (enSelected) {
            setEnContent(editedContent);
        }else {
            setKrContent(editedContent);
        }
    }, [editedContent])

    return <div className=" mt-14 bg-white mx-10 px-10 py-4 font-custom1 tracking-wide">
        <div className="text-4xl font-bold">
            <label>Form đăng thông tin</label>
        </div>
        <div className="mt-4 flex flex-col gap-6">
            <div className="w-full rounded-xl">
                <input className="w-full rounded-xl"
                       type={"text"}
                       placeholder={"Tựa đề"}
                       value={(vnSelected) ? title : (enSelected) ? enTitle : krTitle}
                       onChange={e => {
                           if (vnSelected) {
                               setTitle(e.target.value)
                           }else if (enSelected) {
                               setEnTitle(e.target.value)
                           }else {
                               setKrTitle(e.target.value)
                           }
                       }}/>
            </div>
            <div className="w-full rounded-xl">
                <input className="w-full rounded-xl" type={"text"}
                       placeholder={"Tóm tắt thông tin"}
                       value={(vnSelected) ? summary : (enSelected) ? enSummary : krSummary}
                       onChange={e => {
                           if (vnSelected) {
                               setSummary(e.target.value);
                           }else if (enSelected) {
                               setEnSummary(e.target.value)
                           }else {
                               setKrSummary(e.target.value)
                           }
                       }}/>
            </div>
            <div className="w-full rounded-xl">
                <input className="w-full rounded-xl" type={"file"} placeholder={"Hình ảnh"} onChange={e => {
                    // @ts-ignore
                    // setImage(e.target.files[0]);
                    const reader = new FileReader();

                    console.log("ON image load");

                    reader.onloadend = () => {
                        // Set the base64 encoded image data to the state
                        // @ts-ignore
                        setBase64Image(reader.result);
                        console.log(reader.result);
                    };

                    // Read the file as a data URL, which will result in base64 format
                    // @ts-ignore
                    reader.readAsDataURL(e.target.files[0]);
                }}/>
            </div>
            <div className="flex justify-between items-center">
                <div className="flex gap-4 items-center w-fit">
                    <div>
                        <input onChange={() => {
                            setVnSelected(!vnSelected);
                            if (!vnSelected) {
                                setKrSelected(false);
                                setEnSelected(false);
                            }
                        }} checked={vnSelected} type={"checkbox"}/>
                    </div>
                    <div>
                        <label>Tiếng Việt</label>
                    </div>
                </div>
                <div className="flex gap-4 items-center w-fit">
                    <div>
                        <input onChange={() => {
                            setEnSelected(!enSelected);
                            if (!enSelected) {
                                setKrSelected(false);
                                setVnSelected(false);
                            }
                        }} checked={enSelected} type={"checkbox"}/>
                    </div>
                    <div>
                        <label>Tiếng Anh</label>
                    </div>
                </div>
                <div className="flex gap-4 items-center w-fit">
                    <div>
                        <input onChange={() => {
                            setKrSelected(!krSelected);
                            if (!krSelected) {
                                setEnSelected(false);
                                setVnSelected(false);
                            }
                        }} checked={krSelected} type={"checkbox"}/>
                    </div>
                    <div>
                        <label>Tiếng Hàn</label>
                    </div>
                </div>
            </div>
            <div>
                <ReactQuill value={editedContent} modules={modules} formats={formats} onChange={setEditedContent}/>
            </div>
            {missingInfo ? <div className="text-lg text-red-500">
                <label>Thiếu thông tin</label>
            </div> : null}
            <div>
                <div className="w-1/2 mx-auto" >
                    {startUpload ? <div className=" bg-blue-200 text-center py-2 w-full rounded-xl hover:bg-blue-50 active:bg-blue-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mx-auto animate-spin">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                    </div> : <button onClick={handlePostNews} className="bg-blue-200 text-center py-2 w-full rounded-xl hover:bg-blue-50 active:bg-blue-500">Đăng bài</button>}
                </div>
            </div>
        </div>
    </div>
}

export default NewsUploadPage;
