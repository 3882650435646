import React, {useEffect, useState} from 'react';
import NavBar from "./components/navbar/NavBar";
import {Routes, Route} from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutUsPage from "./pages/AboutUsPage";
import HiringPage from "./pages/HiringPage";
import ContactPage from "./pages/ContactPage";
import Footer from "./components/Footer";
import NewsUploadPage from "./pages/NewsUploadPage";
import NewsPage from "./pages/NewsPage";
import NewsContent from "./pages/NewsContent";
import AdminNewsPage from "./pages/AdminNewsPage";
import {LanguageType, removePunctuation, webContent} from "./pages/utils";
import FacebookMsg from "./components/FacebookMsg";
import axios from "axios";
import {AppContextPageProvider} from "./context/app-context";
import MegatonPage from "./pages/MegatonPage";
import ScienceBox from "./pages/ScienceBoxPage";
import Drone from "./pages/Drone";
import Robotics from "./pages/Robotics";



export const LanguageContext = React.createContext<LanguageType | null>(null);

type NewsRouteType = {
    route: string,
    id: string,
    titleImg: string
}

function App() {
    const [language, setLanguage] = useState("vn");
    const [pageContent, setPageContent] = useState(webContent.vn);
    const [isHomePage, setIsHomePage] = useState(false);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [newsRoutes, setNewsRoutes] = useState<NewsRouteType[]>([])

    const updateWindowSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };

    useEffect(() => {
        console.log(windowSize);
    },[windowSize])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/blog`).then(res => {
            console.log(res);
            let newsRoutes: NewsRouteType[] = [];
            for (let i = 0; i < res.data.length; i++) {
                newsRoutes.push({
                    route: removePunctuation(res.data[i].title),
                    id: res.data[i].blod_id,
                    titleImg: res.data[i].img
                })
            }
            setNewsRoutes(newsRoutes);
        }).catch(err => {
            console.log(err);
        })
    }, [])

    useEffect(() => {
        // Update window size whenever the window is resized
        window.addEventListener('resize', updateWindowSize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateWindowSize);
        };
    }, []);

    useEffect(() => {
        if (localStorage.meta_square_website_language === undefined) {
            localStorage.meta_square_website_language = "vn"
        }else {
            setLanguage(localStorage.meta_square_website_language);
            if (localStorage.meta_square_website_language === 'vn') {
                setPageContent(webContent.vn);
            }else if (localStorage.meta_square_website_language === 'kr') {
                setPageContent(webContent.kr);
            }else {
                setPageContent(webContent.en);
            }
        }
    }, [])

    useEffect(() => {
        window.history.scrollRestoration = 'manual'
    }, []);

    useEffect(() => {

        if (language === 'vn') {
            setPageContent(webContent.vn);
        }else if (language === 'kr') {
            setPageContent(webContent.kr);
        }else {
            setPageContent(webContent.en);
        }
    }, [language])



    return (<div className="relative">
    <LanguageContext.Provider value={{language, setLanguage, pageContent, windowSize, setIsHomePage}}>
        <AppContextPageProvider >
            <NavBar/>
            <Routes>
                <Route path={"/"} element={<HomePage/>} />
                <Route path={"/products/robotics-ai"} element={<ScienceBox/>} />
                <Route path={"/products/megaton"} element={<MegatonPage/>} />
                <Route path={"/about-us"} element={<AboutUsPage/>}/>
                <Route path={"/about-us/drone"} element={<Drone/>}/>
                <Route path={"/about-us/robotics"} element={<Robotics/>}/>
                <Route path={"/hiring"} element={<HiringPage/>}/>
                <Route path={"/contact"} element={<ContactPage/>}/>
                <Route path={"/news"} element={<NewsPage/>}/>
                {newsRoutes.map((news, index) => <Route key={index} path={`/news/${news.route}`} element={<NewsContent titleImg={news.titleImg} news_id={news.id}/>}/>)}
                <Route path={"/news-upload"} element={<NewsUploadPage/>}/>
                <Route path={"/news-content"} element={<NewsContent news_id={null} titleImg={undefined}/>}/>
                <Route path={"/news/admin"} element={<AdminNewsPage/>}/>
            </Routes>
        </AppContextPageProvider>
        <FacebookMsg/>
        <div className="fixed bottom-10 z-50 right-10 bg-red-500 p-5 rounded-full animate-bounce ">
            <a href="tel:+84817665064">
                <div >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#FFFFFF" className="w-5 h-5 ">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                    </svg>
                </div>
            </a>
        </div>
        <Footer/>
    </LanguageContext.Provider>
        </div>
    );
}

export default App;
