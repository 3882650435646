import React from "react";
import titleBg from "../assests/title.jpg";
import megatonColorLogo from "../assests/sb_logo.png";
import sb3 from '../assests/SB3.png'
import sb4 from '../assests/SB4.png'

const TitleSection = () => {
    return <section className={`w-full h-screen mt-0 sec-2 relative pt-4`}>
        <div className="w-full absolute -z-20 top-0 sec-2-img-bg mb-10 opacity-25">
            <img className="w-full " src={titleBg} alt={"Product_BG_IMG"} />
        </div>
        <div className="w-full h-full ">
            <div className="w-full h-fit flex items-center justify-center">
                <div className=" md:w-[200px] lg:w-[400px] h-auto">
                    <img className="w-full h-full" src={sb3} alt={"MegatonBox"}/>
                </div>
                <div className="md:w-[200px] lg:w-[400px] h-auto">
                    <img className="w-full h-full" src={sb4} alt={"MegatonBox"}/>
                </div>
            </div>
            <div className="w-[50px] h-auto mx-auto brightness-200">
                <img className="w-full h-full" src={megatonColorLogo} alt={"Color Logo"}/>
            </div>
            <div className="flex flex-col items-center gap-5">
                <div className="text-3xl font-meta_header text-black text-center mt-4">
                    <label>GIẢI PHÁP STEM ỨNG DỤNG</label>
                </div>
                <div className="text-xl text-black font-meta_content w-[400px] mx-auto text-center mt-2">
                    <label>
                        Bộ học cụ Robotics AI (NO3, NO6)
                        giải pháp nâng tầm sáng tạo
                    </label>
                </div>
                <div className="w-full flex items-center font-meta_content justify-center mt-2">
                    <button className="bg-red-600 px-10 py-2 text-2xl rounded-3xl mx-auto text-white">Mua</button>
                </div>
            </div>
        </div>
    </section>
}

export default TitleSection;
