import React from "react";
import uni from '../assests/jeonnam_uni.png';
import {useNavigate} from "react-router-dom";

const InternationalCompetition = () => {
    const navigate = useNavigate();

    return <section className="w-full h-fit mt-8">
        <div className="w-fit mx-auto text-3xl font-meta_header text-[#00B0F0]">
            <label>DU ĐẤU QUỐC TẾ
            </label>
        </div>
        <div className="w-4/5 h-fit mx-auto text-center mt-3 font-meta_content">
            <label>Ngoài ra các học sinh sẽ được tham gia các giải thi đấu khoa học sáng tạo thường niên tại Hàn Quốc vào tháng 7 do Đại học Jeonnam State tổ chức.
            </label>
        </div>
        <div className="w-fit mx-auto mt-7">
            <button onClick={() => {
                navigate('/contact');
                window.location.reload();
            }} className="font-meta_content text-[#00B0F0] border-2 border-[#0070C0] text-sm bg-none px-5 py-1.5 ">LIÊN HỆ
            </button>
        </div>
        <div className="w-[480px] mx-auto h-[300px] mt-6 rounded-3xl relative">
            <img src={uni} alt="UNI" className="w-full h-full rounded-3xl"/>
        </div>
    </section>
}

export default InternationalCompetition;
