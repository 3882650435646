import React, {useEffect, useState} from "react";
import {MetaSlider} from "../../../Slider";
import {NewsBox} from "../../../News";
import {News} from "../../../../interface/NewsInterface";
import axios from "axios";

const NewsSection = () => {
    const [news, setNews] = useState<News[]>([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/blog`).then(res => {
            console.log(res);
            setNews(res.data);
        }).catch(err => {
            console.log(err);
        })
    }, []);

    return <section className="w-full mt-0 h-fit bg-[#162236] pt-20">
        <div className="w-fit mx-auto text-3xl text-white border-2 border-gray-100 px-10 py-4">
            <label>Tin Tức</label>
        </div>
        <div className="h-2/3 flex flex-col items-center justify-center">
            <MetaSlider left={5} gap={10} >
                {news.map((blog, index) => <NewsBox newsId={blog.blod_id} title={blog.title} img={blog.img} description={blog.summary}/>)}
            </MetaSlider>
        </div>
    </section>
}

export default NewsSection;
