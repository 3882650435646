import React, {useContext} from "react";
import {MetaButtonProps} from "../interface/MetaButtonInterface";



const MetaButton: React.FC<MetaButtonProps> = ({onClick, className, content}) => {

    return <div className="bg-gradient-to-r from-[#FFE259] from-70% to-[#FFA751] hover:border-2 hover:border-[#FFE259] hover:bg-none w-fit px-6 py-2 mt-14 rounded-xl active:bg-orange-600" >
        <button onClick={onClick}>{content}</button>
    </div>
}

export default MetaButton;
