import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import './css/blog_css.css'
import axios from "axios";
import {LanguageContext} from "../App";
import {LanguageType} from "./utils";
import {LatestNewsContainer} from "./NewsPage";
import {News} from "../interface/NewsInterface";

interface NewsContentProps {
    blod_id: string,
    title: string,
    summary: string,
    content: string,
    enTitle: string,
    enSummary: string,
    krTitle: string,
    krSummary: string,
    img: string,
    enContent: string,
    krContent: string,
    date: string
}

type NewsContentDOMProps = {
    news_id: string | null
    titleImg: string | undefined
}

const NewsContent = ({news_id, titleImg}: NewsContentDOMProps) => {
    const location = useLocation();
    // const news = location.state.news;
    const [isLoading, setIsLoading] = useState(true);
    const {language, pageContent, windowSize} = useContext(LanguageContext) as LanguageType;
    const [onLatestNewsClick, setOnLatestNewsClick] = useState(true);
    const [content, setContent] = useState('');
    const [newsArr, setNewsArr] = useState<News[]>([]);
    const [newsContent, setNewsContent] = useState<NewsContentProps>({
        blod_id: "",
        title: "",
        summary: "",
        content: "",
        enTitle: "",
        enSummary: "",
        krTitle: "",
        krSummary: "",
        img: "",
        enContent: "",
        krContent: "",
        date: ""
    })



    useEffect( () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}api/blog`).then(res => {
                console.log(res);
                setNewsArr(res.data);
            }).catch(err => {
                console.log(err);
            })

            axios.get(`${process.env.REACT_APP_API_URL}api/blog/single?blog_id=${(news_id !== null) ? news_id : "1"}`).then(res => {
                console.log(res.data);
                // setContent(res.data);
                setNewsContent(res.data);
                setIsLoading(false);
            }).catch(err => {
                console.log(err);
            })

        } catch (error) {
            console.error('Error fetching file content:', error);
        }
    }, []);

    const createMarkup = () => {
        return { __html: (language === 'vn') ? newsContent.content : (language === 'en') ? newsContent.enContent : newsContent.krContent};
    };



    return <div className="w-screen h-fit mt-20 px-10 lg:flex lg:gap-4 items-start">
        <div className="w-full lg:w-1/2 lg:ml-28">
            <div className="text-4xl font-bold font-meta_header w-full">
                <label className="break-words w-full ">{(language == 'vn') ? newsContent.title : (language == 'en') ? newsContent.enTitle : newsContent.krTitle}</label>
            </div>
            <div className="text-xl font-medium font-meta_content mt-5 w-full">
                <label className="break-words">{(language == 'vn') ? newsContent.summary : (language == 'en') ? newsContent.enSummary : newsContent.krSummary}</label>
            </div>
            <div className="w-1/3 mx-auto mb-6 mt-6 ">
                <img className="w-full h-full" src={titleImg}/>
            </div>
            <div className="break-words font-meta_content pr-10  w-full">
                <div dangerouslySetInnerHTML={createMarkup()} />
            </div>
            {isLoading ? <div className=" text-center py-2 w-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mx-auto animate-spin">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
            </div> : null}
        </div>
        <div className="w-full mt-10 lg:w-1/3 lg:mt-0">
            <div onClick={() => {
                setOnLatestNewsClick(!onLatestNewsClick);
            }} className="mx-auto border-b-4 border-gray-400 font-meta_header text-xl lg:mx-0 hover:bg-white py-3 px-6 w-4/5 flex gap-8">
                <div>
                    <label>{pageContent.newsPage.latestNews}</label>
                </div>
                <div className={`transition-all duration-500 ${onLatestNewsClick ? '-rotate-90' : 'rotate-0'}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg>
                </div>
            </div>
            <div className={`wrapper mx-auto w-5/6 lg:mx-0 ${onLatestNewsClick ? 'open py-4 px-8 bg-white' : ''} mt-3`}>
                <div className={`${onLatestNewsClick ? '' : 'expandable'} flex flex-col gap-10`}>
                    {newsArr.map((news, index) => (index < 5) ? <div>
                        <LatestNewsContainer key={news.blod_id} news={news}/>
                    </div> : null)}
                </div>
            </div>

        </div>
    </div>

}

export default NewsContent;
