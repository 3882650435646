import React from "react";

const Competition = () => {
    return <section className="w-full h-fit ">
        <div className="w-full h-fit mx-auto mb-5 relative rounded-3xl">
            <iframe
            
                className=" mx-auto rounded-iframe"
                width={480}
                height={270}
                src={`https://www.youtube.com/embed/${'DIFDnhLjwks'}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
            <div className="w-full  text-sm text-center  absolute bottom-0 font-meta_header">
                <div className="text-[#FFC000] w-2/5 mx-auto bg-white py-0.5  rounded-t-xl">
                    <label>CHƯƠNG TRÌNH<br/>
                    VÀ CUỘC THI</label>
                </div>
            </div>
        </div>
    </section>
}

export default Competition;

{/* <label>CHƯƠNG TRÌNH<br/>
                VÀ CUỘC THI
            </label> */}