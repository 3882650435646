import React, {useContext, useEffect, useRef, useState} from "react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './home.css'
import {HomePageWeb, HomePagePhone} from "../components/pages/HomePage";
import {LanguageContext} from "../App";
import {LanguageType} from "./utils";


const HomePage = () => {
    const {windowSize} = useContext(LanguageContext) as LanguageType;

    return <div>
        {windowSize.width < 700 ? <HomePagePhone/> : <HomePageWeb/>}
    </div>
}

export default HomePage;
