import React from "react";
import Banner from "./Banner";
import Products from "./Products";
import Competition from "./Competition";
import Skills from "./Skills";
import Partners from "./Partners";
import NewsSection from "./News";
import './style.css'

const HomePagePhone = () => {
    return <div>
        <Banner/>
        <Products/>
        <Competition/>
        <Skills/>
        <Partners/>
        <NewsSection/>
    </div>
}

export default HomePagePhone;
