import React from "react";
import {partnersImg} from "../../../../pages/utils";
import {MetaSlider} from "../../../Slider";

const Partners = () => {
    return <section className="w-full mt-10 mb-10">
        <div className="text-xl font-meta_header text-[#FFC000] ml-9 mb-8">
            <label>ĐỐI TÁC
            </label>
        </div>
        <MetaSlider gap={14} className=" py-4 rounded-2xl bg-gradient-to-l from-[#A9ACB4] via-[#C7C9CE] via-50% to-[#E4E5E7] to-90%">
            {partnersImg.map((partner, index) => <img key={index} src={partner} className="w-[100px]"/>)}
        </MetaSlider>
    </section>
}

export default Partners;
