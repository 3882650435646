import React from "react";
import megatonColorLogo from "../assests/sb_logo.png";

const VideoIntroduction = () => {
    return <section className={`w-full h-[500px] mt-0 sec-2 relative pt-5 bg-gray-100 pb-20`}>
        <div className="absolute top-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="w-[80px] h-auto mx-auto brightness-200">
                <img className="w-full h-full" src={megatonColorLogo} alt={"Color Logo"}/>
            </div>
        </div>
        <div className="flex items-center justify-center h-full w-full mt-10 gap-20">
            {/*Content Container*/}
            <div className="w-1/3 h-auto pl-10">
                <div className="font-meta_header text-2xl text-center">
                    <label>ỨNG DỤNG</label>
                </div>
                <div className="w-full text-justify font-meta_content mx-auto mt-4 text-md ">
                    <label>Bộ công cụ Sciencebox giúp trẻ em phát triển vượt trội về khả năng tư duy logic, kết hợp với kiến thức khoa học ứng dụng thông qua bộ môn lắp ráp kĩ thuật cơ khí và lập trình. Đặc biệt, các học sinh sẽ được tham gia các giải thi đấu khoa học sáng tạo thường niên do Sciencebox tổ chức trong nước và quốc tế.
                    </label>
                </div>
                <div className="w-full flex items-center justify-start  mt-10">
                    <div className="w-fit flex items-center font-meta_content justify-center">
                        <button className="bg-red-600 px-10 py-2 text-lg rounded-3xl mx-auto text-white">Mua</button>
                    </div>
                    <div className="text-lg ml-20  font-meta_content text-[#0070C0] font-bold underline ">
                        <label>Tìm Hiểu Thêm</label>
                    </div>
                </div>
            </div>

            {/*Video Container*/}
            <div className="w-1/3 h-full flex items-center justify-center  ">
                <iframe
                    width={544}
                    height={306}
                    src={`https://www.youtube.com/embed/${'ZgTeVeysKTA'}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    </section>
}

export default VideoIntroduction;
