import React from "react";
import titleBg from "../../Drone/assests/line_tech.png";
import pic1 from '../assests/1_.png';
import pic2 from '../assests/2_.png';
import pic3 from '../assests/3_.png';
import '../../Drone/web/style.css'
import {useNavigate} from "react-router-dom";

const AboutSection = () => {
    const navigate = useNavigate();

    return <section className={`w-full h-fit mt-0 relative pt-16 about-sec pb-16`}>
    <div className="opacity-50 w-[800px] h-auto absolute -z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mb-10">
        <img className="w-full " src={titleBg} alt={"Product_BG_IMG"} />
    </div>
    <div className="w-full h-full flex items-center justify-center ">
        <div className="w-1/2 h-full flex flex-col items-start gap-4 justify-center pl-32">
            <div className="text-xl font-meta_content font-bold text-[#0070C0]">
                <label>Robotics AI</label>
            </div>
            <div className="font-meta_header text-5xl mt-2">
                <label>VỀ KHÓA HỌC</label>
            </div>
            <div className="w-4/5 text-md mt-2 font-meta_content">
                <label>AI Robotics sử dụng bộ công cụ Sciencebox giúp học sinh phát triển vượt trội về khả năng tư duy logic, kết hợp với kiến thức khoa học ứng dụng thông qua bộ môn lắp ráp kĩ thuật cơ khí và lập trình.
                </label>
            </div>
            <div className="mt-2">
                <button onClick={() => {
                    navigate('/contact');
                    window.location.reload();
                }} className="px-7 py-1 text-lg border-2 border-[#41719C] text-[#0070C0]">Liên hệ</button>
            </div>
        </div>
        <div className="relative w-1/2 h-[400px]">
            <img src={pic3} alt={'pic'} className=" absolute bottom-0 left-72 w-[300px] z-30 h-auto rounded-3xl" />
            <img src={pic2} alt={'pic'} className=" absolute  -top-10 left-96 z-10 w-[300px] h-auto rounded-3xl" />
            <img src={pic1} alt={'pic'} className=" absolute  top-5 left-32 z-40 w-[290px] h-auto rounded-3xl" />
        </div>
    </div>
</section>
}

export default AboutSection;

