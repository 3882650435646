import React from "react";
import act1 from '../assests/activity/z5116168957362_41139bc03d90586874d8292fe58d77d7.jpg';
import act2 from '../assests/activity/412425462_667879058881251_422874645598483499_n.jpg';
import act3 from '../assests/activity/647394-nam00536jpg.jpg';
import act4 from '../assests/activity/z5116168957362_41139bc03d90586874d8292fe58d77d7.jpg';
import act5 from '../assests/activity/647394-nam00536jpg.jpg';
import {MetaSlider} from "../../../Slider";

const activities = [
    act1,
    act2,
    act3,
    act4,
    act5
]

const Activity = () => {
    return <section className=" h-fit mt-0 sec-2 relative pt-5 bg-[#BDD7EE] pb-10 mb-10 rounded-3xl">
        <div className="w-full">
            <div className="w-fit text-5xl mx-auto font-meta_header font-bold mt-5 text-white">
                <label>HOẠT ĐỘNG</label>
            </div>
            <MetaSlider className="mt-14" gap={10}>
                {activities.map((activity, index) => <img key={index} className="w-[400px] h-auto rounded-3xl" src={activity} alt={"ACT"}/>)}
            </MetaSlider>
        </div>
    </section>
}

export default Activity;
