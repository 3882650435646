import React, {ReactNode, useRef, useEffect} from "react";
import './style.css'
type MetaSliderProps = {
    children: ReactNode;
    className?: string;
    arrow?: boolean;
    handleScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
    gap?: number;
    left?: number;
    bottom?: number;
    autoplay?: boolean;
    playTime?: number;
}

const MetaSlider = ({children, className, arrow, handleScroll, gap, left, bottom, autoplay, playTime}: MetaSliderProps) => {
    const sliderRef = useRef<HTMLDivElement>(null);

    const onScrollLeft = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollTo({
                left: sliderRef.current.scrollLeft - 550, // Adjust the scroll amount as needed
                behavior: 'smooth' // Add smooth scrolling behavior
            });
        }
    }

    const onScrollRight = () => {
        if (sliderRef.current) {
            const container = sliderRef.current;
            if (parseInt((container.scrollLeft + container.clientWidth).toString()) === container.scrollWidth) {
                console.log("Reach end")
                sliderRef.current.scrollTo({
                    left: 0, // Adjust the scroll amount as needed
                    behavior: 'smooth' // Add smooth scrolling behavior
                });
            }else {
                sliderRef.current.scrollTo({
                    left: sliderRef.current.scrollLeft + 550, // Adjust the scroll amount as needed
                    behavior: 'smooth' // Add smooth scrolling behavior
                });
            }
        }


    }

    useEffect(() => {
        const scrollSpeed = 50; // Adjust the scroll speed as needed
        let scrollAmount = 450;
        const scrollInterval = 1000; // Time in milliseconds for each scroll step

        const scroll = () => {
            if (sliderRef.current) {
              sliderRef.current.scrollBy({
                top: 0,
                left: scrollAmount,
                behavior: 'smooth',
              });
      
              // Reset scroll when reaching the end
              if (sliderRef.current.scrollLeft + sliderRef.current.clientWidth >= sliderRef.current.scrollWidth) {
                sliderRef.current.scrollTo({ left: 0, behavior: 'smooth' });
              }
            }
        };
    

        let scrollTime = 1000;
        if (autoplay) {
            
            if (playTime) {
                scrollTime = playTime;
            }
            
        }

        let intervalId = setInterval(scroll, scrollTime);
        
        
        console.log("IN HERE SCROLL");
            
        if (autoplay) {
            return () => clearInterval(intervalId);
        }
        
      }, []);

    return <div className={`w-full h-fit ${className} flex items-center justify-between relative `}>

        {arrow ? <div onClick={onScrollLeft} className="ml-10">
            <div className="w-0 h-0
                  border-t-[50px] border-t-transparent
                  border-r-[75px] border-r-[#162236]
                  border-b-[50px] border-b-transparent">
            </div>
        </div> : null}
        <div onScroll={handleScroll} ref={sliderRef} className={`flex items-center justify-start overflow-x-scroll pb-${bottom} ml-${left ? left : 0} gap-${(gap) ? gap : 0} w-full no-scrollbar`}>
            {children}
        </div>
        {arrow ? <div onClick={onScrollRight} className="mr-10">
            <div className="w-0 h-0
                  border-t-[50px] border-t-transparent
                  border-l-[75px] border-l-[#162236]
                  border-b-[50px] border-b-transparent">
            </div>
        </div> : null}
    </div>
}

export default MetaSlider;
