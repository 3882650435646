import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import {NewsContainerProps} from "../interface/NewsInterface";
import '../pages/css/news_container.css'
import {removePunctuation} from "../pages/utils";



const NewsContainer: React.FC<NewsContainerProps> = ({news, elements, language}) => {
    const [titleImg, setTitleImg] = useState('');
    const navigate = useNavigate();

    useEffect( () => {
        try {
            axios.get(`https://test-client-stem.s3.amazonaws.com/blog-${news.blod_id}.txt`).then(res => {
                setTitleImg(res.data);
            }).catch(err => {
                console.log(err);
            })

        } catch (error) {
            console.error('Error fetching file content:', error);
        }
    }, []);


    return <div onClick={() => {
        navigate(`/news/${removePunctuation(news.title)}`);
        window.location.reload();
    }} className="relative cursor-pointer flex flex-col items-center justify-start max-w-[550px] my-auto h-[550px] rounded-xl border-2 border-gray-200">
        <div className={`cursor-pointer w-full ${(elements == 1) ? 'h-64' : 'h-64'} rounded-xl cursor-pointer`}>
            <img className="w-full h-full rounded-xl hover:scale-105" src={titleImg} />
        </div>
        <div className="cursor-pointer w-full  py-1 px-2 md:p-4 cursor-pointer">
            <div className="cursor-pointer w-full h-fit">
                <div className="font-bold font-meta_header  text-xl">
                    <label>{(language == 'vn') ? news.title : (language == 'en') ? news.enTitle : news.krTitle}</label>
                </div>
                <div className="w-full h-10 font-meta_content">
                    <p className="summary">{(language == 'vn') ? news.summary : (language == 'en') ? news.enSummary : news.krSummary}</p>
                </div>
                <div className="text-left flex w-full font-meta_content">
                    <label className="w-full">{news.date}</label>
                </div>
            </div>
            <div className="absolute bottom-4 text-left flex font-meta_content text-cyan-600 border-b-2 border-cyan-600 w-fit mt-16 hover:text-gray-400 hover:border-gray-400">
                <Link to={'/news-content'} state={{news: news}} className="">Xem thêm</Link>
            </div>
        </div>
    </div>
}
export default NewsContainer;
