import React, {ChangeEvent, useContext, useState} from "react";
import axios from "axios";
import {LanguageContext} from "../App";
import {LanguageType, webContent} from "./utils";
import MetaButton from "../components/MetaButton";
import banner from '../assets/TUYỂN DỤNG.png'

interface FormInfo {
    name: string,
    phone: string,
    email: string,
    position: string,
    teachingSkill: string,
    englishSkill: string,
    content: string
}

const HiringPage = () => {
    const {language, pageContent} = useContext(LanguageContext) as LanguageType;
    const [formInfo, setFormInfo] = useState<FormInfo>({
        name: "",
        phone: "",
        email: "",
        position: "",
        teachingSkill: "",
        englishSkill: "",
        content: ""
    })

    const [teacherCheck, setIsTeacherCheck] = useState(false);
    const [officerCheck, setIsOfficerCheck] = useState(false);
    const [marketingCheck, setIsMarketingCheck] = useState(false);
    const [isTeachingSkillCheck, setIsTeachingSkillCheck] = useState(false);
    const [isEnglishSkillCheck, setIsEnglishSkillCheck] = useState(false);
    const [alert, setAlert] = useState(false);

    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setAlert(false);
        const { name, value } = e.target;
        setFormInfo((formInfo) => ({
            ...formInfo,
            [name]: value,
        }));
    };

    const onSubmitHandle = () => {
        if ((formInfo.name.length == 0) || (formInfo.position.length == 0) || (formInfo.email.length == 0) || (formInfo.phone.length == 0)) {
            setAlert(true)
        }else {
            console.log(formInfo);
            axios.post(`${process.env.REACT_APP_API_URL}api/notification`, formInfo, {
                headers: {

                }
            }).then(res => {
                console.log(res);
                window.location.reload();
            }).catch(err => {
                console.log(err);
            })
        }
    }

    return <div className="w-screen font-custom1 tracking-wide">
        <div className="w-full h-auto brightness-75">
            <img src={banner} alt="Banenr" className="w-full h-full"/>
        </div>
        {/*Introduce banner*/}
        <div className="w-full mx-auto md:ml-10 md:w-2/3 rounded-2xl bg-white px-6 py-10 flex flex-col gap-10">
            <div className="w-full flex flex-col md:flex-row gap-5 md:gap-0 items-center pb-16 border-b-2 border-b-gray-100">
                <div className="text-3xl w-full text-center md:text-start md:w-3/12">
                    <label>{pageContent.hiringPage.name}</label>
                </div>
                <div className="w-full md:w-2/3">
                    <input onChange={handleInputChange} name={"name"}  className="bg-transparent w-full h-full px-2 py-4 rounded-2xl" type={"text"} placeholder={pageContent.contactPage.infoForm.nameField} value={formInfo.name} style={{outline: 'none'}}/>
                </div>
            </div>
            <div className="w-full flex flex-col md:flex-row gap-5 md:gap-0 items-center pb-16 border-b-2 border-b-gray-100">
                <div className="text-3xl w-full text-center md:text-left md:w-3/12">
                    <label>{pageContent.hiringPage.phone}</label>
                </div>
                <div className="w-full md:w-2/3">
                    <input onChange={handleInputChange} name={"phone"} value={formInfo.phone} className="bg-transparent w-full h-full px-2 py-4 rounded-2xl" type={"text"} placeholder={pageContent.contactPage.infoForm.phoneField} style={{outline: 'none'}}/>
                </div>
            </div>
            <div className="w-full flex flex-col md:flex-row gap-5 md:gap-0 items-center pb-16 border-b-2 border-b-gray-100">
                <div className="text-3xl w-full text-center md:text-left md:w-3/12">
                    <label>{pageContent.hiringPage.email}</label>
                </div>
                <div className="w-full md:w-2/3">
                    <input onChange={handleInputChange} name={"email"} value={formInfo.email} className="bg-transparent w-full h-full px-2 py-4 rounded-2xl" type={"text"} placeholder={pageContent.contactPage.infoForm.emailField} style={{outline: 'none'}}/>
                </div>
            </div>
            <div className="w-full flex flex-col gap-5 md:gap-0 md:flex-row items-center pb-16 border-b-2 border-b-gray-100">
                <div className="text-3xl w-full text-center md:text-left md:w-3/12">
                    <label>{pageContent.hiringPage.position.title}</label>
                </div>
                <div className="w-full md:w-2/3 px-2 py-1.5 flex items-center justify-between">
                    <div className="flex gap-4 items-center">
                        <input onChange={() => {
                            setIsTeacherCheck(!teacherCheck);
                            if (!teacherCheck) {
                                setFormInfo({...formInfo, position: 'Giao Vien'});
                                setIsMarketingCheck(false);
                                setIsOfficerCheck(false);
                            }

                        }} checked={teacherCheck} type={"checkbox"}/>
                        <label>{pageContent.hiringPage.position.content[0]}</label>
                    </div>
                    <div className="flex gap-4 items-center">
                        <input onChange={() => {
                            setIsOfficerCheck(!officerCheck);
                            if (!officerCheck) {
                                setFormInfo({...formInfo, position: 'Trợ giảng'});
                                setIsMarketingCheck(false);
                                setIsTeacherCheck(false);
                            }
                        }} checked={officerCheck} type={"checkbox"}/>
                        <label>{pageContent.hiringPage.position.content[1]}</label>
                    </div>
                    <div className="flex gap-4 items-center">
                        <input onChange={() => {
                            setIsMarketingCheck(!marketingCheck);
                            if (!marketingCheck) {
                                setFormInfo({...formInfo, position: 'Marketing'});
                                setIsOfficerCheck(false);
                                setIsTeacherCheck(false);
                            }
                        }} checked={marketingCheck} type={"checkbox"}/>
                        <label>{pageContent.hiringPage.position.content[2]}</label>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col gap-5 md:gap-0 md:flex-row items-center pb-16 border-b-2 border-b-gray-100">
                <div className="text-3xl w-full text-center md:text-left md:w-3/12">
                    <label>{pageContent.hiringPage.certificate.title}</label>
                </div>
                <div className="w-full md:w-2/3 px-2 py-1.5 flex items-center justify-between">
                    <div className="flex gap-4 items-center">
                        <input onChange={() => {
                            setIsTeachingSkillCheck(!isTeachingSkillCheck);
                            if (!isTeachingSkillCheck) {
                                setFormInfo({...formInfo, teachingSkill: '1'});

                            }else {
                                setFormInfo({...formInfo, teachingSkill: '0'});
                            }

                        }} checked={isTeachingSkillCheck} type={"checkbox"}/>
                        <label>{pageContent.hiringPage.certificate.content[0]}</label>
                    </div>
                    <div className="flex gap-4 items-center">
                        <input onChange={() => {
                            setIsEnglishSkillCheck(!isEnglishSkillCheck);
                            if (!isEnglishSkillCheck) {
                                setFormInfo({...formInfo, englishSkill: '1'});
                            }else {
                                setFormInfo({...formInfo, englishSkill: '0'});
                            }
                        }} checked={isEnglishSkillCheck} type={"checkbox"}/>
                        <label>{pageContent.hiringPage.certificate.content[1]}</label>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col gap-5 md:gap-0 md:flex-row items-start pb-16 border-b-2 border-b-gray-100">
                <div className="text-3xl w-full text-center md:text-left md:w-3/12">
                    <label>{pageContent.hiringPage.content}</label>
                </div>
                <textarea
                    onChange={handleInputChange}
                    name={"content"}
                    className="rounded-2xl w-full md:w-2/3 h-[200px]"
                    placeholder={pageContent.contactPage.infoForm.contentField}
                    style={{outline: 'none'}}
                ></textarea>
            </div>
            <div>
                {alert ? <div className="text-red-600 text-lg">
                    <label>Vui lòng điền đầy đủ thông tin</label>
                </div> : null}
                <MetaButton className={""} onClick={onSubmitHandle} content={pageContent.hiringPage.button}/>
            </div>
        </div>
    </div>


}

export default HiringPage;
