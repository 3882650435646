import React, {useContext} from "react";
import {LanguageContext} from "../App";
import {LanguageType} from "./utils";
import {MegatonPagePhone, MegatonPageWeb} from "../components/pages/Megaton";

const MegatonPage = () => {
    const {windowSize} = useContext(LanguageContext) as LanguageType;

    return <div>
        {windowSize.width < 700 ? <MegatonPagePhone/> : <MegatonPageWeb/>}
    </div>
}

export default MegatonPage;
