import React from "react";
import titleBg from "../../Drone/assests/line_tech.png";
import skill3 from "../../Drone/assests/coding.png";
import skill2 from "../../Drone/assests/creative.png";
import skill4 from "../../Drone/assests/teamwork.png";
import skill1 from "../../Drone/assests/logical.png";
import {useNavigate} from "react-router-dom";

const AboutCourse = () => {
    const navigate = useNavigate();

    return <section className="w-full h-fit relative mt-10">
        <div className=" w-[700px] opacity-30 rotate-45 h-auto absolute -z-20 top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mb-10">
            <img className="w-full " src={titleBg} alt={"Product_BG_IMG"} />
        </div>
        <div className="w-fit h-fit font-meta_header text-3xl text-[#00B0F0] mx-auto">
            <label>VỀ KHÓA HỌC</label>
        </div>
        <div className="w-2/3 h-fit text-center mx-auto mt-8 font-meta_content">
            <label>AI Robotics giúp học sinh kết hợp với kiến thức khoa học ứng dụng thông qua bộ môn lắp ráp kĩ thuật cơ khí và lập trình.
            </label>
        </div>
        <div className="w-fit mx-auto mt-10">
            <button onClick={() => {
                navigate('/contact');
                window.location.reload();
            }} className="font-meta_content text-[#00B0F0] border-2 border-[#0070C0] text-xl bg-none px-5 py-1.5 ">LIÊN HỆ
            </button>
        </div>
        <div className="flex flex-row flex-wrap gap-3 h-[500px] justify-center w-full mx-auto mt-20 mb-10">
            <div className="w-[200px] h-fit flex flex-col justify-center items-center">
                <div className="w-[100px] h-auto bg-[#5C9BD5] rounded-full">
                    <img src={skill3} alt="Skill" className="w-full h-auto"/>
                </div>
                <div className="text-xl text-center font-meta_content mt-3">
                    <label>KỸ NĂNG <br/>LẬP TRÌNH</label>
                </div>
            </div>
            <div className="w-[200px] h-fit flex flex-col justify-center items-center">
                <div className="w-[100px] h-auto bg-[#5C9BD5] rounded-full">
                    <img src={skill2} alt="Skill" className="w-full h-auto"/>
                </div>
                <div className="text-xl text-center font-meta_content mt-3">
                    <label>KỸ NĂNG <br/>SÁNG TẠO</label>
                </div>
            </div>
            <div className="w-[200px] h-fit flex flex-col justify-center items-center">
                <div className="w-[100px] h-auto bg-[#5C9BD5] rounded-full">
                    <img src={skill4} alt="Skill" className="w-full h-auto"/>
                </div>
                <div className="text-xl text-center font-meta_content mt-3">
                    <label>KỸ NĂNG <br/>LÀM VIỆC NHÓM</label>
                </div>
            </div>
            <div className="w-[200px] h-fit flex flex-col justify-center items-center">
                <div className="w-[100px] h-auto bg-[#5C9BD5] rounded-full">
                    <img src={skill1} alt="Skill" className="w-full h-auto"/>
                </div>
                <div className="text-xl text-center font-meta_content mt-3">
                    <label>KỸ NĂNG <br/>TƯ DUY LOGIC</label>
                </div>
            </div>
        </div>
        <div className="w-full h-fit mt-10 bg-[#BDD7EE] rounded-3xl pt-5 ">
            <div className="w-fit text-3xl text-white mx-auto font-meta_header">
                <label>ỨNG DỤNG</label>
            </div>
            <div className="w-2/3 text-center text-white font-meta_content text-lg mt-5 mx-auto">
                <label>AI Robotics là chương trình học Robotics được tích hợp thêm các ứng dụng AI…vào Robot giúp các em giải quyết các vấn đề và nhiệm vụ một cách công nghệ.
                </label>
            </div>
            <div className="w-fit mx-auto mt-10">
                <button className="font-meta_content text-[#00B0F0] border-2 border-[#0070C0] text-xl bg-none px-5 py-1.5 ">LIÊN HỆ
                </button>
            </div>
            <div className="w-full h-[300px] mx-auto mb-14 mt-10 ">
                <iframe
                    className="w-full h-[300px]"
                    src={`https://www.youtube.com/embed/${'DIFDnhLjwks'}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
            <div className="w-full h-[25px] ">

            </div>
        </div>
    </section>
}

export default AboutCourse;
