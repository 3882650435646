import React from "react";
import megatonBox from "../assests/megaton_box.png";
import megatonBoard from '../assests/megaton_board.png';
import megatonColorLogo from "../assests/color_logo.png";

const IntroductionSection = () => {
    return <section className={`w-full h-fit mt-0 sec-2 relative pt-5 bg-white pb-10`}>
        <div>
            <div className="w-[130px] h-auto mx-auto brightness-200">
                <img className="w-full h-full" src={megatonColorLogo} alt={"Color Logo"}/>
            </div>
        </div>
        <div className="flex items-center justify-center gap-16 mt-10 ">
            {/*IMG Container*/}
            <div className="relative w-1/3 h-[350px] ">
                <div className="absolute -top-10 left-10 w-[400px] h-auto">
                    <img className="w-full h-full" src={megatonBox} alt="Megaton Box"/>
                </div>
                <div className="absolute top-10 right-0 w-[260px] h-auto">
                    <img className="w-full h-full" src={megatonBoard} alt={"Megaton Board"} />
                </div>
            </div>

            {/*Content Container*/}
            <div className=" w-1/3 h-[400px] text-center">
                <div className="text-2xl text-black font-meta_header">
                    <label>VỀ MEGATON</label>
                </div>
                <div className="w-full text-md text-justify mt-8 font-meta_content">
                    <label>Megaton là bộ học cụ AI được tích hợp thêm các ứng dụng như nhận diện khuôn mặt, nhận diện giọng nói, nhận diện hành động cử chỉ,… vào Robot giúp các em giải quyết
                        các vấn đề và nhiệm vụ một cách công nghệ.
                        Phù hợp với mọi độ tuổi từ Trung học đến bậc Đại Học
                    </label>
                </div>

                <div className="text-[#002060] font-bold underline text-md mt-8 font-meta_content">
                    <label>4.900.000</label>
                </div>

                <div className="w-full flex items-center font-meta_content justify-center mt-8">
                    <button className="bg-red-600 px-10 py-2 text-md rounded-3xl mx-auto">Mua</button>
                </div>
            </div>
        </div>
    </section>
}

export default IntroductionSection;
