import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {LanguageContext} from "../../../../App";
import {certificates, LanguageType, partnersImg, skills} from "../../../../pages/utils";
import {News} from "../../../../interface/NewsInterface";
import tmpImg from "../../ScienceBox/assests/title.jpg";
import Products from "../../../Products";
import certificate_bg from "../../../../assets/ui_bg/section_bg_2.jpg";
import partner_title_bg from "../../../../assets/ui_bg/parner_title.png";
import {MetaSlider} from "../../../Slider";
import {NewsBox} from "../../../News";
import drone1 from '../../../../assets/drone_intro/1.png';
import drone2 from '../../../../assets/drone_intro/3.png';
import drone3 from '../../../../assets/drone_intro/2.png';
import robotics1 from '../../../../assets/robotics_intro/1_.png';
import robotics2 from '../../../../assets/robotics_intro/3_.png';
import robotics3 from '../../../../assets/robotics_intro/2_.png';
import line_tech from '../../Drone/assests/line_tech.png'
import axios from "axios";
import proFrame from '../assests/pro_frame.png';
import certFrame from '../assests/cert_frame.png';
import partnerFrame from '../assests/partner_frame.png';
import newsBg from '../assests/3.jpg';


type ContentProps = {
    title: string;
    description: string
}

const droneContent: ContentProps[] = [
    {
        title: 'DRONE ROBOT TRUNG HỌC',
        description: 'Giúp các em hiểu được nguyên lý khoa học cơ khí, Lập trình từ cơ bản đến nâng cao thông qua việc học lắp ghép các mô hình 3D, với cấu tạo mở, trực quan dễ hiểu, chất liệu thép giống với thực tế.'
    },
    {
        title: 'DRONE ROBOT ĐẠI HỌC',
        description: 'Các bạn sẽ học cách lập trình và điều khiển Drone thực hiện những nhiệm vụ thú vị như vượt chướng ngại vật, hay giao hàng mô phỏng,...'
    }
]

const roboticsContent: ContentProps[] = [
    {
        title: 'ROBOTICS AI TRUNG HỌC',
        description: 'Giúp các em hiểu được nguyên lý khoa học cơ khí, Lập trình từ cơ bản đến nâng cao thông qua việc học lắp ghép các mô hình 3D, với cấu tạo mở, trực quan dễ hiểu, chất liệu thép giống với thực tế.'
    },
    {
        title: 'ROBOTICS AI ĐẠI HỌC',
        description: 'Chương trình học Robotics được tích hợp thêm các ứng dụng AI như nhận diện khuôn mặt, nhận diện giọng nói, nhận diện hành động cử chỉ,…'
    }
]


const HomePageWeb = () => {
    const navigate = useNavigate();
    const {language, pageContent, windowSize, setIsHomePage} = useContext(LanguageContext) as LanguageType;
    const [news, setNews] = useState<News[]>([]);
    const [element, setElement] = useState(3);
    const videoPath = require('../../../../assets/video.mp4');
    const [isDrone, setIsDrone] = useState(false);
    const [productShown, setProductShown] = useState(0);
    const [videoShown, setVideoShown] = useState(0);
    const rootImgRef = useRef<HTMLDivElement>(null);
    const [img2Pos, setImg2Pos] = useState({x: 0, y: 0});

    useEffect(() => {
        setIsHomePage(true);
        if (windowSize.width < 700) {
            setElement(1);
        }else if (windowSize.width < 900) {
            setElement(2);
        }else {
            setElement(3);
        }
    }, [windowSize])

    useEffect(() => {
        let sections = document.querySelectorAll('section');
        const handleScroll = () => {
            sections.forEach(sec => {
                let top = window.scrollY;
                let offset = sec.offsetTop - 600;
                let height = sec.offsetHeight + 400;
                // console.log(`TOP: ${top} - OFFSET: ${offset} - HEIGHT: ${height}`);
                if (top >= offset && top < offset + height) {
                    sec.classList.add('show-animate');
                }else {
                    sec.classList.remove('show-animate')
                }

            })


        };

        // Attach the event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Detach the event listener when the component unmounts to avoid memory leaks
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/blog`).then(res => {
            console.log(res);
            setNews(res.data);
        }).catch(err => {
            console.log(err);
        })
    }, []);


    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        // You can access scroll-related properties from the event
        const target = event.currentTarget as HTMLDivElement;
        const { scrollTop, scrollHeight, clientHeight } = target;

        // Calculate the scroll percentage
        const scrollPercent = (scrollTop / (scrollHeight - clientHeight)) * 100;


        if (scrollPercent < 20) {
            setProductShown(0)
        }else if (scrollPercent < 40) {
            setProductShown(1)
        }else if (scrollPercent < 60) {
            setProductShown(2)
        }else if (scrollPercent < 80) {
            setProductShown(3)
        }else {
            setProductShown(4)
        }
    };

    const handleIntroductionScroll = (event: React.UIEvent<HTMLDivElement>) => {
        // You can access scroll-related properties from the event
        const target = event.currentTarget as HTMLDivElement;
        const { scrollTop, scrollHeight, clientHeight } = target;

        // Calculate the scroll percentage
        const scrollPercent = (scrollTop / (scrollHeight - clientHeight)) * 100;


        if (scrollPercent < 20) {
            setVideoShown(0)
        }else if (scrollPercent < 40) {
            setVideoShown(1)
        }else if (scrollPercent < 60) {
            setVideoShown(2)
        }else if (scrollPercent < 80) {
            setVideoShown(3)
        }else {
            setVideoShown(4)
        }
    }

    const scrollToView = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    }

    useEffect(() => {
        if (rootImgRef.current) {
            const post = rootImgRef.current.getBoundingClientRect();
            console.log(`L: ${post.top} - R: ${post.left}`);
            setImg2Pos({x: post.top, y: post.left})
            
        }
    }, [])

    useEffect(() => {

    }, [img2Pos])

    return <div className="w-full h-fit overflow-hidden">
        {/*Introduce banner*/}
        {/*TODO: Need to replace phone UI for title img*/}
        <div className="w-screen h-fit rounded-2xl relative ">
            <div className="bg-intro-color">
                <video src={videoPath} className={`w-full h-auto video-bright`} autoPlay loop muted>
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>

        {/*Teaching Level*/}
        <section id={'class-section'} className={`sec-1  w-full mt-0 bg-white py-2 relative`}>
            <img src={line_tech} className="absolute w-[900px] h-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-50"/>
            <div className="w-full h-full mt-0   rounded-3xl transition-colors duration-500">
                {/*Label Title*/}
                <div className={`w-fit mx-auto text-center text-3xl text-white font-meta_header`}>
                    <label>{pageContent.home.levelHeader}</label>
                </div>

                {/*Switch Button*/}
                <div className="w-fit mx-auto flex items-center justify-center gap-80 mb-3">
                    <div className="text-4xl text-black flex flex-col items-center justify-center font-meta_content">
                        <div>
                            <button>Robotics</button>
                        </div>
                        <div onClick={() => {
                            setIsDrone(false);
                        }} className="w-6 h-6 border-2 border-black flex items-center justify-center">
                            <div className={`transition-opacity duration-300 w-3 h-3 bg-black mx-auto ${isDrone ? 'opacity-0' : 'opacity-100'}`}>

                            </div>
                        </div>
                    </div>
                    <div className="text-4xl text-black flex flex-col items-center justify-center font-meta_content">
                        <div>
                            <button>Drone</button>
                        </div>
                        <div onClick={() => {
                            setIsDrone(true);
                        }} className="w-6 h-6 border-2 border-black flex items-center justify-center">
                            <div className={`transition-opacity duration-300 w-3 h-3 bg-black mx-auto ${!isDrone ? 'opacity-0' : 'opacity-100'}`}>

                            </div>
                        </div>
                    </div>

                </div>

                {/*Content*/}
                <div className="flex items-start justify-center ">
                    {/*IMG*/}
                    <div className="w-3/5 h-[470px]">
                        <div className="relative w-full mx-auto">
                            <div ref={rootImgRef} className=" absolute z-10 w-[380px] h-[350px] rounded-3xl ">
                                <img className="w-full h-full rounded-3xl" src={isDrone ? drone1 : robotics1} alt={'INTRO IMG'} />
                            </div>
                            <div style={{
                                position: 'absolute',
                                left: `${img2Pos.x + 250}px`,
                                top: `${img2Pos.y + 70}px`
                            }} className="  z-40 w-[290px] h-[190px] rounded-3xl">
                                <img className="w-full h-full rounded-3xl" src={isDrone ? drone2 : robotics2} alt={'INTRO IMG'} />
                            </div>
                            
                            <div style={{
                                position: 'absolute',
                                left: `${img2Pos.x + 50}px`,
                                top: `${img2Pos.y + 190}px`
                            }} className=" z-30 w-[300px] h-[290px] rounded-3xl">
                                <img className="w-full h-full rounded-3xl" src={isDrone ? drone3 : robotics3} alt={'INTRO IMG'} />
                            </div>
                        </div>
                    </div>

                    {/*Content*/}
                    <div className="w-3/5 pt-8 pb-16 pr-28">
                        <div className="w-full text-right flex flex-col items-end">
                            <div className="text-black text-3xl font-meta_content mb-10">
                                <label>{isDrone ? droneContent[0].title : roboticsContent[0].title}</label>
                            </div>
                            <div className="text-black text-xl w-4/5 font-meta_content">
                                <label>
                                    {isDrone ? droneContent[0].description : roboticsContent[0].description}
                                </label>
                            </div>

                            <button className="bg-white mt-28 px-6 py-2 hover:shadow-xl hover:scale-105 transition-all duration-500 border-2 border-[#5B9BD5]">
                                <div className="flex items-center justify-center gap-6 cursor-pointer text-lg">
                                    <div>
                                        <button onClick={() => {
                                            navigate('/contact');
                                            window.location.reload();
                                        }}>Tìm Hiểu Thêm</button>
                                    </div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section >

        {/*Products*/}
        <section id={'product-section'} className={`w-full h-[500px] sec-2 relative pb-20`}>
            <div className="absolute w-full h-full bg-[#DEEBF7] bg-opacity-50">

            </div>
            <div className="w-full absolute -z-20 top-0 sec-2-img-bg mb-10 ">
                <img className="h-[600px] w-full " src={tmpImg} alt={"Product_BG_IMG"} />
            </div>
            <div className="relative mx-auto w-fit font-meta_content text-3xl text-white ">
                <img className="absolute w-[300px] h-auto -z-10" src={proFrame}/>
                <div className="w-[300px]  text-center pt-5">
                    <label className="">{pageContent.home.productHeader.toUpperCase()}</label>
                </div>
            </div>
            <div onScroll={handleScroll} className="relative w-full h-[400px]   overflow-y-scroll overflow-x-hidden flex items-center justify-center  pl-40 ">
                <div className="h-fit flex flex-col items-end gap-3 pt-[3700px] ">
                    <div className="pro-animation text-left h-[900px] w-[700px] flex flex-col items-start justify-center text-5xl ">
                        <div className="text-2xl text-black w-4/5 font-meta_content font-bold">
                            <label>MEGATON - BỘ CÔNG CỤ ỨNG DỤNG AI</label>
                        </div>
                        <div className="break-words text-lg w-4/5 font-meta_content text-black mt-12">
                            <label>Megaton là một giải pháp công nghệ tích hợp trí tuệ nhân tạo (AI), giải pháp cung cấp một loạt các công cụ và tài liệu học tập thông minh để hỗ trợ việc học tập và nghiên cứu robot và ứng dụng thực tế.
                            </label>
                        </div>
                        <button onClick={() => {
                            navigate('/products/megaton');
                            window.location.reload();
                        }} className="text-white text-lg mt-12 px-6 py-2 hover:shadow-xl hover:scale-105 transition-all duration-500 border-2 border-white bg-[#5B9BD5]">Read more</button>
                    </div>
                    <div className="pro-animation text-left h-[900px] w-[700px] flex flex-col items-start justify-center text-5xl">
                        <div className="text-2xl text-black w-4/5 font-meta_content font-bold">
                            <label>LẬP TRÌNH DRONE (BRC-105)
                            </label>
                        </div>
                        <div className="break-words text-lg w-4/5 font-meta_content text-black mt-12">
                            <label>Phát triển tư duy thông qua giải pháp lập trình scratch và nhiều kỹ năng khác mở rộng môi trường thông qua các cuộc thi <br/> trong và ngoài nước.
                            </label>
                        </div>
                        <button onClick={() => {
                            navigate('/contact');
                            window.location.reload();
                        }} className="text-white text-lg mt-12 px-6 py-2 hover:shadow-xl hover:scale-105 transition-all duration-500 border-2 border-white bg-[#5B9BD5]">Read more</button>
                    </div>
                    <div className="pro-animation text-left h-[900px] w-[700px] flex flex-col items-start justify-center text-5xl">
                        <div className="text-2xl text-black w-4/5 font-meta_content font-bold">
                            <label>DRONE BÓNG ĐÁ (SKYKICK EVOLUTION)
                            </label>
                        </div>
                        <div className="break-words text-lg w-4/5 font-meta_content text-black mt-12">
                            <label>Học sinh được trải nghiệm những thử thách, mở rộng sân chơi đa quốc gia với bộ môn WORLD CUP Drone.
                            </label>
                        </div>
                        <button onClick={() => {
                            navigate('/contact');
                            window.location.reload();
                        }} className="text-white text-lg mt-12 px-6 py-2 hover:shadow-xl hover:scale-105 transition-all duration-500 border-2 border-white bg-[#5B9BD5]">Read more</button>
                    </div>
                    <div className="text-left h-[900px] w-[700px] flex flex-col items-start justify-center text-5xl">
                        <div className="text-2xl text-black w-4/5 font-meta_content font-bold">
                            <label>DRONE CHIẾN ĐẤU (BRB-95)
                            </label>
                        </div>
                        <div className="break-words text-lg w-4/5 font-meta_content text-black mt-12">
                            <label>Đặc biệt sản phẩm để các bạn nhỏ trải nghiệm nhiều hơn nữa những công nghệ tiên tiến.
                            </label>
                        </div>
                        <button onClick={() => {
                            navigate('/contact');
                            window.location.reload();
                        }} className="text-white text-lg mt-12 px-6 py-2 hover:shadow-xl hover:scale-105 transition-all duration-500 border-2 border-white bg-[#5B9BD5]">Read more</button>
                    </div>
                    <div className="text-left h-[900px] w-[700px] flex flex-col items-start justify-center text-5xl">
                        <div className="text-2xl text-black w-4/5 font-meta_content font-bold">
                            <label>ROBOTICS AI (NO3 /NO6)
                            </label>
                        </div>
                        <div className="break-words text-lg w-4/5 font-meta_content text-black mt-12">
                            <label>Tiếp cận công nghệ cao ứng dụng trí tuệ nhân tạo vào lập trình và phát triển Robot, tạo cơ hội hội nhập thông qua các cuộc thi Đa quốc gia.
                            </label>
                        </div>
                        <button onClick={() => {
                            navigate('/products/robotics-ai');
                            window.location.reload();
                        }} className="text-white text-lg mt-12 px-6 py-2 hover:shadow-xl hover:scale-105 transition-all duration-500 border-2 border-white bg-[#5B9BD5]">Read more</button>
                    </div>
                </div>
                <Products shownProduct={productShown}/>
            </div>
        </section>

        {/* Introduction Video*/}
        <section className="w-full mt-0 h-[500px] bg-white pt-4 relative ">
            <img src={line_tech} className="absolute w-[900px] h-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-50"/>
            <div className="w-fit mx-auto text-3xl text-[#5B9BD5] text-center font-meta_content font-bold mt-5">
                <label>Về chương trình<br/> và cuộc thi</label>
            </div>
            <div className="w-full h-[400px] bg-transparent overflow-y-scroll absolute top-20">
                <div className="w-full h-[400px] flex items-center justify-center mb-32">
                    <iframe
                        className={``}
                        width={500}
                        height={300}
                        src={`https://www.youtube.com/embed/${'puiIFIoQPSk'}`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <div className="w-1/2 flex flex-col items-end justify-end ">
                        <div className="text-xl text-black font-bold w-full font-meta_content text-right">
                            <label>GIỚI THIỆU HỌC VIỆN STEM & TRÍ TUỆ NHÂN TẠO</label>
                        </div>
                        <div className="break-words text-sm text-justify font-meta_content text-black mt-12 w-4/6">
                            <label>Học viện STEM và trí tuệ nhân tạo trực thuộc Trường Đại học Công nghệ TP.HCM (HUTECH) hợp tác với Meta Square,CK Group cùng ĐH Jeongnam State, ĐH Daegu đào tạo các khóa học đặc biệt được cập nhật mới nhất từ các giáo sư đầu nghành của Hàn Quốc nhằm mang lại năng lực công nghệ và kiến thức thực tiễn hiện đại cho sinh viên.
                            </label>
                        </div>
                        <div className="w-fit ">
                            <button onClick={() => {
                                navigate('/contact');
                                window.location.reload();
                            }} className="text-black bg-[#FBC600] text-sm mt-10 px-10 py-2 rounded-xl">Read more</button>
                        </div>
                    </div>
                </div>
                <div className="w-full h-[400px] flex items-center justify-center">
                    <iframe
                        className={``}
                        width={500}
                        height={300}
                        src={`https://www.youtube.com/embed/${'K5tsv2x0jUs'}`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <div className="w-1/2 flex flex-col items-end justify-end ">
                        <div className="text-xl text-[#FBC600] w-full font-meta_content text-right">
                            <label>CUỘC THI ROBOTICS CHAMPION</label>
                        </div>
                        <div className="break-words text-sm text-right font-meta_content text-black mt-12">
                            <label>Học sinh sẽ thể hiện sự sáng tạo và kỹ năng của mình, đồng thời là một cơ hội để tương tác và giao lưu với sinh viên Hàn Quốc và mở ra cơ hội du đấu quốc tế.
                            </label>
                        </div>
                        <div className="w-fit ">
                            <button onClick={() => {
                                navigate('/contact');
                                window.location.reload();
                            }} className="text-black bg-[#FBC600] text-sm mt-10 px-10 py-2 rounded-xl">Read more</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="w-1/2 h-[400px] absolute">
                    <iframe
                        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-opacity duration-300 ${(videoShown === 2) ? 'opacity-100' : 'opacity-0'}`}
                        width={500}
                        height={300}
                        src={`https://www.youtube.com/embed/${'aXHnH8p4sEc'}`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <iframe
                        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-opacity duration-300 ${(videoShown === 3) ? 'opacity-100' : 'opacity-0'}`}
                        width={500}
                        height={300}
                        src={`https://www.youtube.com/embed/${'qO2kR3KpsiQ'}`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <iframe
                        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-opacity duration-300 ${(videoShown === 4) ? 'opacity-100' : 'opacity-0'}`}
                        width={500}
                        height={300}
                        src={`https://www.youtube.com/embed/${'aXHnH8p4sEc'}`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
            </div> */}
            {/* <div onScroll={handleIntroductionScroll} className="relative  w-full mt-5 h-[380px] overflow-y-scroll flex items-center justify-center bg-transparent">
                <div className="ml-14" style={{
                    position: 'sticky',
                    top: '40%',
                    transform: 'translateY(-30%) translateX(20%)',
                    width: '50%',  // Adjust the width as needed
                    height: '500px',
                    transition: 'opacity 0.9s ease-in-out', // Add a transition effect
                    opacity: 1, // Initial opacity
                }}>
                    
                </div>

                <div className="h-[500px] flex flex-col gap-3 pt-[100px] items-end pr-32">

                    <div className="pro-animation text-right h-[300px] w-[600px] flex flex-col items-end justify-center text-5xl mb-40">
                        <div className="text-xl text-[#FBC600] w-full font-meta_header">
                            <label></label>
                        </div>
                        <div className="break-words text-sm font-meta_content text-white mt-12">
                            <label>Học sinh sẽ thể hiện sự sáng tạo và kỹ năng của mình, đồng thời là một cơ hội để tương tác và giao lưu với sinh viên Hàn Quốc và mở ra cơ hội du đấu quốc tế.</label>
                        </div>
                        <button onClick={() => {
                            navigate('/contact');
                            window.location.reload();
                        }} className="text-black bg-[#FBC600] text-sm mt-10 px-10 py-2 rounded-xl">Read more</button>
                    </div>
                    <div className=" pro-animation text-right h-[300px] w-[600px] flex flex-col items-end justify-center text-5xl mb-40">
                        <div className="text-xl text-[#FBC600] w-full font-meta_header">
                            <label>CUỘC THI DRONE RACING</label>
                        </div>
                        <div className="break-words text-sm font-meta_content text-white mt-12">
                            <label>Học sinh phải lập trình, điều khiển drone qua những đường đua phức tạp, yêu cầu người tham gia phải có kỹ năng và phản xạ nhanh nhạy.
                            </label>
                        </div>
                        <button onClick={() => {
                            navigate('/contact');
                            window.location.reload();
                        }} className="text-black bg-[#FBC600] text-sm mt-10 px-10 py-2 rounded-xl">Read more</button>
                    </div>
                    <div className="text-right h-[300px] w-[600px] flex flex-col items-end justify-center text-5xl mb-40">
                        <div className="text-xl text-[#FBC600] w-full font-meta_header">
                            <label>CUỘC THI SUMO ROBOTICS</label>
                        </div>
                        <div className="break-words text-sm font-meta_content text-white mt-12">
                            <label>Được tổ chức dưới dạng một giải đấu Sumo truyền thống, các đội phải thiết kế và lập trình robot của mình để chiến đấu trong một vòng tròn nhỏ. Mục tiêu đơn giản: đẩy đối thủ ra khỏi vòng tròn hoặc làm cho chúng mất cân bằng.
                            </label>
                        </div>
                        <button onClick={() => {
                            navigate('/contact');
                            window.location.reload();
                        }} className="text-black bg-[#FBC600] text-sm mt-10 px-10 py-2 rounded-xl">Read more</button>
                    </div>
                    <div className="text-right h-[300px] w-[600px] flex flex-col items-end justify-center text-5xl mb-40">
                        <div className="text-xl text-[#FBC600] w-full font-meta_header">
                            <label>CUỘC THI RACING ROBOTICS</label>
                        </div>
                        <div className="break-words text-sm font-meta_content text-white mt-12">
                            <label>Sinh viên sẽ có cơ hội thực hành và trải nghiệm quá trình phát triển và lập trình các tích hợp AI vào xe đua, từ đó mở rộng hiểu biết và có trải nghiệm thực tế về công nghệ ngày nay.
                            </label>
                        </div>
                        <button onClick={() => {
                            navigate('/contact');
                            window.location.reload();
                        }} className="text-black bg-[#FBC600] text-sm mt-10 px-10 py-2 rounded-xl">Read more</button>
                    </div>
                </div>
            </div> */}
        </section>

        {/*Certificate*/}
        <section className="relative w-full h-fit pb-32">
        <div className="absolute w-full h-full bg-[#DEEBF7] bg-opacity-50 -z-10">
        </div>
            <div className="w-full absolute -z-20 top-0 brightness-50">
                <img className="w-full " src={certificate_bg} alt={"Product_BG_IMG"} />
            </div>
            <div className="relative mx-auto w-fit font-meta_content text-3xl text-white mb-10">
                <img className="absolute w-[400px] h-auto -z-10" src={proFrame}/>
                <div className="w-[400px] font-meta_content text-3xl text-center pt-5">
                    <label className="">KỸ NĂNG <br/>VÀ CHỨNG CHỈ</label>
                </div>
            </div>
            {/* <div className="w-fit mx-auto text-xl text-white border-2 border-gray-100 px-10 py-5 text-center">
                <label>KỸ NĂNG <br/>VÀ CHỨNG CHỈ</label>
            </div> */}
            <div className="w-full h-[300px] flex flex-col items-center justify-start ">
                <div className="w-fit h-full flex items-center justify-center gap-10 ">
                    {/*Skills Container*/}
                    <div className="w-[450px] flex items-center justify-center flex-wrap gap-16 pt-20 ">
                        {skills.map((skill, index) => <div className="w-[130px] h-[130px] p-3 border-2 border-gray-100 rounded-3xl">
                            <img src={skill} alt={"Skill"} />
                        </div>)}
                    </div>

                    {/*Certificates Container*/}
                    <div className="w-[550px]  h-[400px] flex items-center justify-center flex-wrap gap-24 pt-10 ">
                        {certificates.map((skill, index) => <div className="w-[180px] h-[80px]">
                            <img src={skill} alt={"Skill"} />
                        </div>)}
                    </div>
                </div>
            </div>
        </section>

        {/*Partners*/}
        <section className="relative w-full mt-0 bg-white">
            <div className="h-fit text-5xl text-white  font-meta_header w-[300px] mx-auto partner-title ">
                <div className="absolute inset-0 flex justify-center items-center">
                    <img src={partnerFrame} className="absolute top-0 partner-title z-10" />
                    <label className="absolute z-40 font-meta_content text-3xl">Đối tác</label>
                </div>
            </div>
            <MetaSlider className="pt-5 " gap={10} left={10} autoplay={true} playTime={1000}>
                {partnersImg.map((partner, index) => <img key={index} src={partner} className="w-[160px]"/>)}
            </MetaSlider>
            <div className="w-[900px] h-[10px] absolute -bottom-5 z-40 flex items-center justify-center partner-bg left-1/4 transform -translate-x-1/4">
                <div className="w-0 h-0
                  border-t-[0px] border-t-transparent
                  border-r-[55px] border-r-white
                  border-b-[50px] border-b-transparent">
                </div>
                <div className="w-[900px] h-[50px] bg-white ">

                </div>
                <div className="w-0 h-0
                  border-t-[0px] border-t-transparent
                  border-l-[55px] border-l-white
                  border-b-[50px] border-b-transparent">
                </div>
            </div>
        </section>

        {/*News*/}
        <section className="w-full mt-0 h-[700px] bg-[#162236] pt-0 relative">
            <div className="absolute w-full h-full bg-[#DEEBF7] bg-opacity-70 z-10">

            </div>
            <div className="absolute h-full w-full z-0">
                <img src={newsBg} className=" w-full h-[700px]"/>
            </div>
            <div className="absolute w-[300px] left-1/2 z-40 transform -translate-x-1/2 top-10 mx-auto text-5xl text-white  font-meta_content news-title">
                <div className="w-full h-full  relative">
                    <img src={partnerFrame} className="absolute w-[500px] h-auto -z-20"/>
                    <div className="z-30 text-3xl w-full text-center pt-5">
                        <label>Tin Tức</label>
                    </div>
                </div>
            </div>
            <div className="absolute top-40 z-40">
            
                <div className="h-2/3 flex flex-col items-center justify-center">
                    <MetaSlider left={10} gap={10} >
                        {news.map((blog, index) => <NewsBox newsId={blog.blod_id} title={blog.title} img={blog.img} description={blog.summary}/>)}
                    </MetaSlider>
                </div>
            </div>
            
        </section>
    </div>
}

export default HomePageWeb;
