import React from "react";
import TitleSection from "./TitleSection";
import IntroductionSection from "./IntroductionSection";
import VideoIntroduction from "./VideoIntroduction";
import ToolIntroduction from "./ToolIntroduction";
import ActivitySection from "./ActivitySection";

const ScienceBoxPage = () => {
    return <div>
        <TitleSection/>
        <IntroductionSection/>
        <VideoIntroduction/>
        <ToolIntroduction/>
        <ActivitySection/>
    </div>
}

export default ScienceBoxPage;
