
import React, {createContext, Dispatch, ReactNode, SetStateAction, useContext, useState} from "react";

type AppContextProps = {
    selectedProduct: number;
    setSelectedProduct: Dispatch<SetStateAction<number>>
}

interface ModeProviderProps {
    children: ReactNode;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

function AppContextPageProvider(props: ModeProviderProps) {
    const [selectedProduct, setSelectedProduct] = useState(1);

    const value: AppContextProps = {
        selectedProduct: selectedProduct,
        setSelectedProduct: setSelectedProduct
    }

    return <AppContext.Provider {...props} value={value} ></AppContext.Provider>
}

const useAppContext = () => {
    const context = useContext(AppContext);
    if (typeof context === "undefined") {
        throw new Error("useMode must be used within AuthProvider");
    }
    return context;
}

export {AppContextPageProvider, useAppContext}
