import React, {useState} from "react";
import banner from "../assests/Slide6.png";
import {useNavigate} from "react-router-dom";

const Banner = () => {
    const navigate = useNavigate();

    return <section className="w-full h-screen relative">
        <div className="snap-center min-w-full w-full h-full relative rounded-b-2xl">
            <img src={banner} alt={"Banner"} id="image1" className="w-full h-full absolute top-0  brightness-75 rounded-b-2xl"/>
            <div className="rounded-b-2xl absolute bottom-32 pt-10 z-30">
                <div className="text-4xl text-white w-fit mx-auto font-meta_header text-center">
                    <label>KHÓA HỌC
                    </label>
                </div>
                <div className="text-[#00B0F0] font-meta_content w-fit mx-auto mt-8">
                    <label>ROBOTICS AI</label>
                </div>
                <div className="w-2/3 text-center mx-auto mt-5 font-meta_content text-white">
                    <label>Ứng dụng Trí Tuệ Nhân Tạo vào
                        lập trình Phát triển Robot

                    </label>
                </div>
                <div className="w-fit mx-auto mt-10">
                    <button onClick={() => {
                        navigate('/contact');
                        window.location.reload();
                    }} className="font-meta_content text-white text-xl bg-[#00B0F0] px-5 py-1.5 rounded-2xl">Liên hệ
                    </button>
                </div>
            </div>
        </div>
    </section>
}

export default Banner;
