import React, {useState} from "react";
import {MetaSlider} from "../../../Slider";
import megatonBox from '../assests/megaton_board.png';
import megatonLCD from '../assests/megaton_component/2.png';
import megatonBattery from '../assests/megaton_component/1.png'

const items = [
    "Mạch Megaton",
    "Màn Hình LCD",
    "Khay Pin"
]

const InsideBox = () => {

    const [imgShown, setImgShown] = useState(0);

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const container = event.currentTarget;
        const containerRect = container.getBoundingClientRect();
        const containerCenter = containerRect.left + containerRect.width / 2;
        const images = container.querySelectorAll('img');

        images.forEach(img => {
            const imgRect = img.getBoundingClientRect();
            const imgCenter = imgRect.left + imgRect.width / 2;

            // Check if the image center is within the container
            if (imgCenter >= containerRect.left && imgCenter <= containerRect.right) {
                if (img.id === 'image1') {
                    console.log("SEt 1")
                    setImgShown(0);
                }else if (img.id === 'image2') {
                    console.log("SEt 2")
                    setImgShown(1);
                }else {
                    console.log("SEt 3")
                    setImgShown(2);
                }
            }
        });
    };


    return <section className="w-full h-fit mt-10 mb-10">
        <div className="w-fit text-3xl mx-auto font-meta_header text-center text-[#FF0000]">
            <label>TRONG MEGATON<br/>
                CÓ GÌ?
            </label>
        </div>
        <div className="w-fit mt-5 mx-auto text-sm  font-meta_content text-[#FF0000]">
            <label>{items[imgShown].toUpperCase()}</label>
        </div>
        <MetaSlider handleScroll={handleScroll}>
            <div className="min-w-full w-full h-full  ">
                <img id={"image1"} src={megatonBox} alt={"Box"} className="w-3/5 mx-auto my-3 h-auto"/>
            </div>
            <div className="min-w-full w-full h-full ">
                <img id={"image2"} src={megatonLCD} alt={"Box"} className="w-3/5 mx-auto my-3 h-auto"/>
            </div>
            <div className="min-w-full w-full h-full ">
                <img id={"image3"} src={megatonBattery} alt={"Box"} className="w-3/5 mx-auto my-3 h-auto"/>
            </div>
        </MetaSlider>
        
    </section>
}

export default InsideBox;
