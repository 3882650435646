import React from "react";
import {MetaSlider} from "../../../Slider";
import activity1 from '../assests/activity/hd1.jpg'
import activity2 from '../assests/activity/hd2.jpg'
import activity3 from '../assests/activity/hd3.jpg'
import activity4 from '../assests/activity/hd4.jpg'
import activity5 from '../assests/activity/hd5.jpg'
import activity6 from '../assests/activity/hd6.jpg'
import activity7 from '../assests/activity/hd7.jpg'
import activity8 from '../assests/activity/hd8.jpg'
import activity9 from '../assests/activity/hd9.jpg'
import activity10 from '../assests/activity/hd10.jpg'

const activities = [
    activity1,
    activity2,
    activity3,
    activity4,
    activity5,
    activity6,
    activity7,
    activity8,
    activity9,
    activity10
]

const Activity = () => {
    return <section className="w-full h-fit mt-14 mb-10">
        <div className="w-fit mx-auto text-3xl text-[#FF0000] font-meta_header">
            <label>HOẠT ĐỘNG</label>
        </div>
        <MetaSlider className="mt-12" gap={10} left={5}>
            {activities.map((activity, index) => <img key={index} className="w-[400px] h-auto rounded-xl" src={activity} alt={"ACT"}/>)}
        </MetaSlider>
    </section>
}

export default Activity;
