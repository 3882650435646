import React, {useEffect, useState} from "react";
import './style.css'
import axios from "axios";
import {removePunctuation} from "../../pages/utils";
import {useNavigate} from "react-router-dom";

type NewsBoxProps = {
    newsId: string,
    title: string,
    img: string,
    description: string
}

const NewsBox = ({newsId , title, img, description}: NewsBoxProps) => {
    const [onNewsHover, setOnNewsHover] = useState(false);
    const [titleImg, setTitleImg] = useState('');
    const navigate = useNavigate();

    useEffect( () => {
        try {
            axios.get(`https://test-client-stem.s3.amazonaws.com/blog-${newsId}.txt`).then(res => {
                setTitleImg(res.data);
            }).catch(err => {
                console.log(err);
            })

        } catch (error) {
            console.error('Error fetching file content:', error);
        }
    }, []);

    return <div
        onClick={() => {
            navigate(`/news/${removePunctuation(title)}`);
            window.location.reload();
        }}
        onMouseLeave={() => {setOnNewsHover(false)}}
        onMouseEnter={() => {setOnNewsHover(true)}}
        className="relative min-w-[500px] w-[500px] rounded-2xl shadow-xl">
        {/*News Img*/}
        <div className="rounded-2xl">
            <img className="w-[500px] rounded-2xl" src={titleImg}/>
        </div>
        {/*News Content Box*/}
        <div className="absolute bottom-0 w-full bg-white  bg-opacity-70 pl-5 pt-3 w-full rounded-b-2xl">
            {/*Title*/}
            <div className="font-meta_header ">
                <label>{title}</label>
            </div>
            {/*Description*/}
            <div className={`font-meta_content pb-5 news-content-box ${onNewsHover ? 'expandable' : ''} w-full break-words rounded-b-2xl`}>
                <label className="w-full ">{description}</label>
            </div>
        </div>
    </div>
}

export default NewsBox;
