import React from "react";
import Banner from "./Banner";
import MegatonIntroduction from "./MegatonIntroduction";
import CodingToolIntroduction from "./CodingToolIntroduction";
import InsideBox from "./InsideBox";
const MegatonPagePhone = () => {
    return <div>
        <Banner/>
        <MegatonIntroduction/>
        <CodingToolIntroduction/>
        <InsideBox/>
    </div>
}

export default MegatonPagePhone;
