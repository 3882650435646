import React from "react";
import {MetaSlider} from "../../../Slider";
import drone1 from '../../Drone/assests/byrobot_drone_8.png';
import drone2 from '../../Drone/assests/byrobot_drone_3_10.png';
import drone3 from '../../Drone/assests/drone_football.png';
import megatonBox from '../../Megaton/assests/megaton_box.png';
import sb4 from '../../ScienceBox/assests/SB4.png';
import sb3 from '../../ScienceBox/assests/SB3.png';

const Products = () => {
    return <section className="w-full h-fit relative ">
        <div className="w-fit mx-auto mt-5 font-meta_header text-4xl">
            <label>SẢN PHẨM</label>
        </div>

        <MetaSlider className="mt-3" gap={10} bottom={3}>
            <div className="min-w-[500px] w-[500px] min-h-[420px] relative">
                <div className="w-full h-[250px] relative ">
                    <img src={drone1} className="w-[180px] absolute left-72 top-16"/>
                    <img src={drone2} className="w-[200px] absolute -top-10 left-20"/>
                    <img src={drone3} className="w-[150px] absolute top-32 left-20"/>
                </div>
                <div className="w-fit mx-auto font-meta_header text-[#FFC000] text-xl mt-5">
                    <label>DRONE ROBOT</label>
                </div>
                <div className="w-4/5 mx-auto text-center mt-5 font-meta_content">
                    <label>Học cách lập trình và điều khiển Drone thực hiện những nhiệm vụ thú vị như vượt chướng ngại vật, hay giao hàng mô phỏng,…</label>
                </div>
            </div>
            <div className="min-w-[500px] w-[500px] min-h-[420px] relative">
                <div className="w-full h-fit relative ">
                    <img src={megatonBox} alt="Megaton box" className="w-auto h-[250px] mx-auto"/>
                </div>
                <div className="w-fit mx-auto font-meta_header text-[#FFC000] text-xl mt-5">
                    <label>MEGATON</label>
                </div>
                <div className="w-2/3 mx-auto text-center mt-5 font-meta_content">
                    <label>Nguồn "tài nguyên" quý giá cho  giải pháp STEM tích hợp đa dạng Trí Tuệ Nhân Tạo
                        </label>
                </div>
            </div>
            <div className="min-w-[500px] w-[500px] min-h-[420px] relative">
                <div className="w-full h-fit relative ">
                    <img src={sb4} alt="Megaton box" className="w-auto h-[250px] mx-auto"/>
                </div>
                <div className="w-fit mx-auto font-meta_header text-[#FFC000] text-xl mt-5">
                    <label>SCIENCE BOX NO3</label>
                </div>
                <div className="w-2/3 mx-auto text-center mt-5 font-meta_content">
                    <label>Bộ công cụ Sciencebox giúp trẻ em phát triển vượt trội về khả năng tư duy logic và lập trình
                        </label>
                </div>
            </div>
            <div className="min-w-[500px] w-[500px] min-h-[420px] relative">
                <div className="w-full h-fit relative ">
                    <img src={sb3} alt="Megaton box" className="w-auto h-[250px] mx-auto"/>
                </div>
                <div className="w-fit mx-auto font-meta_header text-[#FFC000] text-xl mt-5">
                    <label>SCIENCE BOX NO6</label>
                </div>
                <div className="w-2/3 mx-auto text-center mt-5 font-meta_content">
                    <label>Kết hợp với kiến thức khoa học ứng dụng thông qua bộ môn lắp ráp kĩ thuật cơ khí và lập trình.
                        </label>
                </div>
            </div>

        </MetaSlider>
    </section>
}

export default Products;
