import React, {useContext} from "react";
import {DronePage, DronePagePhone} from "../components/pages/Drone";
import {LanguageContext} from "../App";
import {LanguageType} from "./utils";

const Drone = () => {
    const {windowSize} = useContext(LanguageContext) as LanguageType;

    return <div>
        {windowSize.width < 700 ? <DronePagePhone/> : <DronePage/>}
    </div>
}

export default Drone;
