import React, {useContext} from "react";
import {Link, useNavigate} from "react-router-dom";
import {LanguageContext} from "../App";
import {LanguageType} from "../pages/utils";

const Footer = () => {
    const navigate = useNavigate();
    const {language, pageContent} = useContext(LanguageContext) as LanguageType;

    // @ts-ignore
    return <footer className="bg-[#162236] text-white pt-8 pb-16 px-12 tracking-wide">
        <div className="flex flex-col gap-8 md:flex-row md:gap-12 md:items-start">
            {/*About */}
            <div className="w-full md:w-2/5">
                <div className="text-2xl text-[#FBC000] font-sans mb-3 font-bold font-meta_header">
                    <label>{pageContent.footerContent.about.title}</label>
                </div>
                <div>
                    <label className="break-words font-meta_content">
                        {pageContent.footerContent.about.content}
                    </label>
                </div>
            </div>

            <div className="w-full md:w-1/3 ">
                <div className="text-2xl font-sans mb-3 text-[#FBC000] font-bold font-meta_header">
                    <label>{pageContent.footerContent.contact.title}</label>
                </div>
                <div className="flex flex-col items-start justify-start font-meta_content gap-4">
                    <div className="flex gap-3 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                        </svg>
                        <div>
                            <label>{pageContent.footerContent.contact.hotline}</label>
                        </div>
                    </div>
                    <div className="flex gap-3 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                        </svg>

                        <div>
                            <label>{pageContent.footerContent.contact.email}</label>
                        </div>
                    </div>
                    <div className="flex gap-3 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                        </svg>
                        <div>
                            <label>{pageContent.footerContent.contact.office}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/3">
                <div className="text-2xl font-sans mb-3 text-[#FBC000] font-bold font-meta_header">
                    <label>{pageContent.footerContent.customerSupport.title}</label>
                </div>
                <div>
                    <ul className="flex flex-col gap-1.5 font-meta_content">
                        <li><button onClick={() => {
                            navigate('/contact');
                            window.location.reload();
                        }}>{pageContent.footerContent.customerSupport.contact}</button></li>
                        <li><button onClick={() => {
                            navigate('/contact');
                            window.location.reload();
                        }}>{pageContent.footerContent.customerSupport.news}</button></li>
                        <li><button onClick={() => {
                            navigate('/hiring');
                            window.location.reload();
                        }}>{pageContent.footerContent.customerSupport.hiring}</button></li>
                        <li><button onClick={() => {
                            navigate('/about-us');
                            window.location.reload();
                        }}>{pageContent.footerContent.customerSupport.introduction}</button></li>
                        {/*<li><Link to={'/contact'}>Tin tức </Link></li>*/}
                        {/*<li><Link to={'/hiring'}>Tuyển dụng </Link></li>*/}
                        {/*<li><Link to={'/about-us'}>Giới thiệu </Link></li>*/}
                    </ul>
                </div>
            </div>
            <div className="w-full md:w-1/3">
                <div className="text-2xl text-[#FBC000] font-sans mb-3 font-bold font-meta_header">
                    <label>Fanpage</label>
                </div>

                <div>
                    <iframe
                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FMetaSquareEducation%3Fmibextid%3DLQQJ4d&tabs=timeline&width=340&height=331&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1749174868812273"
                        width="340" height="331" style={{border:'none',overflow:'hidden'}} scrolling="no" frameBorder="0"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>
            </div>
        </div>
    </footer>
}

export default Footer;
