import React from "react";
import megatonBoard from '../assests/megaton_board.png';
import megatonLogo from '../assests/LOGO.png'
import {useNavigate} from "react-router-dom";

const MegatonIntroduction = () => {
    const navigate = useNavigate();

    return <section  className="w-full h-fit mb-5">
        <div className="w-full h-fit pb-10 px-4 mb-1">
            <div className="w-full h-full ">
                <div className="w-fit mx-auto mt-10 mb-10 text-3xl font-meta_header text-[#FF0000]">
                    <label>VỀ MEGATON</label>
                </div>
                <div className="w-full h-fit  rounded-3xl relative">
                    <img src={megatonBoard} alt={"Box"} className="w-[240px] h-auto mx-auto"/>
                </div>
                <div className="w-full mt-10">
                    <div className="mx-10 text-center font-meta_content text-sm mt-5 px-2">
                        <label>Megaton là bộ học cụ AI được tích hợp thêm các ứng dụng như nhận diện khuôn mặt, nhận diện giọng nói, nhận diện hành động cử chỉ,… vào Robot giúp các em giải quyết
                            các vấn đề và nhiệm vụ một cách công nghệ
                        </label>
                    </div>
                    <div className="mt-6 w-fit mx-auto font-meta_content text-sm text-[#FF0000] text-center">
                        <label>Phù hợp với mọi độ tuổi từ Trung học đến bậc Đại Học</label>
                    </div>
                </div>
                <div className="w-fit mx-auto mt-10">
                    <button onClick={() => {
                        navigate('/contact');
                        window.location.reload();
                    }} className="font-meta_content text-white text-xs bg-[#FF0000] px-6 py-1.5 rounded-2xl">MUA
                    </button>
                </div>
            </div>
        </div>
        <div className="w-full h-fit relative rounded-b-3xl">
            <div className="w-fit text-center mx-auto font-meta_header text-3xl text-[#FF0000]">
                <label>TÍCH HỢP ĐA DẠNG<br/>
                    TRÍ TUỆ NHÂN TẠO
                </label>
            </div>
            <img src={megatonBoard} alt={"Board"} className="w-4/5 h-auto mx-auto mt-5" />
            <div className="w-full h-2/5 bg-red-600 absolute bottom-0 rounded-b-3xl flex items-center flex-col justify-center">
                <img src={megatonLogo} alt="Logo" className="w-[90px] h-auto"/>
            </div>
        </div>
    </section>
}

export default MegatonIntroduction;
