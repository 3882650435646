import React from "react";
import megatonColorLogo from "../assests/sb_logo.png";
import sb3 from '../assests/SB3.png'
import sb4 from '../assests/SB4.png'

const IntroductionSection = () => {
    return <section className={`w-full h-fit mt-0 sec-2 relative pt-5 bg-white pb-8`}>
        <div className="absolute top-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="w-[80px] h-auto mx-auto brightness-200 ">
                <img className="w-full h-full" src={megatonColorLogo} alt={"Color Logo"}/>
            </div>
        </div>
        <div className="flex items-center justify-center gap-16 mt-0">
            {/*IMG Container*/}
            <div className="relative w-1/3 h-fit ">
                <div className="w-[350px] h-[350px] mx-auto ">
                    <img className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[600px] h-auto" src={sb3} alt="Megaton Box"/>
                </div>
                <div className="w-full text-center text-[#7F7F7F] text-2xl font-bold font-meta_header ">
                    <label>Science Box NO6</label>
                </div>
                <div className="w-full flex items-center font-meta_content justify-center mt-8">
                    <button className="bg-red-600 px-7 py-2 text-lg rounded-3xl mx-auto text-white">Mua</button>
                </div>

            </div>

            <div className="relative w-1/3 h-fit ">
                <div className="w-[350px] h-[350px] mx-auto ">
                    <img className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[400px] h-auto" src={sb4} alt="Megaton Box"/>
                </div>
                <div className="w-full text-center text-2xl text-[#7F7F7F] font-bold font-meta_header ">
                    <label>Science Box NO3</label>
                </div>
                <div className="w-full flex items-center font-meta_content justify-center mt-8">
                    <button className="bg-red-600 px-7 py-2 text-lg rounded-3xl mx-auto text-white">Mua</button>
                </div>

            </div>
        </div>
    </section>
}

export default IntroductionSection;
