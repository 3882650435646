import React from "react";
import titleImg from '../assets/title_img.png'
import primaryImg from '../assets/meta_square_primary.png'
import secondaryImg from '../assets/meta_square_secondary.png'
import highImg from '../assets/meta_square_high.png'
import skills from '../assets/skills.png'
import skills2 from '../assets/skills2.png'
import skills3 from '../assets/skills3.png'
import primary_title from '../assets/primary_title.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {useLocation} from "react-router-dom";



const AboutUsPage = () => {
    const location = useLocation();

    return <div className=" mt-14 font-custom1 tracking-wide">
        <div className=" relative w-full h-3/5 mx-auto rounded-2xl flex flex-col justify-center items-center">
            <div className="w-full h-full">
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, Autoplay]}
                    autoplay={true}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation={{
                        navigationDisabledClass: "swiper-navigation-enabled"
                    }}
                    onSwiper={(swiper) => console.log(swiper)}
                >
                    <SwiperSlide  className=" w-full h-[300px]">
                        <img src={titleImg}/>
                    </SwiperSlide>
                    <SwiperSlide  className=" w-full h-full">
                        <img src={primary_title}/>
                    </SwiperSlide>
                    {/*<SwiperSlide  className=" w-full h-full">*/}
                    {/*    <img src={secondaryImg}/>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide  className=" w-full h-full">*/}
                    {/*    <img src={highImg}/>*/}
                    {/*</SwiperSlide>*/}
                </Swiper>
            </div>

            {/*Section 1*/}
            <div className="w-full mt-8" id={"section1"}>
                <div className="w-full mx-auto flex gap-14 py-5 px-10 rounded-3xl">
                    <div className="w-full rounded-2xl">
                        <img className="rounded-2xl" src={primaryImg}/>
                    </div>

                    <div className="ml-8 flex flex-col items-start justify-start gap-10">
                        <div className="font-bold text-4xl" >
                            <label>Chương trình Tiểu học</label>
                        </div>
                        <div className="text-xl ">
                            <label>Trẻ ở độ tuổi tiểu học sẽ được tiếp cận các khóa học Robotics & Drone với nền tảng cơ bản để các em dần phát triển tư duy công nghệ cùng nhóm kỹ năng (lập trình, thuyết trình, tư duy logic,…) cần thiết trong kỹ nguyên mới.</label>
                        </div>
                        <div className="bg-blue-200 px-8 py-2 rounded-2xl w-fit">
                            <button>Liên hệ</button>
                        </div>
                    </div>
                </div>
            </div>

            {/*Section 2*/}
            <div className="w-full mt-8" id={"section2"}>
                <div className="w-full mx-auto flex gap-14 py-5 px-10 rounded-3xl">


                    <div className="ml-8 flex flex-col items-start justify-start gap-10">
                        <div className="font-bold text-4xl" >
                            <label>Chương trình Trung học cơ sở</label>
                        </div>
                        <div className="text-xl ">
                            <label>
                            </label>
                        </div>
                        <div className="bg-blue-200 px-8 py-2 rounded-2xl w-fit">
                            <button>Liên hệ</button>
                        </div>
                    </div>
                    <div className="w-full rounded-2xl">
                        <img className="rounded-2xl" src={secondaryImg}/>
                    </div>
                </div>
            </div>

            {/*Section 3*/}
            <div className="w-full mt-8" id={"section3"}>
                <div className="w-full mx-auto flex gap-14 py-5 px-10 rounded-3xl">
                    <div className="w-full rounded-2xl">
                        <img className="rounded-2xl" src={highImg}/>
                    </div>

                    <div className="ml-8 flex flex-col items-start justify-start gap-10">
                        <div className="font-bold text-4xl" >
                            <label>Chương trình Trung học phổ thông</label>
                        </div>
                        <div className="text-xl ">
                            <label>Mở ra cơ hội du học chưa bao giờ đơn giản hơn qua giải pháp học mà chơi. Học tập và tiếp cận công nghệ cao ứng dụng trí tuệ nhân tạo vào lập trình và phát triển Robot, tạo cơ hội hội nhập thông qua các cuộc thi Đa quốc gia.</label>
                        </div>
                        <div className="bg-blue-200 px-8 py-2 rounded-2xl w-fit">
                            <button>Liên hệ</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rounded-2xl mt-16 mx-4">
                <img className="rounded-2xl" src={skills}/>
            </div>
            <div className="rounded-2xl mt-16 mx-4">
                <img className="rounded-2xl" src={skills2}/>
            </div>
            <div className="rounded-2xl mt-16 mb-20 mx-4">
                <img className="rounded-2xl" src={skills3}/>
            </div>
        </div>
    </div>

}

export default AboutUsPage;
