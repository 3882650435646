import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";

interface News {
    blod_id: string,
    title: string,
    summary: string,
    img: string,
    content: string,
    date: string

}

interface NewsContainerProps {
    news: News;
}

const NewsContainer: React.FC<NewsContainerProps> = ({news}) => {

    const [titleImg, setTitleImg] = useState('');

    useEffect( () => {
        try {
            axios.get(`https://test-client-stem.s3.amazonaws.com/blog-${news.blod_id}.txt`).then(res => {
                console.log(res.data);
                setTitleImg(res.data);
            }).catch(err => {
                console.log(err);
            })

        } catch (error) {
            console.error('Error fetching file content:', error);
        }
    }, []);

    return <div className="flex flex-col md:flex-row items-start gap-12 w-full h-fit md:h-64 bg-white rounded-2xl py-2 px-3 h-fit ">
        <div className="w-4/5 mx-auto md:mx-0 md:w-1/5 h-5/6 rounded-2xl mt-6">
            <img className="w-full h-full rounded-2xl" src={titleImg} />
        </div>
        <div className="flex flex-col gap-4 md:justify-between w-full h-full py-4">
            <div>
                <div className="text-2xl">
                    <label>{news.title}</label>
                </div>
                <div className="text-lg text-gray-300">
                    <label>{news.summary}</label>
                </div>
                <div className="text-lg text-gray-300">
                    <label>Ngày đăng {news.date}</label>
                </div>
            </div>
            <div className="flex justify-between items-center">
                <Link to={'/news-upload'} state={{news: news, update: "1"}} className="px-4 py-2 bg-orange-200 rounded-2xl hover:bg-gray-50 active:bg-orange-200">Chỉnh sửa</Link>
                <button onClick={() => {
                    axios.delete(`${process.env.REACT_APP_API_URL}api/blog/delete?blog_id=${news.blod_id}`).then(res => {
                        console.log(res.data);
                        window.location.reload();
                    }).catch(err => {
                        console.log(err);
                        alert("Chưa thể xoá blog");
                    })
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
}

const AdminNewsPage = () => {
    const [news, setNews] = useState<News[]>([]);
    const navigator = useNavigate();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/blog`).then(res => {
            console.log(res);
            setNews(res.data);
        }).catch(err => {
            console.log(err);
        })
    }, [])

    return <div className="w-screen mt-20 px-4 font-custom1 tracking-wide">
        <div className="w-full mb-5">
            <Link to={'/news-upload'} state={{update: "0"}} className="w-fit px-10 py-2 bg-blue-200 mb-10 rounded-2xl">
                Tạo Blog
            </Link>
        </div>
        <div className={"flex flex-col gap-8"}>
            {news.map((content, index) => <NewsContainer news={content}/>)}
        </div>
    </div>
}

export default AdminNewsPage;
