import React, {useContext} from "react";
import {LanguageContext} from "../App";
import {LanguageType} from "./utils";
import {ScienceBoxPage, ScienceBoxPhone} from "../components/pages/ScienceBox";

const ScienceBox = () => {
    const {windowSize} = useContext(LanguageContext) as LanguageType;

    return <div>
        {windowSize.width < 700 ? <ScienceBoxPhone/> : <ScienceBoxPage/>}
    </div>
}

export default ScienceBox;
