import React from "react";
import megatonColorLogo from "../../ScienceBox/assests/sb_logo.png";
import {MetaSlider} from "../../../Slider";
import act1 from '../assests/activity/z5116168957362_41139bc03d90586874d8292fe58d77d7.jpg';
import act2 from '../assests/activity/412425462_667879058881251_422874645598483499_n.jpg';
import act3 from '../assests/activity/647394-nam00536jpg.jpg';
import act4 from '../assests/activity/z5116168957362_41139bc03d90586874d8292fe58d77d7.jpg';
import act5 from '../assests/activity/647394-nam00536jpg.jpg';
import '../style.css'

const activities = [
    act1,
    act2,
    act3,
    act4,
    act5
]

const Activity = () => {
    return <section className="w-full h-fit mt-0 sec-2 relative bg-[#F5F5F7] pb-20">
        <div className="flex items-center justify-start rotate-180 ">
            <div className="w-[400px] flex items-center justify-center partner-bg  styling-div-activity ml-10">
                <div className="w-0 h-0
                  border-t-[0px] border-t-transparent
                  border-r-[55px] border-r-[#F5F5F7]
                  border-b-[50px] border-b-transparent">
                </div>
                <div className="w-[900px] h-[50px] bg-[#F5F5F7] ">

                </div>
                <div className="w-0 h-0
                  border-t-[0px] border-t-transparent
                  border-l-[55px] border-l-[#F5F5F7]
                  border-b-[50px] border-b-transparent">
                </div>
            </div>
        </div>
        <div className="w-full">
            <div className="w-fit text-5xl mx-auto font-meta_header font-bold mt-5">
                <label>HOẠT ĐỘNG</label>
            </div>
            <MetaSlider className="mt-12" left={10} gap={10}>
                {activities.map((activity, index) => <img key={index} className="w-[400px] h-auto rounded-3xl" src={activity} alt={"ACT"}/>)}
            </MetaSlider>
        </div>
    </section>
}

export default Activity;
