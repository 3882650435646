import React from "react";
import titleBg from '../assests/title_bg.jpg';
import megatonBox from '../assests/megaton_box.png'
import megatonColorLogo from '../assests/LOGO.png';

const TitleSection = () => {
    return <section className={`w-full h-screen mt-0 sec-2 relative pt-16`}>
        <div className="w-full absolute -z-20 top-0 sec-2-img-bg sec-op mb-10 ">
            <img className="w-full " src={titleBg} alt={"Product_BG_IMG"} />
        </div>
        <div className="w-full">
            <div className="w-[390px] h-auto mx-auto">
                <img className="w-full h-full" src={megatonBox} alt={"MegatonBox"}/>
            </div>
            <div className="w-[130px] h-auto mx-auto brightness-200">
                <img className="w-full h-full" src={megatonColorLogo} alt={"Color Logo"}/>
            </div>
            <div className="flex flex-col items-center gap-4 mt-5">
                <div className="text-2xl font-meta_header text-white text-center mt-5">
                    <label>GIẢI PHÁP STEM AI</label>
                </div>
                <div className="text-xl text-white font-meta_content w-[400px] mx-auto text-center mt-5">
                    <label>
                        Nguồn "tài nguyên" quý giá cho
                        giải pháp STEM toàn cầu
                    </label>
                </div>
                <div className="w-full flex items-center font-meta_content justify-center mt-5">
                    <button className="bg-red-600 px-10 py-2 text-2xl rounded-3xl mx-auto text-white">Mua</button>
                </div>
            </div>
        </div>
    </section>
}

export default TitleSection;
