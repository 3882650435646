import React from "react";
import skill1 from '../assests/logical.png';
import skill2 from '../assests/creative.png';
import skill3 from '../assests/coding.png';
import skill4 from '../assests/teamwork.png';


const SkillsSection = () => {
    return <section className="w-full bg-[#DEEBF7] py-5">
        <div className="text-3xl font-meta_header w-fit mx-auto">
            <label>PHÁT TRIỂN KỸ NĂNG</label>
        </div>
        <div className="flex flex-row gap-32 justify-center w-4/5 mx-auto mt-5 mb-5 font-bold">
            <div className="w-[200px] h-fit flex flex-col justify-center items-center">
                <div className="w-[75px] h-auto bg-[#5C9BD5] rounded-full">
                    <img src={skill3} alt="Skill" className="w-full h-auto"/>
                </div>
                <div className="text-lg text-center font-meta_content mt-3">
                    <label>KỸ NĂNG <br/>LẬP TRÌNH</label>
                </div>
            </div>
            <div className="w-[200px] h-fit flex flex-col justify-center items-center">
                <div className="w-[75px] h-auto bg-[#5C9BD5] rounded-full">
                    <img src={skill2} alt="Skill" className="w-full h-auto"/>
                </div>
                <div className="text-lg text-center font-meta_content mt-3">
                    <label>KỸ NĂNG <br/>SÁNG TẠO</label>
                </div>
            </div>
            <div className="w-[200px] h-fit flex flex-col justify-center items-center">
                <div className="w-[75px] h-auto bg-[#5C9BD5] rounded-full">
                    <img src={skill4} alt="Skill" className="w-full h-auto"/>
                </div>
                <div className="text-lg text-center font-meta_content mt-3">
                    <label>KỸ NĂNG <br/>LÀM VIỆC NHÓM</label>
                </div>
            </div>
            <div className="w-[200px] h-fit flex flex-col justify-center items-center">
                <div className="w-[75px] h-auto bg-[#5C9BD5] rounded-full">
                    <img src={skill1} alt="Skill" className="w-full h-auto"/>
                </div>
                <div className="text-lg text-center font-meta_content mt-3">
                    <label>KỸ NĂNG <br/>TƯ DUY LOGIC</label>
                </div>
            </div>
        </div>
    </section>
}

export default SkillsSection;
