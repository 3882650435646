import React from "react";
import certificate from '../assests/certificate.png';
import ta from '../assests/ta.png';
import complete from '../assests/compete.png';
import {MetaSlider} from "../../../Slider";
import drone1 from '../assests/byrobot_drone_3_10.png';
import drone2 from '../assests/byrobot_drone_8.png';
import drone3 from '../assests/drone_football.png';

const Certificates = () => {
    return <section className="mt-5">
        <div className="text-3xl font-meta_header mx-auto w-fit text-[#00B0F0]">
            <label>CHỨNG NHẬN</label>
        </div>
        <MetaSlider className="mt-10 mb-10" left={5} gap={10}>
            <div className="min-w-[290px] w-[290px] h-fit flex flex-col gap-5 items-center justify-center">
                <div className="w-1/2">
                    <img src={complete} alt={"CERT"} className="w-2/3 h-auto mx-auto border-2 border-[#00B0F0] rounded-3xl"/>
                </div>
                <div className="w-4/5 h-fit text-center font-meta_content">
                    <label>Cơ hội trực tiếp tham gia<br/>
                        các cuộc thi Robot tại<br/>
                        Hàn Quốc và toàn cầu
                    </label>
                </div>
            </div>
            <div className="min-w-[290px] w-[290px] h-fit flex flex-col gap-5 items-center justify-center">
                <div className="w-1/2">
                    <img src={ta} alt={"CERT"} className="w-2/3 h-auto mx-auto border-2 border-[#00B0F0] rounded-3xl"/>
                </div>
                <div className="w-2/3 h-fit text-center font-meta_content">
                    <label>Đủ kiến thức để <br/> trợ giảng về môn học <br/><span className="invisible">Hello</span>
                    </label>
                </div>
            </div>
            <div className="min-w-[290px] w-[290px] h-fit flex flex-col gap-5 items-center justify-center">
                <div className="w-1/2">
                    <img src={certificate} alt={"CERT"} className="w-2/3 h-auto mx-auto border-2 border-[#00B0F0] rounded-3xl"/>
                </div>
                <div className="w-2/3 h-fit text-center font-meta_content">
                    <label>Chứng nhận khoá học <br/>
                    được công nhận bởi các <br/>
                    Đại học Hàn Quốc
                    </label>
                </div>
            </div>
        </MetaSlider>
        <div className="text-3xl font-meta_header mx-auto w-fit text-[#00B0F0]">
            <label>ỨNG DỤNG DRONE ROBOT
            </label>
        </div>
        <MetaSlider className="mt-5 mb-10" left={5} gap={10}>
            <div className="min-w-[500px] w-[500px] min-h-[500px] relative">
                <div className="w-full h-fit relative ">
                    <img src={drone1} alt="Megaton box" className="w-2/3 h-auto mx-auto"/>
                </div>
                <div className="w-fit mx-auto font-meta_header text-[#00B0F0] text-xl mt-5">
                    <label>DRONE CHIẾN ĐẤU</label>
                </div>
                <div className="w-2/3 mx-auto text-center mt-5 font-meta_content">
                    <label>Cơ hội trực tiếp tham gia
                            các cuộc thi Robot tại
                            Hàn Quốc và toàn cầu</label>
                </div>
            </div>
            <div className="min-w-[500px] w-[500px] min-h-[500px] relative">
                <div className="w-full h-fit relative ">
                    <img src={drone3} alt="Megaton box" className="w-2/3 h-auto mx-auto"/>
                </div>
                <div className="w-fit mx-auto font-meta_header text-[#00B0F0] text-xl mt-5">
                    <label>DRONE BÓNG ĐÁ</label>
                </div>
                <div className="w-2/3 mx-auto text-center mt-5 font-meta_content">
                    <label>Học sinh được trải nghiệm những thử thách, mở rộng sân chơi đa quốc gia với bộ môn WORLD CUP Drone.</label>
                </div>
            </div>
            <div className="min-w-[500px] w-[500px] min-h-[500px] relative">
                <div className="w-full h-fit relative ">
                    <img src={drone2} alt="Megaton box" className="w-2/3 h-auto mx-auto"/>
                </div>
                <div className="w-fit mx-auto font-meta_header text-[#00B0F0] text-xl mt-5">
                    <label>DRONE LẬP TRÌNH</label>
                </div>
                <div className="w-2/3 mx-auto text-center mt-5 font-meta_content">
                    <label>Phát triển tư duy thông qua giải pháp lập trình scratch hoàn toàn mới do Hàn Quốc phát triển
                        </label>
                </div>
            </div>
        </MetaSlider>
    </section>
}

export default Certificates;
