import React from "react";
import megatonBanner from '../assests/Slide4.png'
import megatonBox from '../assests/megaton_box.png'
import logo from '../assests/LOGO.png'
import {useNavigate} from "react-router-dom";

const Banner = () => {
    const navigate = useNavigate();

    return <section className="w-full h-screen">
        <div className="snap-center min-w-full w-full h-full relative">
            <div className="absolute top-5 left-1/2 transform -translate-x-1/2 z-40  w-fit">
                <div className="w-fit mx-auto">
                    <img src={logo} className=" w-[100px] h-auto "/>
                </div>
            </div>
            <img src={megatonBanner} alt={"Banner"} id="image1" className="w-full h-full absolute top-0  "/>
            <div className="absolute w-4/5 top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pr-5">
                <div className="w-full h-fit mx-auto">
                    <img src={megatonBox} alt={"Megaton Box"} className="w-full h-auto"/>
                </div>
            </div>
            <div className="rounded-b-2xl absolute bottom-3 pt-10 z-30 w-full">
                <div className="text-4xl text-white w-fit mx-auto font-meta_header">
                    <label>GIẢI PHÁP STEM AI</label>
                </div>
                <div className="text-[#FF0000] font-meta_content w-fit mx-auto mt-4">
                    <label>MEGATON</label>
                </div>
                <div className="w-2/3 text-center mx-auto mt-3 text-sm font-meta_content text-white">
                    <label>Nguồn "tài nguyên" quý giá cho<br/>
                        giải pháp STEM toàn cầu
                    </label>
                </div>
                <div className="w-fit mx-auto mt-8 mb-5">
                    <button onClick={() => {
                        navigate('/contact');
                        window.location.reload();
                    }} className="font-meta_content text-white text-lg bg-[#FF0000] px-5 py-1.5 rounded-2xl">Liên hệ
                    </button>
                </div>
            </div>
        </div>
    </section>
}

export default Banner;
