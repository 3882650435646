import React from "react";
import titleBg from "../../Drone/assests/line_tech.png";
import certificate from '../../Drone/assests/certificate.png';
import ta from '../../Drone/assests/ta.png';
import complete from '../../Drone/assests/compete.png';
import drone1 from '../assests/megaton_box.png';
import drone2 from '../assests/SB3.png';
import drone3 from '../assests/SB4.png';

const Certificates = () => {
    return <section className={`w-full h-fit mt-0 sec-2 relative pt-16 about-sec`}>
        <div className=" w-[700px] h-auto absolute -z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mb-10">
            <img className="w-full certificates_bg_img" src={titleBg} alt={"Product_BG_IMG"} />
        </div>
        <div className="w-full">
            <div className="w-fit text-3xl font-meta_header text-[#0079CF] mx-auto">
                <label>CHỨNG NHẬN</label>
            </div>
        {/*    Certificates*/}
            <div className="w-full flex items-start justify-center gap-20 font-meta_content mt-10 mb-10">
                <div className="w-[200px] flex flex-col items-center justify-center">
                    <div className="w-[100px] p-1 border-2 border-[#41719C] rounded-3xl mb-5">
                        <img src={certificate} alt={"CERT"} className="w-full h-auto" />
                    </div>
                    <div className="text-md text-center">
                        <label>Chứng nhận khóa học
                            được công nhận bởi các
                            Đại học Hàn Quốc
                        </label>
                    </div>
                </div>
                <div className="w-[200px] flex flex-col items-center justify-center">
                    <div className="w-[100px] p-1 border-2 border-[#41719C] rounded-3xl mb-5">
                        <img src={ta} alt={"CERT"} className="w-full h-auto" />
                    </div>
                    <div className="text-md text-center">
                        <label>Đủ kiến thức để
                            trợ giảng về bộ môn

                        </label>
                    </div>
                </div>
                <div className="w-[200px] flex flex-col items-center justify-center">
                    <div className="w-[100px] p-1 border-2 border-[#41719C] rounded-3xl mb-5">
                        <img src={complete} alt={"CERT"} className="w-full h-auto" />
                    </div>
                    <div className="text-md text-center">
                        <label>Cơ hội trực tiếp tham gia
                            các cuộc thi Robot tại
                            Hàn Quốc và toàn cầu
                        </label>
                    </div>
                </div>
            </div>
        {/*    Utilizing*/}
            <div className="w-fit text-3xl font-meta_header text-[#0079CF] mx-auto">
                <label>ỨNG DỤNG DRONE ROBOT</label>
            </div>
            <div className="w-4/5 mx-auto flex items-center justify-between mt-20">
                <img src={drone2} alt={"DR1"} className="w-[380px] h-auto drone1"/>
                <img src={drone1} alt={"DR1"} className="w-[380px] h-auto"/>
                <img src={drone3} alt={"DR1"} className="w-[380px] h-auto drone2"/>
            </div>
        </div>
    </section>
}

export default Certificates;
