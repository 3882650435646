import React from "react";
import megatonColorLogo from "../assests/color_logo.png";
import megatonBoard from "../assests/megaton_board.png";

const AIIntroduction = () => {
    return <section className={`w-full h-fit mt-0 sec-2 relative pt-5 bg-[#F5F5F7]`}>
        <div>
            <div className="w-[130px] h-auto mx-auto brightness-200">
                <img className="w-full h-full" src={megatonColorLogo} alt={"Color Logo"}/>
            </div>
        </div>
        <div className="w-full">
            <div className="w-[400px] font-meta_header mx-auto text-center mt-10 text-2xl">
                <label>TÍCH HỢP ĐA DẠNG<br/>
                    TRÍ TUỆ NHÂN TẠO
                </label>
            </div>
            <div className="w-[600px] h-[300px] mx-auto mt-14  relative">
                <img className="w-[550px] h-auto absolute top-0" src={megatonBoard} alt={"Megaton Board"} />
            </div>
        </div>
    </section>
}

export default AIIntroduction;
