import React from "react";
import jeongnamUni from '../assests/jeonnam_uni.png'
import {useNavigate} from "react-router-dom";
import r1 from '../assests/r1.png';
import r2 from '../assests/r2.png';

const InternationalCompetitionIntroduction = () => {
    const navigate = useNavigate();
    return <section className={`w-full h-[380px] mt-0 sec-2 relative bg-[#F5F5F7]`}>
        <div className="flex items-center justify-center  h-full w-full">
            {/*Content Container*/}
            <div className="w-1/2 h-auto ml-24">
                <div className="font-meta_header text-[#0070C0] text-sm">
                    <label>Drone Robot</label>
                </div>
                <div  className="font-meta_header text-3xl text-left mt-5">
                    <label >DU ĐẤU QUỐC TẾ</label>
                </div>
                <div className="w-full text-left font-meta_content mx-auto mt-5 text-md pr-40">
                    <label>Ngoài ra các học sinh sẽ được tham gia các giải thi đấu khoa học sáng tạo thường niên tại Hàn Quốc vào tháng 7 do Đại học Jeonnam State tổ chức, từ đó các bé sẽ được tiếp xúc sớm và thực hành bộ môn khoa học ngay tại nhà trường</label>
                </div>
                <div className="w-full flex items-center justify-start mt-5">
                    <div className="w-fit flex items-center font-meta_content justify-center">
                    <button onClick={() => {
                        navigate('/contact');
                        window.location.reload();
                    }} className="px-7 py-1 text-lg border-2 border-[#41719C] text-[#0070C0]">Liên hệ</button>
                    </div>
                </div>
            </div>
            <div className="w-1/3 h-full ">
                 
            </div>
            
            <div className="h-[460px] z-30 absolute -top-12 right-20">
                <img className="h-full w-auto" src={r1}/>
            </div>
            <div className="h-[480px] z-10 absolute -top-12 right-10">
                <img className="h-full w-auto" src={r2}/>
            </div>
            

        </div>
        <div className="w-[400px] ml-24 flex items-center justify-center absolute -bottom-10">
            <div className="w-0 h-0
                  border-t-[0px] border-t-transparent
                  border-r-[55px] border-r-[#F5F5F7]
                  border-b-[50px] border-b-transparent">
            </div>
            <div className="w-[900px] h-[50px] bg-[#F5F5F7] ">

            </div>
            <div className="w-0 h-0
                  border-t-[0px] border-t-transparent
                  border-l-[55px] border-l-[#F5F5F7]
                  border-b-[50px] border-b-transparent">
            </div>
        </div>
    </section>
}

export default InternationalCompetitionIntroduction;
