import React from "react";
import certificate from '../../Drone/assests/certificate.png';
import ta from '../../Drone/assests/ta.png';
import complete from '../../Drone/assests/compete.png';
import {MetaSlider} from "../../../Slider";
import drone1 from '../../Drone/assests/byrobot_drone_3_10.png';
import drone2 from '../../Drone/assests/byrobot_drone_8.png';
import drone3 from '../../Drone/assests/drone_football.png';
import sb3 from '../assests/SB3.png';
import sb4 from '../assests/SB4.png';
import megatonBoard from '../assests/megaton_box.png';

const Certificates = () => {
    return <section className="mt-10">
        <div className="text-3xl font-meta_header mx-auto w-fit text-[#00B0F0]">
            <label>CHỨNG NHẬN</label>
        </div>
        <MetaSlider className="mt-5 mb-10" gap={10}>
            <div className="min-w-[290px] w-[290px] h-fit flex flex-col gap-5 items-center justify-center">
                <div className="w-1/2">
                    <img src={complete} alt={"CERT"} className="w-2/3 h-auto mx-auto border-2 border-[#00B0F0] rounded-3xl"/>
                </div>
                <div className="w-3/5 h-fit text-center">
                    <label>Cơ hội trực tiếp tham gia
                        các cuộc thi Robot tại
                        Hàn Quốc và toàn cầu
                    </label>
                </div>
            </div>
            <div className="min-w-[290px] w-[290px] h-fit flex flex-col gap-5 items-center justify-center">
                <div className="w-1/2">
                    <img src={ta} alt={"CERT"} className="w-2/3 h-auto mx-auto border-2 border-[#00B0F0] rounded-3xl"/>
                </div>
                <div className="w-1/2 h-fit">
                    <label>Cơ hội trực tiếp tham gia
                        các cuộc thi Robot tại
                        Hàn Quốc và toàn cầu
                    </label>
                </div>
            </div>
            <div className="min-w-[290px] w-[290px] h-fit flex flex-col gap-5 items-center justify-center">
                <div className="w-1/2">
                    <img src={certificate} alt={"CERT"} className="w-2/3 h-auto mx-auto border-2 border-[#00B0F0] rounded-3xl"/>
                </div>
                <div className="w-1/2 h-fit">
                    <label>Cơ hội trực tiếp tham gia
                        các cuộc thi Robot tại
                        Hàn Quốc và toàn cầu
                    </label>
                </div>
            </div>
        </MetaSlider>
        <div className="text-3xl font-meta_header mx-auto w-fit text-[#00B0F0]">
            <label>ỨNG DỤNG DRONE ROBOT
            </label>
        </div>
        <MetaSlider className="mt-5 mb-10" gap={10}>
            <div className="min-w-[500px] w-[500px] min-h-[500px] relative">
                <div className="w-full h-fit relative ">
                    <img src={sb3} alt="Megaton box" className="w-2/3 h-auto mx-auto"/>
                </div>
                <div className="w-fit mx-auto font-meta_header text-[#FFC000] text-xl mt-5">
                    <label>SCIENCE BOX NO6</label>
                </div>
                <div className="w-2/3 mx-auto text-center mt-5">
                    <label>Kết hợp với kiến thức khoa học ứng dụng thông qua bộ môn lắp ráp kĩ thuật cơ khí và lập trình.
                        </label>
                </div>
            </div>
            <div className="min-w-[500px] w-[500px] min-h-[500px] relative">
                <div className="w-full h-fit relative ">
                    <img src={megatonBoard} alt="Megaton box" className="w-2/3 h-auto mx-auto"/>
                </div>
                <div className="w-fit mx-auto font-meta_header text-[#FFC000] text-xl mt-5">
                    <label>MEGATON</label>
                </div>
                <div className="w-2/3 mx-auto text-center mt-5">
                    <label>Nguồn "tài nguyên" quý giá cho  giải pháp STEM tích hợp đa dạng Trí Tuệ Nhân Tạo
                        </label>
                </div>
            </div>
            <div className="min-w-[500px] w-[500px] min-h-[500px] relative">
                <div className="w-full h-fit relative ">
                    <img src={sb4} alt="Megaton box" className="w-2/3 h-auto mx-auto"/>
                </div>
                <div className="w-fit mx-auto font-meta_header text-[#FFC000] text-xl mt-5">
                    <label>SCIENCE BOX NO3</label>
                </div>
                <div className="w-2/3 mx-auto text-center mt-5">
                    <label>Bộ công cụ Sciencebox giúp trẻ em phát triển vượt trội về khả năng tư duy logic và lập trình</label>
                </div>
            </div>
        </MetaSlider>
    </section>
}

export default Certificates;
