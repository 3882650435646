import React from "react";
import megatonColorLogo from "../assests/color_logo.png";
import pythonLogo from '../assests/python_logo.png';
import cLogo from '../assests/c_logo.png';
import scratchLogo from '../assests/scratch_logo.png';

const CodingToolIntroduction = () => {
    return <section className={`w-full h-fit mt-0 sec-2 relative pt-5 bg-[#F5F5F7] pb-20`}>
        <div>
            <div className="w-[130px] h-auto mx-auto brightness-200">
                <img className="w-full h-full" src={megatonColorLogo} alt={"Color Logo"}/>
            </div>
        </div>
        <div className="w-full">
            <div className="w-fit text-2xl font-bold font-meta_header mx-auto mt-10">
                <label>Phần mềm lập trình</label>
            </div>
            <div className="text-lg font-meta_content text-[#5B9BD5] font-bold underline mx-auto w-fit mt-6">
                <label>Tìm hiểu thêm</label>
            </div>
            <div className="w-full flex items-center justify-center gap-40 mt-10">
                <div className="w-[150px] h-auto">
                    <img className="w-full h-full" src={pythonLogo} alt={"Python Logo"}/>
                </div>
                <div className="w-[150px] h-auto">
                    <img className="w-full h-full" src={scratchLogo} alt={"Python Logo"}/>
                </div>
                <div className="w-[150px] h-auto">
                    <img className="w-full h-full" src={cLogo} alt={"Python Logo"}/>
                </div>
            </div>
        </div>

    </section>
}

export default CodingToolIntroduction;
