import React from "react";
import cLogo from '../assests/c_logo.png';
import scratchLogo from '../assests/scratch_logo.png';
import pythonLogo from '../assests/python_logo.png';
import {useNavigate} from "react-router-dom";

const logos = [
    cLogo,
    scratchLogo,
    pythonLogo
]

const CodingToolIntroduction = () => {
    const navigate = useNavigate();

    return <section className="w-full h-fit mt-10">
        <div className="w-full h-fit">
            <div className="w-fit text-3xl text-[#FF0000] mx-auto font-meta_header">
                <label>ỨNG DỤNG</label>
            </div>
            <div className="w-2/3 text-center font-meta_content text-sm mt-5 mx-auto">
                <label>Đặc biệt, các học sinh sẽ được tham gia các giải thi đấu khoa học sáng tạo thường niên do Sciencebox tổ chức
                    trong nước và quốc tế.
                </label>
            </div>
            <div className="w-fit mx-auto mt-10">
                    <button onClick={() => {
                        navigate('/contact');
                        window.location.reload();
                    }} className="font-meta_content text-white text-xs bg-[#FF0000] px-6 py-1.5 rounded-2xl">MUA
                    </button>
            </div>
            <div className="w-full h-[300px] mx-auto mb-5 mt-10 ">
                <iframe
                    className="w-full h-[300px]"
                    src={`https://www.youtube.com/embed/${'DIFDnhLjwks'}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
        <div className="font-meta_header text-3xl w-fit mx-auto text-[#FF0000] mt-10 mb-5">
            <label>PHẦN MỀM LẬP TRÌNH
            </label>
        </div>
        <div className="w-full border-2 border-white bg-[#FF0000] h-fit rounded-3xl py-5 flex items-center justify-center gap-10">
            {logos.map((logo, index) => <img key={index} src={logo} alt="Logo" className="w-[100px] h-auto"/>)}
        </div>
    </section>
}

export default CodingToolIntroduction;
