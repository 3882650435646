import React from "react";
import megatonBanner from "../assests/Slide5.png";
import megatonBox from "../../Megaton/assests/megaton_box.png";
import sb3 from '../assests/SB3.png';
import sb4 from '../assests/SB4.png';
import {useNavigate} from "react-router-dom";
import sbLogo from '../assests/sb_logo.png'

const Banner = () => {
    const navigate = useNavigate();

    return <section className="w-full h-screen relative ">
        <div className="snap-center min-w-full w-full h-full relative rounded-b-2xl">
            <div className="absolute top-5 left-1/2 transform -translate-x-1/2 z-40  w-fit">
                <div className="w-fit mx-auto">
                    <img src={sbLogo} className=" w-[60px] h-auto "/>
                </div>
            </div>
            <img src={megatonBanner} alt={"Banner"} id="image1" className="w-full h-full absolute top-0"/>
            <div className="w-full absolute top-24 z-10 -right-24">
                <div className="w-2/3 h-fit mx-auto  relative">
                    <img src={sb4} alt={"Megaton Box"} className="w-full h-auto"/>
                </div>
            </div>
            <div className="w-full absolute top-52 -left-20 z-20">
                <div className="w-2/3 h-fit mx-auto brightness-125 relative">
                    <img src={sb3} alt={"Megaton Box"} className="w-full h-auto"/>
                </div>
            </div>

            <div className="rounded-b-2xl absolute bottom-3 pt-10 z-30 w-full">
                <div className="text-4xl text-white w-fit mx-auto text-center font-meta_header">
                    <label>GIẢI PHÁP STEM <br/> ỨNG DỤNG</label>
                </div>
                <div className="text-[#FF0000] font-meta_content w-fit mx-auto mt-4">
                    <label>SCIENCEBOX</label>
                </div>
                <div className="w-2/3 text-center mx-auto mt-3 text-sm font-meta_content text-white">
                    <label>Bộ học cụ Robotics AI (NO3, NO6)
                        giải pháp nâng tầm sáng tạo
                    </label>
                </div>
                <div className="w-fit mx-auto mt-8 mb-5">
                    <button onClick={() => {
                        navigate('/contact');
                        window.location.reload();
                    }} className="font-meta_content text-white text-lg bg-[#FF0000] px-5 py-1.5 rounded-2xl">Liên hệ
                    </button>
                </div>
            </div>
        </div>
    </section>
}

export default Banner;


{/* <div className="rounded-b-2xl absolute bottom-3 pt-5 z-30">
                <div className="text-4xl text-white w-fit mx-auto font-meta_header text-center">
                    <label>GIẢI PHÁP STEM <br/> ỨNG DỤNG
                    </label>
                </div>
                <div className="text-[#FF0000] font-meta_content w-fit mx-auto mt-8">
                    <label>SCIENCEBOX</label>
                </div>
                <div className="w-2/3 text-center text-sm mx-auto mt-5 font-meta_content text-white">
                    <label>Bộ học cụ Robotics AI (NO3, NO6)
                        giải pháp nâng tầm sáng tạo
                    </label>
                </div>
                <div className="w-fit mx-auto mt-10">
                    <button onClick={() => {
                        navigate('/contact')
                    }} className="font-meta_content text-white text-lg bg-[#FF0000] px-5 py-1.5 rounded-2xl">Liên hệ
                    </button>
                </div>
            </div> */}
