import React, {useContext, useEffect, useState} from "react";
import webLogo from '../../assets/new_web_logo.png'
import {Link, useNavigate} from "react-router-dom";
import {LanguageContext} from "../../App";
import vn from '../../assets/vn.png';
import en from '../../assets/en.jpeg';
import kr from '../../assets/kr.png'
import {LanguageType} from "../../pages/utils";
import './style.css'


interface Product {
    name: String,
    description: String,
    image: String,
    url: String
}

interface ProductProps {
    product: string;
    idx: number
}

const NavBar = () => {
    const [open, setOpen] = useState(false);
    const [isHoverProduct, setIsHoverProduct] = useState(false);
    const [isHoverIntro, setIsHoverIntro] = useState(false);
    const [isHoverHiring, setIsHoverHiring] = useState(false);
    const [isHoverLanguage, setIsHoverLanguage] = useState(false);
    const [onClickArrow, setOnClickArrow] = useState(false);
    const [onClickLanguageArrow, setOnClickLanguageArrow] = useState(false);
    const navigator = useNavigate();
    const {language, setLanguage, pageContent, windowSize} = useContext(LanguageContext) as LanguageType;
    const [onClickProductArrow, setOnClickProductArrow] = useState(false);

    useEffect(() => {
        console.log("Scrol to top")
        window.scrollTo(0, 0); // Scrolls to the top left corner of the page
    }, []);

    const productUrls = [
        "/products/robotics-ai",
        "/products/megaton"
    ]


    const ProductCategory: React.FC<ProductProps> = ({product, idx}) => {

        return <div className={`hover:bg-gray-200 w-full px-4 bg-white text-left py-6 transition-opacity font-meta_header duration-300 rounded-xl`}>
            <button onClick={() => {
                // @ts-ignore
                navigator(productUrls[idx]);
                window.location.reload();
            }}>{product}</button>
        </div>
    }

    return <div className="absolute w-screen top-0 bg-transparent z-40 font-meta_header tracking-wide">
        {/*Web nav*/}
        <div onMouseLeave={() => {
            setIsHoverProduct(false);
            setIsHoverIntro(false);
            setIsHoverHiring(false);
            setIsHoverLanguage(false);
        }} className="text-white text-xl">
            <div className="w-full flex items-center justify-between ">
                <div onClick={() => {
                    navigator('/');
                    window.location.reload();
                }} className={` cursor-pointer w-[130px] lg:w-[200px] h-auto mt-4 ${(windowSize.width < 1024) ? 'hidden' : ''}`}>
                    <img className="w-full h-full" src={webLogo} alt={'Web logo'} />
                </div>
                {windowSize.width < 1024 ? null :
                    <div className={` flex items-center justify-end gap-10 w-full h-full font-meta_header  font-bold invisible lg:visible `}>
                    
                    <div onMouseEnter={() => {
                        setIsHoverProduct(false)
                        setIsHoverIntro(false);
                        setIsHoverHiring(false);
                    }} className="hover:text-purple-300 hover:border-b-4 hover:border-purple-300 transition-colors duration-150 shadow-2xl ">
                        <button onClick={() => {
                            navigator('/');
                            window.location.reload();
                        }} className="font-meta_header">{pageContent.navBar.home}</button>
                    </div>
                    <div onMouseEnter={() => {
                        setIsHoverIntro(false);
                        setIsHoverHiring(false);
                        setIsHoverLanguage(false);
                    }}  className="relative transition-colors  duration-150">
                        <button onMouseEnter={() => {
                            setIsHoverProduct(true);
                            console.log("Hover products");
                        }} onClick={() => {

                        }} className="hover:text-purple-300 font-meta_header ">{pageContent.navBar.products.title}</button>
                        <div className={`absolute w-72 font-custom1  flex flex-col gap-4  transition-all ease-in-out -z-10 duration-500 bg-white text-black top-8 rounded-xl ${isHoverProduct ? 'opacity-100' : 'opacity-0'}`}>
                            {pageContent.navBar.products.contents.map((product, index) => <ProductCategory key={index} idx={index} product={product}/>)}
                        </div>
                    </div>



                    <div onMouseEnter={() => {
                        setIsHoverProduct(false);
                        setIsHoverHiring(false);
                        setIsHoverLanguage(false);

                    }} className="relative transition-colors duration-150">
                        <div onMouseEnter={() => {
                            setIsHoverIntro(true);
                        }} className="hover:text-purple-300 cursor-pointer font-meta_header">{pageContent.navBar.introduction.title}</div>
                        <div className={`absolute w-72 text-black  font-custom1  flex flex-col gap-4 -z-10  transition-all ease-in-out duration-500 bg-white top-8  rounded-xl ${isHoverIntro ? 'opacity-100' : 'opacity-0'}`}>
                            <div className={`rounded-xl hover:bg-gray-200 px-4 font-meta_header py-6 transition-opacity duration-300 ${isHoverIntro ? 'opacity-100' : 'opacity-0'}`}>
                                <Link to={"/about-us/drone"} state={{section: "1"}}>{pageContent.navBar.introduction.contents[0]}</Link>
                            </div>
                            <div className={`rounded-xl hover:bg-gray-200 px-4 font-meta_header py-6 transition-opacity duration-500 ${isHoverIntro ? 'opacity-100' : 'opacity-0'}`}>
                                <Link to={"/about-us/robotics"} state={{section: "2"}}>{pageContent.navBar.introduction.contents[1]}</Link>
                            </div>
                        </div>
                    </div>


                    <div onMouseEnter={() => {
                        setIsHoverProduct(false);
                        setIsHoverIntro(false);
                        setIsHoverLanguage(false);

                    }} className="relative transition-colors duration-150">
                        <button onMouseEnter={() => {
                            setIsHoverHiring(true);
                        }} className="hover:text-purple-300 font-meta_content tracking-widest">{pageContent.navBar.hiring.title}</button>
                        <div className={`absolute w-72  text-black  flex flex-col gap-4 -z-10  transition-all ease-in-out duration-500 top-8 bg-white rounded-xl ${isHoverHiring ? 'opacity-100' : 'opacity-0'}`}>
                            <div onClick={() => {
                                navigator('/hiring');
                                window.location.reload();
                            }} className={`rounded-xl  cursor-pointer px-4 hover:bg-gray-200  py-6 transition-opacity duration-300 ${isHoverHiring ? 'opacity-100' : 'opacity-0'}`}>
                                <label>{pageContent.navBar.hiring.contents[0]}</label>
                            </div>
                            <div onClick={() => {
                                navigator('/contact');
                                window.location.reload();
                            }} className={`rounded-xl cursor-pointer px-4 hover:bg-gray-200 py-6 transition-opacity duration-500 ${isHoverHiring ? 'opacity-100' : 'opacity-0'}`}>
                                <label>{pageContent.navBar.hiring.contents[1]}</label>
                            </div>
                        </div>
                    </div>


                    <div onMouseEnter={() => {
                        setIsHoverProduct(false);
                        setIsHoverIntro(false);
                        setIsHoverHiring(false);
                        setIsHoverLanguage(false);
                    }} className="hover:text-purple-300 hover:border-b-4 hover:border-purple-300 transition-colors duration-150">
                        <button onClick={() => {
                            navigator('/news');
                            window.location.reload();
                        }} className="font-meta_header">{pageContent.navBar.news}</button>
                    </div>
                    <div onMouseEnter={() => {
                        setIsHoverProduct(false);
                        setIsHoverIntro(false);
                        setIsHoverHiring(false);
                        setIsHoverLanguage(false);
                    }} onClick={() => {
                        navigator('/contact');
                        window.location.reload();
                    }} className="hover:text-purple-300 hover:border-b-4 hover:border-purple-300 transition-colors duration-150">
                        <button className="font-meta_header">{pageContent.navBar.contact}</button>
                    </div>
                    {/*Language Tab*/}
                    <div onMouseEnter={() => {
                        setIsHoverProduct(false);
                        setIsHoverIntro(false);
                        setIsHoverHiring(false);

                    }} className="relative transition-colors duration-150">
                        <button onMouseEnter={() => {
                            setIsHoverLanguage(true);
                        }} className="hover:text-purple-300 font-meta_header tracking-widest">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                            </svg>
                        </button>
                        <div className={`absolute -left-52 w-56  rounded-xl  flex flex-col gap-4  transition-all ease-in-out -z-10 duration-500 ${isHoverLanguage ? 'bg-white h-fit top-20 ' : 'top-[-490px] '}`}>
                            <div onClick={() => {
                                setLanguage("vn");
                                localStorage.meta_square_website_language = "vn";
                                window.location.reload();
                            }} className={`rounded-xl cursor-pointer hover:bg-gray-200 px-4 py-6 transition-opacity duration-300 ${isHoverLanguage ? 'opacity-100' : 'opacity-0'}`}>
                                <img className="w-10 mx-auto" src={vn}/>
                            </div>
                            <div onClick={() => {
                                setLanguage("en");
                                localStorage.meta_square_website_language = "en";
                                window.location.reload();
                            }} className={`rounded-xl cursor-pointer hover:bg-gray-200 px-4 py-6 transition-opacity duration-500 ${isHoverLanguage ? 'opacity-100' : 'opacity-0'}`}>
                                <img className="w-10 mx-auto" src={en}/>
                            </div>
                            <div onClick={() => {
                                setLanguage("kr");
                                localStorage.meta_square_website_language = "kr";
                                window.location.reload();
                            }} className={` rounded-xl cursor-pointer hover:bg-gray-200 px-4 py-6 transition-opacity duration-500 ${isHoverLanguage ? 'opacity-100' : 'opacity-0'}`}>
                                <img className="w-20 mx-auto" src={kr}/>
                            </div>
                        </div>
                    </div>
                </div>}

                {/*Burger button*/}
                <div onClick={()=>setOpen(!open)} className={`text-3xl mr-8 cursor-pointer visible lg:invisible ml-5 mt-3`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-10 h-10 transition-all duration-300 ${open ? 'rotate-90' : ''} `}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
                    </svg>
                </div>
            </div>
        </div>


        {/*Mobile nav*/}
        <div className="lg:hidden lg:z-40 ">
            <ul className={`  absolute  bg-[#00B0F0] bg-opacity-50 lg:z-auto -z-10 left-0 w-full  navbar-stay ${open ? 'open pt-10' : ''}  transition-all duration-500 ease-in top-0`} style={{ listStyleType: 'none' }}>
                {/*Home Tab*/}
                <li className='ml-8 text-left text-2xl lg:my-0 my-7'>
                    <button onClick={() => {
                        navigator('/');
                        window.location.reload();
                    }} className='text-white hover:text-gray-400 duration-500'>{pageContent.navBar.home}</button>
                </li>
                {/*Product Tab*/}
                <li className='ml-8 text-left text-2xl'>
                    <div onClick={() => {
                        setOnClickProductArrow(!onClickProductArrow);
                    }} className="flex flex-row items-center justify-between w-full ">
                        <button className='text-white  text-left w-full hover:text-gray-400 duration-500'>{pageContent.navBar.products.title}</button>
                        <div onClick={() => {
                            setOnClickProductArrow(!onClickProductArrow);
                        }} className={` transition-all duration-300 w-full flex items-center justify-end mr-10`}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-5 h-5 ${onClickProductArrow ? '-rotate-90' : 'rotate-0'} transition-all duration-300`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                            </svg>
                        </div>
                    </div>
                    <div className={`bg-[#00B0F0] bg-opacity-25 mt-3 transition-all duration-300 product-nav-stay ease-in ${onClickProductArrow ? 'open' : ''}`}>
                        <div className={`flex flex-col gap-3  `}>
                            {pageContent.navBar.products.contents.map((product, index) => <button onClick={() => {
                                if (index === 0) {
                                    navigator('/products/robotics-ai');
                                }else if (index === 1) {
                                    navigator('/products/megaton');
                                }else {
                                    navigator('/contact');
                                }
                                window.location.reload();
                            }} className={` py-1 h-[65px] w-full text-xl text-left text-white`}>{product}</button>)}
                        </div>
                    </div>
                </li>
                {/*About tab*/}
                <li className='ml-8 text-left text-2xl lg:my-0 my-7'>
                    <div onClick={() => {
                        setOnClickArrow(!onClickArrow);
                    }} className="flex flex-row items-center justify-between w-full ">
                        <button className='text-white  text-left w-full hover:text-gray-400 duration-500'>{pageContent.navBar.introduction.title}</button>
                        <div onClick={() => {
                            setOnClickArrow(!onClickArrow);
                        }} className={` transition-all duration-300 w-full flex items-center justify-end mr-10`}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-5 h-5 ${onClickArrow ? '-rotate-90' : 'rotate-0'} transition-all duration-300`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                            </svg>
                        </div>
                    </div>

                    <div className={`bg-[#00B0F0] mt-3 transition-all duration-300 course-navbar-stay ease-in ${onClickArrow ? 'open' : ''}`}>
                        <div className={`flex flex-col gap-3  `}>
                            <button onClick={() => {
                                navigator('/about-us/drone');
                                window.location.reload();
                            }} className={` py-1 h-[65px] w-full text-xl text-left text-white`}>{pageContent.navBar.introduction.contents[0]}</button>
                            <button onClick={() => {
                                navigator('/about-us/robotics');
                                window.location.reload();
                            }} className={` py-1 h-[65px] w-full text-xl text-left  text-white`}>{pageContent.navBar.introduction.contents[1]}</button>
                            </div>
                    </div>
                </li>
                {/*Hiring Tab*/}
                <li className='ml-8 text-left text-2xl my-7'>
                    <button onClick={() => {
                        navigator('/hiring');
                        window.location.reload();
                    }} className='text-white duration-500'>{pageContent.navBar.hiring.title}</button>
                </li>
                {/*News Tab*/}
                <li className='ml-8 text-left text-2xl my-7'>
                    <button onClick={() => {
                        navigator('/news');
                        window.location.reload();
                    }} className='text-white duration-500'>{pageContent.navBar.news}</button>
                </li>
                {/*Contact Tab*/}
                <li className='ml-8 text-left text-2xl my-2'>
                    <button onClick={() => {
                        navigator('/contact');
                        window.location.reload();
                    }} className='text-white duration-500'>{pageContent.navBar.contact}</button>
                </li>
                {/*Language Tab*/}
                <li onClick={() => {
                    setOnClickLanguageArrow(!onClickLanguageArrow);
                }} className='ml-8 text-left text-xl lg:my-0 my-7'>
                    <div className="flex flex-row items-center justify-between px-12">
                        <div className="opacity-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                            </svg>
                        </div>
                        <button className='text-gray-800 hover:text-gray-400 duration-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#FFFFFF" className="w-9 h-9">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                            </svg>
                        </button>
                        <div onClick={() => {
                            setOnClickLanguageArrow(!onClickLanguageArrow);
                        }} className={`${onClickLanguageArrow ? '-rotate-90' : 'rotate-0'} transition-all duration-300`}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                            </svg>
                        </div>
                    </div>
                    <div className="bg-[#00B0F0] mt-3">
                        <div className={`flex flex-col transition-all duration-500 gap-3 language-nav-stay ${onClickLanguageArrow ? 'open' : ''}`}>
                            <button onClick={() => {
                                setLanguage("en");
                                localStorage.meta_square_website_language = "en";
                                window.location.reload();
                            }} className="hover:bg-gray-50 py-1">
                                <img className="w-10 mx-auto" src={en}/>
                            </button>
                            <button onClick={() => {
                                setLanguage("vn");
                                localStorage.meta_square_website_language = "vn";
                                window.location.reload();
                            }} className="hover:bg-gray-50 py-1">
                                <img className="w-10 mx-auto" src={vn}/>
                            </button>
                            <button onClick={() => {
                                setLanguage("kr");
                                localStorage.meta_square_website_language = "kr";
                                window.location.reload();
                            }} className="hover:bg-gray-50 py-1">
                                <div className="w-fit mx-auto bg-white">
                                    <img className="w-10 h-6" src={kr}/>
                                </div>
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

    </div>
}


export default NavBar;
