import React from "react";
import Banner from "./Banner";
import AboutCourse from "./AboutCourse";
import InternationalCompetition from "./InternationalCompetition";
import Certificates from "./Certificates";
import Activity from "./Activity";

const RoboticsPagePhone = () => {
    return <div className="overflow-hidden">
        <Banner/>
        <AboutCourse/>
        <InternationalCompetition/>
        <Certificates/>
        <Activity/>
    </div>
}

export default RoboticsPagePhone;
