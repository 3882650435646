import exp from "constants";
import pn1 from '../assets/partner_new/vas_new.png';
import pn2 from '../assets/partner_new/logo HUTECH.png';
import pn3 from '../assets/partner_new/bk_new.png';
import pn4 from '../assets/partner_new/logo CIS.png';
import pn5 from '../assets/partner_new/dcu.png';
import pn6 from '../assets/partner_new/logo skis.png';
import pn7 from '../assets/partner_new/logo royal.png';
import pn8 from '../assets/partner_new/Logo-UEF.png';
import pn9 from '../assets/partner_new/LOGO SS.png';
import pn11 from '../assets/partner_new/logo bis.png';
import pn12 from '../assets/partner_new/logo IS.png';
import pn13 from '../assets/partner_new/asomeit.png';
import pn14 from '../assets/partner_new/Coss.png';
import pn15 from '../assets/partner_new/Drone division.png';
import pn16 from '../assets/partner_new/GBICT.png';
import pn17 from '../assets/partner_new/TĐT.png';
import pn18 from '../assets/partner_new/science box.png';
import {Dispatch, SetStateAction} from "react";
import teamwork from '../assets/certificate/teamwork.png';
import coding from '../assets/certificate/coding.png';
import logical from '../assets/certificate/logical.png';
import creative from '../assets/certificate/creative.png';
import cert1 from '../assets/certificate/1.png';
import cert2 from '../assets/certificate/2.png';
import cert3 from '../assets/certificate/3.png';
import cert4 from '../assets/certificate/4.png';

export const skills  = [teamwork, coding, logical, creative];
export const certificates = [cert1, cert2, cert3, cert4];

export const partnersImg = [
    pn1,
    pn2,
    pn3,
    pn4,
    pn5,
    pn6,
    pn7,
    pn8,
    pn9,
    pn11,
    pn12,
    pn13,
    pn14,
    pn15,
    pn16,
    pn17,
    pn18

]

export interface HomeContentProps {
    levelHeader: string,
    productHeader: string,
    skillHeader: string,
    partnerHeader: string,
    newsHeader: string
}

export interface BriefProductProps {
    title: string,
    summary: string,
    button: string
}

export interface FooterContactProps {
    title: string,
    hotline: string,
    email: string,
    office: string
}

export interface FooterCustomerSupportProps {
    title: string,
    contact: string,
    news: string,
    hiring: string,
    introduction: string
}

export interface FooterAboutProps {
    title: string,
    content: string
}

export interface FooterContentProps {
    about: FooterAboutProps,
    contact: FooterContactProps,
    customerSupport: FooterCustomerSupportProps
}

export interface NavBarListProps {
    title: string,
    contents: string[]
}



export interface NavBarContentProps {
    home: string,
    products: NavBarListProps,
    introduction: NavBarListProps,
    hiring: NavBarListProps,
    news: string,
    contact: string
}

export interface CurriculumProps {
    title: string,
    content: string,
    button: string
    skills: string[]
}

export interface IntroductionPageProps {
    curricula: CurriculumProps[]
}

export interface HiringArrProps {
    title: string,
    content: string[]
}

export interface HiringPageProps {
    name: string,
    phone: string,
    email: string,
    position: HiringArrProps,
    certificate: HiringArrProps,
    content: string,
    button: string
}

export interface ContactPageContactInfoProps {
    title: string,
    addressTitle: string,
    address: string,
    workingTimeTitle: string,
    workingTime: string,
    phoneTitle: string
}

export interface InfoFormProps {
    title: string,
    question: string,
    nameField: string,
    emailField: string,
    phoneField: string,
    contentField: string
}

export interface ContactPageProps {
    contact: ContactPageContactInfoProps,
    infoForm: InfoFormProps,
    button: string
}

interface ProductDetailPage {
    title: string,
    summary: string,
    description: string,
    detailDescription: string,
    relatedProduct: string,
    contactButton: string,
    viewMoreButton: string,
    status: string,
    available: string
}

interface NewsPageProps {
    latestNews: string
}

export interface LanguageContentProps {
    home: HomeContentProps,
    homeSkills: string[],
    briefProduct: BriefProductProps[],
    footerContent: FooterContentProps,
    navBar: NavBarContentProps,
    introductionPage: IntroductionPageProps,
    hiringPage: HiringPageProps,
    contactPage: ContactPageProps,
    languageType: string,
    productDetailPage: ProductDetailPage[],
    newsPage: NewsPageProps
}

interface WindowSizeProps {
    width: number,
    height: number
}

export interface LanguageType {
    language: string
    setLanguage: (language: string) => void
    pageContent: LanguageContentProps,
    windowSize: WindowSizeProps,
    setIsHomePage: Dispatch<SetStateAction<boolean>>;
}

export const webContent = {
    "vn": {
        "home": {
            "levelHeader": "Chương trình đào tạo",
            "productHeader": "Sản phẩm",
            "skillHeader": "Kỹ Năng",
            "partnerHeader": "Đối tác",
            "newsHeader": "Tin tức"
        },
        "homeSkills": [
            "Lập trình",
            "Thuyết trình",
            "Tư duy logic",
            "Kỹ năng sáng tạo",
            "Làm việc nhóm"
        ],
        "briefProduct": [
            {
                "title": "Lập Trình Drone (BRC-105)",
                "summary": "Phát triển tư duy thông qua giải pháp lập trình scratch và nhiều kỹ năng khác mở rộng môi trường thông qua các cuộc thi trong và ngoài nước.",
                "button": "Xem thêm"
            },
            {
                "title": "Drone Bóng Đá (SKYKICK EVOLUTION)",
                "summary": "Học sinh được trải nghiệm những thử thách, mở rộng sân chơi đa quốc gia với bộ môn WORLD CUP Drone.",
                "button": "Xem thêm"
            },
            {
                "title": "Drone Chiến Đấu (BRB-95)",
                "summary": "Đặc biệt sản phẩm để các bạn nhỏ trải nghiệm nhiều hơn nữa những công nghệ tiên tiến.",
                "button": "Xem thêm"
            },
            {
                "title": "Robotics AI (NO3 /NO6)",
                "summary": "Tiếp cận công nghệ cao ứng dụng trí tuệ nhân tạo vào lập trình và phát triển Robot, tạo cơ hội hội nhập thông qua các cuộc thi Đa quốc gia.",
                "button": "Xem thêm"
            },
            {
                "title": "MEGATON - BỘ CÔNG CỤ ỨNG DỤNG AI",
                "summary": "Megaton là một giải pháp công nghệ tích hợp trí tuệ nhân tạo (AI), giải pháp cung cấp một loạt các công cụ và tài liệu học tập thông minh để hỗ trợ việc học tập và nghiên cứu robot và ứng dụng thực tế.",
                "button": "Xem thêm"
            }
        ],
        "footerContent": {
            about: {
                title: "Về Meta Square",
                content: "Là môi trường học tập\n" +
                        "                        STEM cải tiến, ứng dụng\n" +
                    "                        đa dạng công nghệ\n" +
                    "                        (Robotic, Drone, AI, ...)\n" +
                    "                        phù hợp ở mọi độ tuổi từ\n" +
                    "                        cấp Tiểu học đến bậc Đại\n" +
                    "                        học - mang lại môi\n" +
                    "                        trường học tập đa Quốc\n" +
                    "                        gia thông qua nhiều cuộc\n" +
                    "                        thi & chứng chỉ Quốc tế."
            },
            contact: {
                title: "Thông tin liên hệ",
                hotline: "Hotline: 0817665064",
                email: "admin@scienceboxvietnam.vn",
                office: "Văn phòng Hutech số 475A (số cũ:144/24)\n" +
                    "                                Điện Biên Phủ, Phường 25, Quận Bình Thạnh, TP.HCM."
            },
            customerSupport: {
                title: "Hỗ trợ khách hàng",
                contact: "Liên hệ",
                news: "Tin tức",
                hiring: "Tuyển dụng",
                introduction: "Giới thiệu"
            }
        },
        "navBar": {
            home: "Trang chủ",
            products: {
                title: "Sản phẩm",
                contents: [
                    "Robotics - AI",
                    "Megaton",
                ]
            },
            introduction: {
                title: "Khoá Học",
                contents: [
                    "Drone",
                    "Robotics"
                ]
            },
            hiring: {
                title: "Tuyển dụng",
                contents: [
                    "Form tuyển dụng",
                    "Liên hệ"
                ]
            },
            news: "Tin tức",
            contact: "Liên hệ"
        },
        "introductionPage": {
            curricula: [
                {
                    title: "Chương trình Tiểu học",
                    content: "Trẻ ở độ tuổi tiểu học sẽ được tiếp cận các khóa học Robotics & Drone với nền tảng cơ bản để các em dần phát triển tư duy công nghệ cùng nhóm kỹ năng (lập trình, thuyết trình, tư duy logic,…) cần thiết trong kỹ nguyên mới.",
                    button: "Liên hệ",
                    skills: [
                        "TƯ DUY LOGIC",
                        "KỸ NĂNG SÁNG TẠO",
                        "THUYẾT TRÌNH",
                        "LÀM VIỆC NHÓM"
                    ]
                },
                {
                    title: "Chương trình Trung học cơ sở",
                    content: "Cải thiện tư duy cùng nhiều kỹ năng thông qua đa dạng các giải pháp học tập & ứng dụng vào đời sống bằng nhiều công cụ học tập khác nhau. Ngoài ra học sinh có cơ hội tiếp cận mở rộng sân chơi từ nhiều cuộc thi trong và ngoài nước.",
                    button: "Liên hệ",
                    skills: [
                        "LẬP TRÌNH",
                        "THUYẾT TRÌNH",
                        "TƯ DUY LOGIC"
                    ]
                },
                {
                    title: "Chương trình Trung học phổ thông",
                    content: "Mở ra cơ hội du học chưa bao giờ đơn giản hơn qua giải pháp học mà chơi. Học tập và tiếp cận công nghệ cao ứng dụng trí tuệ nhân tạo vào lập trình và phát triển Robot, tạo cơ hội hội nhập thông qua các cuộc thi Đa quốc gia.",
                    button: "Liên hệ",
                    skills: [
                        "LẬP TRÌNH",
                        "KỸ NĂNG SÁNG TẠO",
                        "THUYẾT TRÌNH",
                        "LÀM VIỆC NHÓM"
                    ]
                },
                {
                    title: "Chương trình Đại Học",
                    content: "Ứng dụng trí tuệ nhân tạo AI là chương trình học được tích hợp thêm các ứng dụng AI như: nhận diện khuôn mặt, nhận diện giọng nói, nhận diện hành động cử chỉ,… vào Robot giúp sinh viên giải quyết các vấn đề và ứng dụng nhanh chóng vào thực tiễn. Ngoài ra học sinh có cơ hội tiếp cận mở rộng sân chơi từ nhiều cuộc thi trong và ngoài nước.",
                    button: "Liên hệ",
                    skills: [
                        "LẬP TRÌNH",
                        "KỸ NĂNG SÁNG TẠO",
                        "THUYẾT TRÌNH",
                        "LÀM VIỆC NHÓM"
                    ]
                }
            ]
        },
        "hiringPage": {
            name: "Tên",
            phone: "Điện thoại",
            email: "Email",
            position: {
                title: "Vị trí",
                content: [
                    "Giáo viên",
                    "Trợ giảng",
                    "Marketing"
                ]
            },
            certificate: {
                title: "Chứng chỉ",
                content: [
                    "Sư phạm",
                    "Tiếng anh"
                ]
            },
            content: "Nội dung",
            button: "Gửi chúng tôi"
        },
        "contactPage": {
            contact: {
                title: "Thông tin liên hệ",
                addressTitle: "Địa chỉ",
                address: "META SQUARE COMPANY LIMITED A05.10 - 475A Điện Biên Phủ, Phường 25, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
                workingTimeTitle: "Thời gian làm việc",
                workingTime: "Thứ 2 - Thứ 6",
                phoneTitle: "Điện thoại"
            },
            infoForm: {
                title: "Gửi thắc mắc cho chúng tôi",
                question: "Nếu bạn có thắc mắc gì, có thể gửi yêu cầu cho chúng tôi và chúng tôi sẽ liên lạc với bạn sớm nhất có thể.",
                nameField: "Tên của bạn",
                emailField: "Email của bạn",
                phoneField: "Số điện thoại",
                contentField: "Ghi nội dung của bạn"
            },
            button: "Gửi cho chúng tôi"
        },
        "languageType": "Ngôn ngữ",
        "productDetailPage": [
            {
                "title": "Lập Trình Drone (BRC-105)",
                "summary": "Phát triển tư duy thông qua giải pháp lập trình scratch và nhiều kỹ năng khác mở rộng môi trường thông qua các cuộc thi trong và ngoài nước.",
                "description": "Mô tả sản phẩm",
                "detailDescription": "",
                "relatedProduct": "Sản phẩm liên quan",
                "contactButton": "Liên hệ sale",
                "viewMoreButton": "Xem thêm",
                "status": "Tình trạng",
                "available": "Còn hàng"
            },
            {
                "title": "Drone Bóng Đá (SKYKICK EVOLUTION)",
                "summary": "Học sinh được trải nghiệm những thử thách, mở rộng sân chơi đa quốc gia với bộ môn WORLD CUP Drone.",
                "description": "Mô tả sản phẩm",
                "detailDescription": "",
                "relatedProduct": "Sản phẩm liên quan",
                "contactButton": "Liên hệ sale",
                "viewMoreButton": "Xem thêm",
                "status": "Tình trạng",
                "available": "Còn hàng"
            },
            {
                "title": "Drone Chiến Đấu (BRB-95)",
                "summary": "Đặc biệt sản phẩm để các bạn nhỏ trải nghiệm nhiều hơn nữa những công nghệ tiên tiến.",
                "description": "Mô tả sản phẩm",
                "detailDescription": "",
                "relatedProduct": "Sản phẩm liên quan",
                "contactButton": "Liên hệ sale",
                "viewMoreButton": "Xem thêm",
                "status": "Tình trạng",
                "available": "Còn hàng"
            },
            {
                "title": "Robotics AI (NO3 /NO6)",
                "summary": "Tiếp cận công nghệ cao ứng dụng trí tuệ nhân tạo vào lập trình và phát triển Robot, tạo cơ hội hội nhập thông qua các cuộc thi Đa quốc gia.",
                "description": "Mô tả sản phẩm",
                "detailDescription": "",
                "relatedProduct": "Sản phẩm liên quan",
                "contactButton": "Liên hệ sale",
                "viewMoreButton": "Xem thêm",
                "status": "Tình trạng",
                "available": "Còn hàng"
            },
            {
                "title": "MEGATON - BỘ CÔNG CỤ  ỨNG DỤNG AI",
                "summary": "Megaton là một giải pháp công nghệ tích hợp trí tuệ nhân tạo (AI), giải pháp cung cấp một loạt các công cụ và tài liệu học tập thông minh để hỗ trợ việc học tập và nghiên cứu robot và ứng dụng thực tế.",
                "description": "Mô tả sản phẩm",
                "detailDescription": "",
                "relatedProduct": "Sản phẩm liên quan",
                "contactButton": "Liên hệ sale",
                "viewMoreButton": "Xem thêm",
                "status": "Tình trạng",
                "available": "Còn hàng"
            }
        ],
        "newsPage": {
            latestNews: "Bài đăng gần đây"
        }
    },
    "en": {
        "home": {
            "levelHeader": "Teaching Level",
            "productHeader": "Products",
            "skillHeader": "Skills",
            "partnerHeader": "Partners",
            "newsHeader": "News"
        },
        "homeSkills": [
            "Programming",
            "Presentation",
            "Logical Thinking",
            "Creativity",
            "Teamwork"
        ],
        "briefProduct": [
            {
                "title": "Drone Programming (BRC-105)",
                "summary": "Develop critical thinking through scratch programming solutions and various other skills, expanding the environment through both national and international competitions.",
                "button": "See more"
            },
            {
                "title": "Drone Football (SKYKICK EVOLUTION)",
                "summary": "Students experience challenges, expanding the international playground with the WORLD CUP Drone sport.",
                "button": "See more"
            },
            {
                "title": "Combat Drones (BRB-95)",
                "summary": "A special product for young enthusiasts to experience more advanced technologies.",
                "button": "See more"
            },
            {
                "title": "AI Robotics (NO3 /NO6)",
                "summary": "Explore advanced technology, apply artificial intelligence to programming and robot development, creating opportunities for integration through international competitions.",
                "button": "See more"
            },
            {
                "title": "MEGATON - AI APPLICATION TOOLSET",
                "summary": "Megaton is an integrated artificial intelligence (AI) technology solution, providing a range of intelligent tools and study materials to support learning and research in robotics and real-world applications.",
                "button": "See More"
            }
        ],
        "footerContent": {
            about: {
                title: "About Meta Square",
                content: "An innovative STEM learning environment, applying diverse technologies (Robotics, Drone, AI, etc.), suitable for all ages from elementary school to university level - providing a multinational learning environment through various international competitions and certificates."
            },
            contact: {
                title: "Contact",
                hotline: "Hotline: 0817665064",
                email: "khangpn@scienceboxvietnam.vn",
                office: "Hutech Office No. 475A (formerly No. 144/24) Dien Bien Phu Street, Ward 25, Binh Thanh District, Ho Chi Minh City."
            },
            customerSupport: {
                title: "Customer Support",
                contact: "Contact",
                news: "News",
                hiring: "Hiring",
                introduction: "Introduction"
            }
        },
        "navBar": {
            "home": "Home",
            "products": {
                "title": "Products",
                "contents": [
                    "Robotics - AI",
                    "Megaton"
                ]
            },
            "introduction": {
                "title": "Courses",
                "contents": [
                    "Drones",
                    "Robotics"
                ]
            },
            "hiring": {
                "title": "Hiring",
                "contents": [
                    "Application Form",
                    "Contact"
                ]
            },
            "news": "News",
            "contact": "Contact"
        },
        "introductionPage": {
            "curricula": [
                {
                    "title": "Elementary School Program",
                    "content": "Children at the elementary school level will have access to Robotics & Drone courses with a basic foundation to gradually develop their technological thinking and essential skills (programming, presentation, logical thinking, etc.) in the new era.",
                    "button": "Contact",
                    "skills": [
                        "LOGICAL THINKING",
                        "CREATIVITY",
                        "PRESENTATION",
                        "TEAMWORK"
                    ]
                },
                {
                    "title": "Middle School Program",
                    "content": "Improve critical thinking and various skills through diverse learning solutions & applications in life using various study tools. Additionally, students have the opportunity to expand their horizons through numerous national and international competitions.",
                    "button": "Contact",
                    "skills": [
                        "PROGRAMMING",
                        "PRESENTATION",
                        "LOGICAL THINKING"
                    ]
                },
                {
                    "title": "High School Program",
                    "content": "Opening doors to study abroad has never been simpler than through learning while playing. Study and access advanced technology applying artificial intelligence to programming and Robot development, creating opportunities for integration through various international competitions.",
                    "button": "Contact",
                    "skills": [
                        "PROGRAMMING",
                        "CREATIVITY",
                        "PRESENTATION",
                        "TEAMWORK"
                    ]
                },
                {
                    title: "University Program",
                    content: "The AI program integrates artificial intelligence applications such as face recognition, voice recognition, gesture recognition, etc., into robots to help students solve problems and quickly apply them in practice. In addition, students have the opportunity to participate in various competitions at home and abroad, expanding their playground.",
                    button: "Contact",
                    skills: [
                        "PROGRAMMING",
                        "CREATIVE SKILLS",
                        "PRESENTATION",
                        "TEAMWORK"
                    ]
                }
            ]
        },
        "hiringPage": {
            "name": "Name",
            "phone": "Phone",
            "email": "Email",
            "position": {
                title: "Position",
                content: [
                    "Teacher",
                    "Assistant Teacher",
                    "Marketing"
                ]
            },
            "certificate": {
                title: "Certificate",
                content: [
                    "Teaching",
                    "English"
                ]
            },
            "content": "Content",
            button: "Send Us"
        },
        "contactPage": {
            "contact": {
                "title": "Contact Information",
                "addressTitle": "Address",
                "address": "META SQUARE COMPANY LIMITED A05.10 - 475A Dien Bien Phu, Ward 25, Binh Thanh District, Ho Chi Minh City",
                "workingTimeTitle": "Working Hours",
                "workingTime": "Monday - Friday",
                "phoneTitle": "Phone"
            },
            "infoForm": {
                "title": "Send Us Your Inquiries",
                "question": "If you have any questions, you can send your requests to us, and we will contact you as soon as possible.",
                "nameField": "Your Name",
                "emailField": "Your Email",
                "phoneField": "Your Phone Number",
                "contentField": "Write your message here"
            },
            "button": "Send us"
        },
        "languageType": "Nationality",
        "productDetailPage": [
            {
                title: "Drone Programming (BRC-105)",
                summary: "Develop critical thinking through scratch programming solutions and enhance skills. Expand the environment through national and international competitions.",
                description: "Product Description",
                detailDescription: "",
                relatedProduct: "Related Products",
                "contactButton": "Contact sale",
                "viewMoreButton": "View more",
                "status": "Status",
                "available": "available"
            },
            {
                title: "Soccer Drone (SKYKICK EVOLUTION)",
                summary: "Students experience challenges, expanding the international playground with the WORLD CUP Drone discipline.",
                description: "Product Description",
                detailDescription: "",
                relatedProduct: "Related Products",
                "contactButton": "Contact sale",
                "viewMoreButton": "View more",
                "status": "Status",
                "available": "available"
            },
            {
                title: "Combat Drone (BRB-95)",
                summary: "Especially designed for young individuals to experience advanced technologies.",
                description: "Product Description",
                detailDescription: "",
                relatedProduct: "Related Products",
                "contactButton": "Contact sale",
                "viewMoreButton": "View more",
                "status": "Status",
                "available": "available"
            },
            {
                title: "Robotics AI (NO3 / NO6)",
                summary: "Access high-tech applications of artificial intelligence in programming and developing robots. Create opportunities for integration through international competitions.",
                description: "Product Description",
                detailDescription: "",
                relatedProduct: "Related Products",
                "contactButton": "Contact sale",
                "viewMoreButton": "View more",
                "status": "Status",
                "available": "available"
            },
            {
                title: "MEGATON - AI APPLICATION TOOLSET",
                summary: "Megaton is an integrated artificial intelligence (AI) technology solution, providing a range of intelligent tools and study materials to support learning and research in robotics and real-world applications.",
                description: "Product Description",
                detailDescription: "",
                relatedProduct: "Related Products",
                "contactButton": "Contact sale",
                "viewMoreButton": "View more",
                "status": "Status",
                "available": "available"
            }
        ],
        "newsPage": {
            latestNews: "Recent Post"
        }
    },
    "kr": {
        "home": {
            "levelHeader": "교육 수준",
            "productHeader": "제품",
            "skillHeader": "기술",
            "partnerHeader": "파트너",
            "newsHeader": "뉴스"
        },
        "homeSkills": [
            "프로그래밍",
            "프레젠테이션",
            "논리적 사고",
            "창의성",
            "팀워크"
        ],
        "briefProduct": [
            {
                "title": "드론 프로그래밍 (BRC-105)",
                "summary": "스크래치 프로그래밍 및 다양한 기술을 통한 사고 능력 발전 및 국내외 대회를 통한 확장된 환경.",
                "button": "자세히 보기"
            },
            {
                "title": "축구 드론 (SKYKICK EVOLUTION)",
                "summary": "다양한 국제 대회에서 경험하며 국제적인 경기장을 확장할 수 있는 기회를 제공합니다.",
                "button": "자세히 보기"
            },
            {
                "title": "전투 드론 (BRB-95)",
                "summary": "최첨단 기술을 체험할 수 있는 제품으로 어린이들에게 더 많은 경험을 제공합니다.",
                "button": "자세히 보기"
            },
            {
                "title": "로봇 공학 (NO3 / NO6)",
                "summary": "인공 지능 기술을 로봇 프로그래밍 및 개발에 적용하고 국제 대회를 통한 통합 기회를 제공합니다.",
                "button": "자세히 보기"
            },
            {
                "title": "MEGATON - AI 응용 프로그램 도구 세트",
                "summary": "Megaton은 통합된 인공 지능 (AI) 기술 솔루션으로, 로봇 공학 및 실제 응용 분야에서 학습과 연구를 지원하기 위한 다양한 지능형 도구 및 학습 자료를 제공합니다.",
                "button": "자세히 보기"
            }
        ],
        "footerContent": {
            "about": {
                "title": "Meta Square 소개",
                "content": "STEM 교육을 개선하고 로봇 공학, 드론, 인공 지능 등 다양한 기술을 적용한 교육 환경입니다. 초등학교부터 대학까지 모든 연령대에 적합하며 국제 대회 및 자격증을 통한 국제 교육 환경을 제공합니다."
            },
            "contact": {
                "title": "연락처 정보",
                "hotline": "고객 센터: 0817665064",
                "email": "khangpn@scienceboxvietnam.vn",
                "office": "후텍 사무실 475A (구주소: 144/24) Điện Biên Phủ, Phường 25, Bình Thạnh Quận, 호치민시."
            },
            "customerSupport": {
                "title": "고객 지원",
                "contact": "연락하기",
                "news": "뉴스",
                "hiring": "채용",
                "introduction": "소개"
            }
        },
        "navBar": {
            "home": "홈",
            "products": {
                "title": "제품",
                "contents": [
                    "로봇 공학 - 인공 지능",
                    "Megaton"
                ]
            },
            "introduction": {
                "title": "강좌",
                "contents": [
                    "드론",
                    "로봇공학"
                ]
            },
            "hiring": {
                "title": "채용",
                "contents": [
                    "지원 양식",
                    "연락하기"
                ]
            },
            "news": "뉴스",
            "contact": "연락하기"
        },
        "introductionPage": {
            "curricula": [
                {
                    "title": "초등학교 프로그램",
                    "content": "초등학교 수준의 아이들은 로보틱스 및 드론 코스에 기본 기반을 가지고 기술적 사고와 필수 기술 (프로그래밍, 프레젠테이션, 논리적 사고 등)을 천천히 발전시킬 수 있습니다.",
                    "button": "문의하기",
                    "skills": [
                        "논리적 사고",
                        "창의성",
                        "프레젠테이션",
                        "팀워크"
                    ]
                },
                {
                    "title": "중학교 프로그램",
                    "content": "다양한 학습 솔루션 및 도구를 사용하여 비판적 사고와 다양한 기술을 향상시키십시오. 또한 학생들은 국내외 다양한 대회를 통해 시야를 넓힐 기회를 가집니다.",
                    "button": "문의하기",
                    "skills": [
                        "프로그래밍",
                        "프레젠테이션",
                        "논리적 사고"
                    ]
                },
                {
                    "title": "고등학교 프로그램",
                    "content": "학문을 통한 즐거운 학습을 통해 해외 유학의 문을 열어보세요. 프로그래밍과 로봇 개발에 인공 지능을 적용하고 다양한 국제 대회를 통해 통합의 기회를 창출합니다.",
                    "button": "문의하기",
                    "skills": [
                        "프로그래밍",
                        "창의성",
                        "프레젠테이션",
                        "팀워크"
                    ]
                },
                {
                    title: "대학 프로그램",
                    content: "AI 프로그램은 얼굴 인식, 음성 인식, 제스처 인식 등과 같은 인공 지능 응용 프로그램을 로봇에 통합하여 학생들이 문제를 해결하고 빠르게 실전에서 적용할 수 있도록 돕습니다. 또한, 학생들은 국내외에서 다양한 대회에 참여하여 놀이터를 확장할 수 있는 기회를 갖습니다.",
                    button: "문의하기",
                    skills: [
                        "프로그래밍",
                        "창의적인 기술",
                        "프레젠테이션",
                        "팀워크"
                    ]
                }
            ]
        },
        "hiringPage": {
            "name": "이름",
            "phone": "전화번호",
            "email": "이메일",
            "position": {
                "title": "직책",
                "content": [
                    "교사",
                    "조교",
                    "마케팅 담당자"
                ]
            },
            "certificate": {
                "title": "자격증",
                "content": [
                    "교육 자격증",
                    "영어 자격증"
                ]
            },
            "content": "내용",
            "button": "문의하기"
        },
        "contactPage": {
            "contact": {
                "title": "연락처 정보",
                "addressTitle": "주소",
                "address": "META SQUARE 주식회사 A05.10 - 475A Điện Biên Phủ, Phường 25, Bình Thạnh Quận, 호치민시",
                "workingTimeTitle": "업무 시간",
                "workingTime": "월요일 - 금요일",
                "phoneTitle": "전화번호"
            },
            "infoForm": {
                "title": "문의 사항 보내기",
                "question": "문의 사항이 있으면 언제든지 문의하여 주시고 빠르게 연락드리겠습니다.",
                "nameField": "이름",
                "emailField": "이메일",
                "phoneField": "전화번호",
                "contentField": "내용을 입력해주세요"
            },
            "button": "문의하기"
        },
        "languageType": "언어",
        "productDetailPage": [
            {
                title: "드론 프로그래밍 (BRC-105)",
                summary: "스크래치 프로그래밍 솔루션을 통한 비판적 사고를 개발하고 기술을 향상시킵니다. 국내외 대회를 통해 환경을 확장합니다.",
                description: "제품 설명",
                detailDescription: "",
                relatedProduct: "관련 제품",
                "contactButton": "판매에 문의",
                "viewMoreButton": "더 보기",
                "status": "상태",
                "available": "유효한"
            },
            {
                title: "축구 드론 (SKYKICK EVOLUTION)",
                summary: "학생들은 도전을 경험하며 WORLD CUP 드론 분야에서 국제 놀이터를 확장합니다.",
                description: "제품 설명",
                detailDescription: "",
                relatedProduct: "관련 제품",
                "contactButton": "판매에 문의",
                "viewMoreButton": "더 보기",
                "status": "상태",
                "available": "유효한"
            },
            {
                title: "전투 드론 (BRB-95)",
                summary: "청소년들이 고급 기술을 경험할 수 있도록 특별히 디자인되었습니다.",
                description: "제품 설명",
                detailDescription: "",
                relatedProduct: "관련 제품",
                "contactButton": "판매에 문의",
                "viewMoreButton": "더 보기",
                "status": "상태",
                "available": "유효한"
            },
            {
                title: "로봇 공학 AI (NO3 / NO6)",
                summary: "로봇 프로그래밍 및 개발에 인공 지능의 고급 응용을 접근합니다. 국제 대회를 통해 통합 기회를 창출합니다.",
                description: "제품 설명",
                detailDescription: "",
                relatedProduct: "관련 제품",
                "contactButton": "판매에 문의",
                "viewMoreButton": "더 보기",
                "status": "상태",
                "available": "유효한"
            },
            {
                title: "MEGATON - AI 응용 프로그램 도구 세트",
                summary: "Megaton은 통합된 인공 지능 (AI) 기술 솔루션으로, 로봇 공학 및 실제 응용 분야에서 학습과 연구를 지원하기 위한 다양한 지능형 도구 및 학습 자료를 제공합니다.",
                description: "제품 설명",
                detailDescription: "",
                relatedProduct: "관련 제품",
                "contactButton": "판매에 문의",
                "viewMoreButton": "더 보기",
                "status": "상태",
                "available": "유효한"
            }
        ],
        "newsPage": {
            latestNews: "최근 게시물"
        }
    }
}

export function removePunctuation(inputString: string) {
    // Use a regular expression to match and remove punctuation
    let cleanedString = removeDiacritics(inputString).replace(/[^a-zA-Z0-9\s]/g, '');

    // Convert to lowercase
    cleanedString = cleanedString.toLowerCase();

    cleanedString = cleanedString.replace(/\s+/g, '-');

    return cleanedString;
}

export function removeDiacritics(inputString: string) {
    return inputString.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
