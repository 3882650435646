import React, {useContext, useEffect, useRef} from "react";
import megatonBoard from "./pages/Megaton/assests/megaton_board.png";
import scienceBox1 from './pages/ScienceBox/assests/SB3.png';
import codingDrone1 from '../assets/coding/dronecoding1.png';
import battleDrone1 from '../assets/battle/droneFightingNew.png';
import footballModel from '../assets/football/droneSportNew.png'
import {LanguageContext} from "../App";
import {LanguageType} from "../pages/utils";
import './products.css'
import {useNavigate} from "react-router-dom";

type ProductsProps = {
    shownProduct: number;
}
const Products = ({shownProduct}: ProductsProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Change " + shownProduct)
    }, [shownProduct])

    return <div className="" style={{
            position: 'sticky',
            top: '40%',
            transform: 'translateY(-30%) translateX(20%)',
            width: '50%',  // Adjust the width as needed
            height: '500px',
            transition: 'opacity 0.9s ease-in-out', // Add a transition effect
            opacity: 1, // Initial opacity
        }}>
        <div className="relative">
            <div onClick={() => {
                navigate('/products/megaton');
                window.location.reload();
            }}
                 style={{
                     top: '50%',
                     left: '50%',
                     transform: 'translate(-80%, 0%)',
                 }}
                 className={`absolute bg-none cursor-pointer h-[400px] flex flex-col items-center justify-center ${shownProduct === 0 ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500 hover:scale-105 transition-all duration-300 snap-center w-[430px] min-w-[430px] mx-auto p-5 rounded-3xl`}>

                <div className="h-[300px]">
                    <div className="w-[300px] h-auto mx-auto">
                        <img className="w-full h-full" src={megatonBoard} alt={"Megaton Board"} />
                    </div>
                </div>
            </div>
            <div onClick={() => {
                navigate('/products/drone-coding');
                window.location.reload();
            }}
                 style={{
                     top: '50%',
                     left: '50%',
                     transform: 'translate(-80%, 0%)',
                 }} className={`absolute bg-none cursor-pointer ${shownProduct === 1 ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500 hover:scale-105 transition-all duration-300 snap-center w-[430px] min-w-[430px] mx-auto p-5 rounded-3xl `}>

                <div className=" w-[350px] h-auto mx-auto">
                    <img className="w-full h-full" src={codingDrone1} alt={"Megaton Board"} />
                </div>
            </div>
            <div onClick={() => {
                navigate('/products/drone-football');
                window.location.reload();
            }}
                 style={{
                     top: '50%',
                     left: '50%',
                     transform: 'translate(-80%, 0%)',
                 }} className={`absolute cursor-pointer ${shownProduct === 2 ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500 hover:scale-105 transition-all duration-300 snap-center w-[430px] min-w-[430px] mx-auto p-5 rounded-3xl`}>
                <div className="w-[355px] h-auto mx-auto">
                    <img className="w-full h-full" src={footballModel} alt={"Megaton Board"} />
                </div>
            </div>
            <div onClick={() => {
                navigate('/products/battle-drone');
                window.location.reload();
            }}
                 style={{
                     top: '50%',
                     left: '50%',
                     transform: 'translate(-80%, 0%)',
                 }} className={`absolute bg-none cursor-pointer ${shownProduct === 3 ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500 hover:scale-105 transition-all duration-300 snap-center w-[430px] min-w-[430px] mx-auto p-5 rounded-3xl`}>
                <div className="w-[460px] h-auto mx-auto">
                    <img className="w-full h-full" src={battleDrone1} alt={"Megaton Board"} />
                </div>
            </div>
            <div onClick={() => {
                navigate('/contact');
                window.location.reload();
            }}
                 style={{
                     top: '50%',
                     left: '50%',
                     transform: 'translate(-80%, 0%)',
                 }} className={` absolute bg-none cursor-pointer ${shownProduct === 4 ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500 hover:scale-105 transition-all duration-300 snap-center w-[430px] min-w-[430px] mx-auto p-5 rounded-3xl`}>
                <div className="w-[420px] h-auto mx-auto">
                    <img className="w-full h-full" src={scienceBox1} alt={"Megaton Board"} />
                </div>
            </div>
        </div>

    </div>
}

export default Products;
